body{
    background-color: #fff;
    font-size: 16px;
    font-family: 'poppins' !important;
    transition: .5s;
  }
  body.themeLight{
    background: #ffffff;
    color: #000000;
  }
  .form-group.flexButton {
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .withdrawPopupForm .flexButton .btn.buttonType1{
    margin: 0;
  }
  .withdrawPopupForm .flexButton .btn.buttonType1:first-child{
    margin-right: 15px;;
  }
  .withdrawPopupForm .flexButton .btn.buttonType1:disabled{
    cursor:not-allowed;
    opacity: .5;
  }
  .flexTitle {
      display: flex !important;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;
  }
  .uploadRightBox h4{
    color: #fff;
    font-size: 14px;
  }
  .proofContentText h4{
    color: #fff;
    font-size: 14px;
  }
  .proofContentText h5{
    color: #ffffff;
  }
  .proofContentText h6{
    color: #ffffff;
  }
  .proofContentText p {
      color: #c1c2ca;
      font-size: 13px;
      margin-bottom: 10px;
      margin-top: 10px;
  }
  .proofBox label{
    color: #81c8f6;
  }
  .custom-file-label::after {
    background: transparent !important;
    color: #005eb8 !important;
  }
  .darktheme .custom-file-label::after {
    background: transparent !important;
    color: #70ace6 !important;
  }
  .proofBox img {
      min-height: 200px;
      border: 1px solid #81c8f6;
      border-radius: 10px;
      width: 100%;
      max-height: 200px;
      margin-bottom: 20px;
  }
  .pairSearch {
      padding: 5px;
  }
  .btn.btn-borderButton.active {
      color: #81c8f6;
      border: 1px solid #81c8f6;
  }
  .ptop{
    padding-top: 18px !important;
  }
  .pTobBott{
    padding: 11px 0 !important;
    border-bottom: 1px solid #1b2c52 !important;
  }
  .react-datepicker-wrapper{
    width: 100%;
  }
  .rowCustom{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
  }
  .tradeLimitMarket {
      min-height: 550px;
  }
  .fixedWidth{
    width: 380px;
    flex-direction: column;
  }
  .liquidWidth{
    width: calc(100% - 760px);
  }
  .leverageHeight {
      min-height: 155px;
  }
  .border-left-zero{
    border-left: 0 !important;
  }
  .border-right-one{
    border-right: 1px solid #1b2c52 !important;
  }
  .orderBookMiddle{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    width: 100%;
    flex-direction: column;
    height: 100%;
  }
  .vertical-tab {
      display: flex;
      margin-right: 0;
      width: 100%;
      height: 100%;
      justify-content: flex-start;
      align-items: flex-start;
  }
  .vertical-tab .table thead th{
    border-bottom: 1px;
  }
  .vertical-tab .nav-tabs{
      width: 20%;
      min-width: 20%;
      border: none;
      border-right: 1px solid #1b2c52;
    display: flex;
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
  .vertical-tab .nav-tabs li{
     width: 100%;
  }
  .vertical-tab .nav-tabs li a{
      text-align: center;
      text-transform: uppercase;
      padding: 5px 15px 5px;
      margin: 0 0 0px 0;
      border: none;
      border-radius: 0;
      display: block;
      position: relative;
      z-index: 1;
      transition: all 0.5s ease 0s;
      color: #ffffff;
  }
  .vertical-tab .nav-tabs li a:hover, .vertical-tab .nav-tabs li a.active{
    background: var(--themeclr);
    color: #fff;
  }
  .vertical-tab .tab-content{
      padding: 0px;
      position: relative;
      width: calc(100% - 20%);
  }
  .vertical-tab .tab-content h3{
      color: #1B76FF;
      font-weight: 800;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin: 0 0 7px 0;
  }
  .derivativeFlexColumn{
    display: flex;
    align-items: center;
  }
  .navbar-brand.innerLogo img {
    max-height: 47px;
}
    a.navbar-brand.innerLogo{
      padding: 0;
  }
  /* .logoLight{
    display: none;
  } */
  .themeLight .logoDark{
    display: none;
  }
  .themeLight .logoLight{
    display: block;
  }
  .btnMarginLeftRight{
    margin-left: 8px;
    margin-right: 8px;
  }
  .borderLeftZero{
    border-left: 0 !important;
  }
  .borderLeftRightOne{
    border-left: 1px solid #1b2c52;
    border-right: 1px solid #1b2c52;
  }
  .border-bottom-one{
    border-bottom: 1px solid #060b27 !important;
  }
  .tableHead.border-bottom-one{
    border-bottom: 1px solid #314779 !important;
  }
  .spotTradingBox{
    background: #000;
  }
  .tradeRight {
    background: #000;
    height: 100%;
  }
  .walletBalanceTable{
    border-bottom: 0 !important;
  }
  .walletBalanceTable tr td:first-child{
    min-width: 110px;
  }
  .walletBalanceTable tr td:first-child img{
    width: 24px;
    margin-right: 10px;
    border-radius: 50%;
  }
  a{
    color: #81c8f6;
    text-decoration: none;
    transition: .5s;
  }
  a:hover{
    color: #81c8f6;
    text-decoration: none;
  }
  td a {
      cursor: pointer;
  }
  ul{
    margin: 0;
    padding: 0;
  }
  ul li{
    list-style: none;
  }
  [type="checkbox"], [type="file"], [type="radio"] {
      margin: 0;
  }
  .lokalFee tbody, .lokalFee tfoot, .lokalFee thead{
    border:0;
  }
  .lokalFee .valuesTable td{
    background: #000;
    font-size: 16px;
    font-weight: 400;
  }
  .darktheme .lokalFee .valuesTable td{
  color:#fff
  }
  .lokalFee .valuesTable th{
    background: none;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
  }
  .lighttheme .lokalFee .valuesTable td{
  background-color: #fff;
  color: #000;
  }
  .lokalFee thead{
    background: var(--themeclr);
    /* background: linear-gradient(90deg, rgba(252,179,5,1) 0%, rgba(252,179,5,1) 35%, rgba(254,194,3,1) 100%); */
  }
  .lokalFee .editor{
    margin-bottom: 30px;
  }
  .lokalFee .editor h2{
    font-size: 24px !important;
    color: #81c8f6 !important;
  }
  .editor p a{
    color: var(--themeclr);
  }
  .editor p a:hover{
    color: #ffffff;
  }
  .editor p a.buttonType2{
    color: #ffffff;
  }
  .css-zk88xw-singleValue{
    color: white !important;
  }
  .track-vertical {
      position: relative;
      display: block;
      width: 6px;
      height: 100%;
      cursor: pointer;
      background: transparent;
      float: right;
      z-index: 1;
      border-radius: 5px;
      margin-right: 2px;
  }
  
  .track-vertical > div {
      background: #005eb8 !important;
  }
  .marginTopLabel{
    margin-top: 20%;
  }
  #price.quantity {
      padding: 6px 15px;
      border-radius: 2px !important;
      background-color: #02071e;
      color: #ffffff;
      border: none !important;
  }
  .quadrat {
    width: 100%;
    padding: 7px 15px;
    border-radius: 5px;
    border: 1px solid transparent;
    color: #d40065;
    font-size: 14px;
    animation: animate 1.25s infinite;  /* IE 10+, Fx 29+ */
  }
  
  @keyframes animate {
    0%, 49% {
      border:1px solid #d40065;
  
    }
  
  }
  .themeLight .rc-slider-rail {
      background-color: #005eb8 !important;
  }
  .themeLight .rc-slider-dot{
    background-color: #e5e5e5 !important;
    border-color: #81c8f6 !important;
  }
  .themeLight .rc-slider-dot-active{
    background-color: #e5e5e5 !important;
    border-color: #81c8f6 !important;
  }
  .themeLight .rc-slider-handle{
    background-color: #81c8f6 !important;
    border-color: #81c8f6 !important;
  }
  .themeLight #price{
    background-color: #fff;
  border: 1px solid #b5b5b5;
  color: #81c8f6;
  }
  .css-0 {
      display: flex;
  }
  .buySellTabButton {
    margin-top: 7px;
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #060b27;
      border-radius: 5px;
  }
  .buySellTabButton .btn-group{
    width: 100%;
  }
  .buySellTabButton .btn-group .btnBuyBlue{
    padding-top: 10px;
    padding-bottom: 10px;
    background: var(--themeclr);
    color: #fff;
  }
  .buySellTabButton .btn-group .btnBuyBlue:hover{
    background: var(--themeclr);
  }
  .buySellTabButton .btn-group .btnBuyYellow{
    padding-top: 10px;
    padding-bottom: 10px;
    border:none;
    color: #81c8f6;
  }
  .themeLight .buySellTabButton .btn-group .btnBuyYellow{
    padding-top: 10px;
    padding-bottom: 10px;
    border:none;
    color: #81c8f6;
  }
  .buySellTabButton .btn-group .btnBuyYellow:hover{
    color: #000;
    border: none;
  }
  .themeLight .buySellTabButton .btn-group .btnBuyYellow:hover{
    color: #81c8f6;
    border-color: #81c8f6;
    border: none;
  
  }
  .buySellTabButton .btn-group .btnSellBlue{
    padding-top: 10px;
    padding-bottom: 10px;
    background: var(--themeclr);
    color: #ffffff;
  }
  .buySellTabButton .btn-group .btnSellBlue:hover{
    background: var(--themeclr);
  }
  .buySellTabButton .btn-group .btnSellYellow{
    padding-top: 10px;
    padding-bottom: 10px;
    border-color: #12161c;
    color: #81c8f6;
  }
  .buySellTabButton .btn-group .btnSellYellow:hover{
    color: #000;
  }
  
  .themeLight .buySellTabButton .btn-group .btnSellYellow{
    color: #81c8f6;
    border-color: #81c8f6;
  }
  .themeLight .buySellTabButton .btn-group .btnSellYellow:hover{
    color: #ffffff;
  }
  .tradeInfoText {
      display: flex;
      justify-content: space-between;
      padding: 10px 15px;
      border-bottom: 0px solid #12161c;
      background: #121126;
      margin-top: -1px;
  }
  
  .themeLight .tradeInfoText {
      display: flex;
      justify-content: space-between;
      padding: 10px 15px;
      border-bottom: 0px solid #c8c8c8;
      background: #c8c8c8;
      margin-top: -1px;
  }
  
  
  .tradeInfoText small span{color: #81c8f6}
  .assetexchangeMain {
    /*width: 19px;*/
    /*height: 14px;*/
    display: block;
    cursor: pointer;
    /*margin-left: 15px;*/
  }
  .assetexchange i {
    font-size: 15px;
  }
  .assetexchangeMain:hover i{
  color: #81c8f6;
  }
  span.bgProgress{
      height: 100%;
      background-color: rgba(61,201,13,0.5);
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
  }
  span.bgProgressText{
    position: relative;
    z-index: 9;
  }
  span.bgProgress1{
      height: 100%;
      background-color: rgba(235,62,112,0.5);
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
  }
  span.bgProgressText1{
    position: relative;
    z-index: 9;
  }
  .highlight {
    background:rgba(22,62,112,0.5);
    animation-name:bckanim;
    animation-fill-mode:forwards;
    animation-duration:0.5s;
    animation-delay:0s;
  }
  .table td, .table th {
      color: #bcbcbc;
      white-space: nowrap;
      font-size: 14px;
  }
  .tradeMain .table td, .tradeMain .table th {
    border-color: #12161c;
  }
  .pairTableMain.table td, .pairTableMain.table th {
    font-size: 11px;
    padding: 13px;
  }
  .pairTableMain.table td:first-child{
    font-size: 11px;
  }
  .btnFavorite {
      box-shadow: none !important;
      border: 0;
      padding: 0;
      color: #dedede;
      line-height: 0;
      margin-right: 5px;
      transition: .5s;
      font-size: 11px;
  }
  .btnFavorite:hover {
      color: #81c8f6;
  }
  @keyframes bckanim {
    0% {background:rgba(22,62,112,0.5);}
    100% { background-color:transparent;}
  }
  .leverageContent .tableHead h6{
    font-size: 18px;
    text-align: center;
    color: #ffffff;
    max-width: 75%;
    margin: 0 auto 20px;
  }
  .leverageContent .darkBoxSpace h3{
    font-size: 24px;
    color: #81c8f6;
    margin-bottom: 10px;
  }
  .leverageBoxContent{
    background-color: #121126;
    border: 1px solid #959cab;
    padding: 20px 20px 0;
    text-align: center;
    height: 100%;
  }
  .leverageCenterContent{
    margin-top: 25px;
    margin-bottom: 60px;
  }
  .leverageCenterContent h2{
    font-size: 24px;
    color: #ffffff;
    text-align: center;
    text-decoration: underline;
    margin-bottom: 15px;
  }
  .leverageContent .darkBoxSpace .leverageCenterContent h3{
    font-size: 18px;
    text-align: center;
    font-weight: 600;
  }
  .leverageCenterContent .leverageBoxContent p{
    font-size: 16px;
    color: #ffffff;
  }
  .leverageCenterContent .leverageBoxContent p span{
    color: #ffffff;
  }
  .leverageCenterContent .leverageBoxContent p small{
    font-size: 24px;
    color: #81c8f6;
  }
  
  .buttonType2{
    background: transparent;
    color: #fff;
    border-radius: 25px;
    padding: 7px 20px;
    border: 1px solid #5087c0;
    font-size: 13px;
    font-weight: 500;
    box-shadow: 0 0 9px 1px #2765a7;
    text-transform: uppercase;
  }
  .buttonType2:hover {
    background: #5087c0;
      background-image: linear-gradient(180deg,#48a7e1 20%, #5087c0 50%);
      border: 1px solid #5087c0;
      color: #fff !important;
  }
  .groupContent h4{
    font-size: 18px !important;
  }
  .groupContent p{
    font-weight: 600;
  }
  .leaverageCalculator{
    background-color: #121a23;
    border: 1px solid #12161c;
    max-width: 420px;
    width: 100%;
    margin: 20px auto 0;
    display: block;
    padding: 20px;
    text-align: center;
  }
  .leaverageCalculator h2{
    font-size: 24px;
    text-align: center;
    margin-top: 20px;
  }
  .leaverageCalculator h2 span{
    display: block;
    color: #81c8f6;
    margin: 5px 0;
  }
  .leaverageCalculator h2 small{
    display: block;
  }
  .leaverageCalculator h6{
    color: #c3c3c3;
    font-size: 18px;
    font-weight: 700;
  }
  .leaverageCalculator p{
    color: #ffffff;
    font-weight: 600;
  }
  .leaverageCalculator input{
    background-color: #ffffff;
  }
  .chartcontainer{
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    height: 100%;
    min-height: 425px
    }
  .loginTrade{
      position: absolute;
      top: 0;
      background: rgba(18,26,35,0.75);
      width: 100%;
      height: 100%;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      padding: 0 50px;
  }
  .loginTrade p{
    margin-bottom: 30px;
  }
  .loginTrade a{
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
  }
  .btnGroupBorderBtn.fontSM{
    font-size: 12px;
  }
  .notification-container-bottom-center, .notification-container-bottom-left, .notification-container-bottom-right, .notification-container-mobile-bottom, .notification-container-mobile-top, .notification-container-top-center, .notification-container-top-left, .notification-container-top-right {
      position: fixed;
      z-index: 9999999;
  }
  
  .btnGroupBorderBtn{
      padding: 2px 25px 4px;
      border-radius: 5px;
      color: #81c8f6;
      border: 1px solid #81c8f6;
      margin-left: 0px;
      font-size: 14px;
      font-weight: 600;
      box-shadow: none !important;
  }
  .themeLight .btnGroupBorderBtn{
    border: 1px solid #81c8f6;
  }
  .btn-group.leverageBuySell {
      display: flex;
  }
  .btnGroupBorderBtn:hover, .btnGroupBorderBtn.active{
    color: #ffffff;
    background: #81c8f6;
  }
  .btnBuyBlue{
      padding: 2px 25px 4px;
      border-radius: 5px;
      margin-left: 0px;
      font-size: 14px;
      font-weight: 600;
      box-shadow: none !important;
      color: #fff;
      border: none;
      background: #81c8f6;
  }
  .btnBuyBlue:hover, .btnBuyBlue.active{
    color: #fff;
    border: none;
    background: #81c8f6;
  }
  .btnSellBlue{
      padding: 2px 25px 4px;
      border-radius: 5px;
      color: #fff;
    border-color: #81c8f6;
    background: #81c8f6;
      margin-left: 0px;
      font-size: 14px;
      font-weight: 600;
      box-shadow: none !important;
  }
  .btnSellBlue:hover, .btnSellBlue.active{
    color: #fff;
    border:none;
    background: #81c8f6;
  }
  .btnSellYellow{
      padding: 2px 25px 4px;
      border-radius: 5px;
      color: #81c8f6;
      border: none;
      margin-left: 0px;
      font-size: 14px;
      font-weight: 600;
      box-shadow: none !important;
  }
  .btnSellYellow:hover, .btnSellYellow.active{
    border: none;
    background: var(--themeclr);
    color: #000;
  }
  .btnBuyYellow{
      padding: 2px 25px 4px;
      border-radius: 5px;
      color: #81c8f6;
      border: 1px solid #81c8f6;
      margin-left: 0px;
      font-size: 14px;
      font-weight: 600;
      box-shadow: none !important;
  }
  .btnBuyYellow:hover, .btnBuyYellow.active{
    color: #fff;
    border-color: #81c8f6;
    background: #81c8f6;
  }
  #nav-conditional .number-input.number-input input[type="number"] {
    width: 82%;
  }
  .number-input.number-input input[type="number"]{
    background-color:#fff;
  }
  .colorBlue{
    color: var(--themeclr);
  }
  .rc-slider-mark-text{
    color: #ffffff !important;
  }
  .themeLight .rc-slider-mark-text{
    color: #969696 !important;
  }
  #nav-conditional .form-group label {
      font-size: 14px;
      color: #969696;
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 0;
  }
  #nav-conditional .form-group label span {
      color: #fff;
  }
  
  #nav-conditional ul.list2ColumnJustify li {
      padding-left: 0;
      padding-right: 0;
      font-size: 14px;
  }
  #nav-conditional ul.list2ColumnJustify li span {
      color: #969696;
  }
  
  .rightSelect{
      position: absolute;
      right: 15px;
      top: 4px;
      font-weight: 600;
      width: 130px;
  }
  .tradeLimitMarket .rightSelect .css-2b097c-container{
    border: 0 !important;
    background: transparent;
  }
  .tradeLimitMarket .css-2b097c-container{
    border: 0 !important;
    background: #12161c !important;
  }
  .tradeLimitMarket .rightSelect .css-tlfecz-indicatorContainer, .tradeLimitMarket .css-tlfecz-indicatorContainer{
    color: #81c8f6 !important;
  }
  /*.show {
    z-index: 9999 !important;
  }*/
  .rembordor tbody{
    border:none !important;
  }
  .tradeBuy td{
    border-color: #33465c;
    color: #141313;
    padding: 5px 15px;
    font-size: 13px;
  }
  .tradeBuy td span{
    color: #81c8f6;
  }
  .checkConfirmationText{
    color: #969696;
    font-size: 13px;
  }
  .temp-link{
    color:white;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 5px;
  }
  .rangeslider, .rangeslider .rangeslider__fill {
      display: block;
      box-shadow: none !important;
      background: #12161c !important;
  }
  .rangeslider-horizontal .rangeslider__handle {
      width: 18px !important;
      height: 18px !important;
      background: #12161c !important;
      border: 2px solid #d1ac32 !important;
  }
  .rangeslider-horizontal .rangeslider__handle::after {
      width: 8px !important;
      height: 8px !important;
      background-color: #81c8f6 !important;
      box-shadow: none !important;
      top: 3px !important;
      left: 3px !important;
  }
  
  .rangeslider-horizontal{
    height: 4px !important
  }
  .rangeslider-horizontal .rangeslider__fill{
    background-color: #81c8f6 !important;
  }
  .nodata{
    border-top: 0;
      font-size: 12px;
      font-weight: 400;
      color: #dcdcdc;
      padding: 4px 15px;
  }
  .tradeLimitMarket .css-1wy0on6 {
      position: absolute !important;
      top: 0 !important;
      right: 0 !important;
  }
  .css-2b097c-container {
      position: relative;
      box-sizing: border-box;
      border: 1px solid var(--themeclr);
      border-radius: 5px;
      background: #000;
      color: #000000;
  }
  .lighttheme .css-2b097c-container {
      position: relative;
      box-sizing: border-box;
      border: 1px solid #cccccc;
      border-radius: 5px;
      background: #ffffff;
  }
  .trollBox .customScroll {
      max-width: 95%;
  }
  .css-1hb7zxy-IndicatorsContainer{
    position: absolute;
      right: 0;
      top: 0;
  }
  .paddingTopTen{
    padding-top: 10px !important;
  }
  .themeLight .css-1hwfws3, .css-1wa3eu0-placeholder, .css-zk88xw-singleValue
  {
    color:#000000 !important;
  }
  .css-1hwfws3,  .css-1wa3eu0-placeholder, .css-zk88xw-singleValue{
    /* color:#fff !important; */
    color:var(--themeclr) !important;
  }
  .css-tlfecz-indicatorContainer {
      color: #374454 !important;
    }
  .css-1okebmr-indicatorSeparator{
    width: 0 !important;
  }
  .css-26l3qy-menu{
  background: #81c8f6 !important;
  }
  .css-1579d96-option, .css-nbylj9-option{
    color: #fff !important;
    border-bottom: 0 !important;
    padding:10px 15px !important;
    border:0 !important;
    font-size: 14px !important;
  }
  
  .css-nbylj9-option, .css-h4cczp-option{
    background-color: #81c8f6 !important;
    color: #fff !important;
    border-bottom: 0 !important;
    padding: 10px 15px !important;
    border:0 !important;
    font-size: 14px !important;
  }
  .pairSearch .form-control {
      padding: 0 15px;
      border: 0 !important;
      background: #02071e !important;
      color: #fff !important;
  }
  .pairSearch input.form-control::placeholder {
    color: #bcbcbc;
  }
  .pairSearch .input-group-text{
    background: #02071e !important;
    border: 0 !important;
    color: #81c8f6 !important;
  }
  .themeLight .css-1hwfws3,  .css-1wa3eu0-placeholder, .css-zk88xw-singleValue{
    /*color:#fff !important;*/
  }
  .themeLight .css-tlfecz-indicatorContainer {
      color: #81c8f6 !important;
    }
  .themeLight .css-1okebmr-indicatorSeparator{
    width: 0 !important;
  }
  .themeLight .css-26l3qy-menu{
  background: #cccccc !important;
  }
  .themeLight .css-1579d96-option, .css-nbylj9-option{
    color: #3a3a3a !important;
    border-bottom: 0 !important;
    padding:10px 15px !important;
    border:0 !important;
    font-size: 14px !important;
  }
  .themeLight .css-nbylj9-option, .css-h4cczp-option{
    background-color: #81c8f6 !important;
    color: #ffffff !important;
    border-bottom: 0 !important;
    padding: 10px 15px !important;
    border:0 !important;
    font-size: 14px !important;
  }
  
  
  .tradeLimitMarket .css-1579d96-option, .css-nbylj9-option{
    font-size: 14px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    color: #ffffff;
  }
  .tradeLimitMarket .css-nbylj9-option, .css-h4cczp-option{
    font-size: 12px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .btnDefaultNew {
    background: #5087c0;
    background-image: linear-gradient(180deg,#48a7e1 20%, #5087c0 50%);
    color: #fff !important;
    border-radius: 25px;
    padding: 7px 20px !important;
    border: 1px solid #5087c0;
    font-size: 13px !important;
    font-weight: 500 !important;
    transition: .2s all;
    /* box-shadow: 0 0 9px 1px #2765a7; */
    text-transform: uppercase;
  }
  .btnDefaultNew:hover {
    background: transparent;
    border: 1px solid #5087c0;
    color: #fff;
  }
  .nodata{
    display: block;
    width: 100%;
    font-size: 12px;
    margin-top: 10px;
    text-align: center;
  }
  .modal-body .checkbox .cr, .radio .cr{
    margin-top: 2px;
  }
  .btnDefaultNewBlue {
    background: transparent;
      color: #fff;
      border-radius: 25px;
      padding: 7px 20px;
      border: 1px solid #5087c0;
      font-size: 13px;
      font-weight: 500;
      transition: .2s all;
      /* box-shadow: 0 0 9px 1px #2765a7; */
      text-transform: uppercase;
  }
  .btnDefaultNewBlue:hover
  {
    background: #5087c0;
      background-image: linear-gradient(180deg,#48a7e1 20%, #5087c0 50%);
      border: 1px solid #5087c0;
      color: #fff;
  }
  .modal-body .form-group label {
      width: auto;
      display: block;
      font-size: 14px;
      color: #000;
  }
  .modal-content .niceStyleSelect.nice-select {
      width: 100%;
      border: 1px solid #12161c;
      border-radius: 5px;
      background: #1b2c52;
      color: #fff;
  }
  
  .form-group label {
      width: 100%;
      display: block;
      font-size: 14px;
      display: block;
      width: 100%;
      color: #fff;
      margin-bottom: 10px;
  }
  .lighttheme .form-group label{
    color: #000;
  }
  .dropdown-toggle::after {
    content: "\f107";
    border: 0;
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    position: absolute;
    margin-left: 8px;
  
  }
  .dropdown-toggle::after{
    color: #000;
  }
  .darktheme .dropdown-toggle::after{
    color: #fff;
  }
  .selectCoinType .dropdown-menu {
      background: linear-gradient(90deg,#ed7c02,#ffa100);
      width: 200px;
      border-radius: 0;
      margin: 0;
      padding: 0;
      position: absolute;
  }
  .apiContent p {
      font-size: 14px;
  }
  .apiContent p span{
    color: #d40065
  }
  .apiContent button{
    border: 0;
  }
  
  .close{
    color: #fff;
    transition: .5s;
  }
  .close:hover{
    color: #81c8f6;
  }
  /* .modal-content {
      background: #1b2c52;
      color: #ffffff;
  } */
  /* .modal-header{
    border-bottom: 1px solid #12161c;
  } */
  .modal-footer{
    border-top: 0;
  }
  .assetTableTop h4{
    color: #ffffff;
  }
  .themeLight .selectCoinType .dropdown-menu {
    background:#fafafa;
  }
  #searchpair {
      padding: 0 10px;
      height: 36px;
      box-shadow: none !important;
  }
  .favStarIcon{
    font-size: 11px;
    cursor: pointer;
    transition: .5s;
  }
  .favStarIcon:hover{
    color: #81c8f6;
  }
  .selectCoinType .btn-group img { margin-right: 5px; border-radius: 50%;}
  .selectCoinType .dropdown-toggle {}
  .selectCoinType .dropdown-toggle .glyphicon { margin-left: 20px; margin-right: 0px }
  .selectCoinType .dropdown-header {
      margin: 0;
      padding: 0;
      float: none;
      width: 100%;
  }
  .selectCoinType .dropdown-header h6{padding: 10px 15px; color: #fff; font-size: 14px; background-color: #81c8f6; font-weight: 700; margin: 0;}
  .selectCoinType .dropdown-header ul li{font-weight: normal; padding: 7px 15px; cursor: pointer; color: #fff;}
  .selectCoinType .dropdown-header ul li a{color: #fff;}
  .themeLight .selectCoinType .dropdown-header ul li{color: #000;}
  .selectCoinType .dropdown-header ul li:hover, .selectCoinType .dropdown-header ul li.active{background-color: #81c8f6;}
  .themeLight .selectCoinType .dropdown-header ul li:hover, .selectCoinType .dropdown-header ul li.active{background-color: #81c8f6; color: #fff;}
  .selectCoinType .btn.btn-default.dropdown-toggle {background: transparent; border: 0; box-shadow: none !important; color: #fff; font-size: 14px;}
  .themeLight .selectCoinType .btn.btn-default.dropdown-toggle{color: #000}
  
  input[type=button], input[type=submit], input[type=reset], input[type=file]  {
    background: #5087c0;
    background-image: linear-gradient(180deg,#48a7e1 20%, #5087c0 50%);
    color: #fff;
    border-radius: 25px;
    padding: 7px 20px;
    border: 1px solid #5087c0;
    font-size: 13px;
    font-weight: 500;
    transition: .2s all;
    box-shadow: 0 0 9px 1px #2765a7;
    margin-left: auto;
    margin-right: auto;
    transition: all 0.3s ease-in-out;
    width: auto;
    cursor: pointer;
    display: block;
    outline: none;
    -webkit-box-shadow: 0 0 9px 1px #2765a7 !important;
    -moz-box-shadow: 0 0 9px 1px #2765a7 !important;
    box-shadow: 0 0 9px 1px #2765a7 !important;
  }
  .formInlineCommon input[type="button"], .formInlineCommon input[type="submit"], .formInlineCommon input[type="reset"]{
    width: auto;
  }
  .securityphone{
    position: absolute;
      bottom: 0;
      right: 0;
      padding: 3px;
  }
  input[type=button]:hover, input[type=submit]:hover, input[type=reset]:hover  {
    background: transparent;
    color: #81c8f6;
    border-color: #81c8f6;
  }
  
  input[type=button]:active, input[type=submit]:active, input[type=reset]:active  {
    -moz-transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -o-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95);
  }
  
  input[type=text], 
  input[type=password], 
  input[type=email], 
  input[type=number], 
  textarea, 
  textarea.form-control {
    background-color: transparent;
    border: 1px solid var(--themeclr);
    color: #fff !important;
    padding: 13px;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 0px;
    width: 100%;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-border-radius: 10px 10px 10px 10px;
    border-radius: 5px;
    resize: none;
    box-shadow: none !important;
    height: 52px;
  }
  .darktheme .stoForm input[type=text], 
  .darktheme .stoForm input[type=password], 
  .darktheme .stoForm input[type=email], 
  .darktheme .stoForm input[type=number], 
  .darktheme .stoForm textarea, 
  .darktheme .stoForm textarea.form-control{
  background-color: #000;
  }
  .lighttheme .stoForm input[type=text], 
  .lighttheme .stoForm input[type=password], 
  .lighttheme .stoForm input[type=email], 
  .lighttheme .stoForm input[type=number], 
  .lighttheme .stoForm textarea, 
  .lighttheme .stoForm textarea.form-control{
  background-color: #fff;
  }
  input[type=text]:focus, input[type=password]:focus, input[type=email]:focus, input[type=number]:focus, input[type=search]:focus, textarea:focus, textarea.form-control:focus {
    background-color: #03081f;
    border: 1px solid #81c8f6;
    color: #fff;
  }
  
  .spotForm{
    min-height: 300px;
  }
  .border-right-one{
    border-right: 1px solid #12161c;
  }
  .border-bottom-one{
    border-bottom: 1px solid #060b27;
  }
  .spotLimitHead p{
    color: #ffffff;
    font-size: 14px;
    margin-right:15px;
  }
  .spotLimitHead p span{
    color: #81c8f6;
  }
  .btn-group.special {
    display: flex;
  }
  
  .special .btn {
    flex: 1
  }
  .spotForm input[type=text],
  .spotForm input[type=password],
  .spotForm input[type=email],
  .spotForm input[type=number],
  .spotForm textarea,
  .spotForm textarea.form-control {
    background-color: #02071e;
    border: 0px solid #81c8f6;
    color: #ffffff;
    padding: 15px;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 0px;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-border-radius: 2px 2px 2px 2px;
    border-radius: 2px 2px 2px 2px;
    resize: none;
    box-shadow: none !important;
  }
  
  .spotForm input[type=text]:focus,
  .spotForm input[type=password]:focus,
  .spotForm input[type=email]:focus,
  .spotForm input[type=number]:focus,
  .spotForm input[type=search]:focus,
  .spotForm textarea:focus,
  .spotForm textarea.form-control:focus {
    background-color: #12161c;
    border: 0px solid #81c8f6;;
    color: #ffffff;
  }
  .spotForm .form-group label{
    margin-bottom: 0;
  }
  .spotForm .input-group-text{
    background-color: #02071e;
    color: #81c8f6;
    border: 0;
    font-size: 14px;
    min-width: 60px;
  }
  .spotForm .input-group-append {
    border-left: 1px solid #1b2c52;
    margin-left: 0;
  }
  .css-b8ldur-Input input, .css-1g6gooi input{
    height: auto;
  }
  .react-datepicker__input-container input{
    /* padding: 7px 15px !important; */
  }
  .form-control:disabled, .form-control[readonly] {
    background-color: #03081f !important;
    border: 1px solid #81c8f6 !important;
    color: #464444 !important;
    padding: 25px 15px;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 0px;
    width: 100%;
    transition: all 0.5s ease-in-out;
    border-radius: 5px;
    resize: none;
    box-shadow: none !important;
  }
  input:placeholder {
    color: #cccccc;
  }
  
  .checkbox label:after,
  .radio label:after {
      content: '';
      display: table;
      clear: both;
  }
  
  .checkbox .cr,
  .radio .cr {
      position: relative;
      display: inline-block;
      border: 1px solid #81c8f6;
      background: #12161c;
      border-radius: 0em;
      width: 20px;
      height: 20px;
      float: left;
      margin-right: .5em;
      margin-top: 3px;
  }
  .wallet-side-menu .nav-item.active {
    background-color: rgba(255, 255, 255, .2);
  }
  .themeLight .checkbox .cr,
  .themeLight .radio .cr {
      position: relative;
      display: inline-block;
      border: 1px solid #ccc;
      background: #fff;
      border-radius: 0em;
      width: 20px;
      height: 20px;
      float: left;
      margin-right: .5em;
      margin-top: 3px;
  }
  
  .radio .cr {
      border-radius: 50%;
  }
  
  .checkbox .cr .cr-icon,
  .radio .cr .cr-icon {
      position: absolute;
      font-size: 10px;
      line-height: 0;
      top: 9px;
      left: 4px;
      color: #81c8f6;
  }
  
  .radio .cr .cr-icon {
      /*margin-left: 0.04em;*/
  }
  
  .checkbox label input[type="checkbox"],
  .radio label input[type="radio"] {
      display: none;
  }
  
  .checkbox label input[type="checkbox"] + .cr > .cr-icon,
  .radio label input[type="radio"] + .cr > .cr-icon {
      transform: scale(3) rotateZ(-20deg);
      opacity: 0;
      transition: all .3s ease-in;
  }
  
  .checkbox label input[type="checkbox"]:checked + .cr > .cr-icon,
  .radio label input[type="radio"]:checked + .cr > .cr-icon {
      transform: scale(1) rotateZ(0deg);
      opacity: 1;
  }
  
  .checkbox label input[type="checkbox"]:disabled + .cr,
  .radio label input[type="radio"]:disabled + .cr {
      opacity: .5;
  }
  
  .transYellowButton{
    background: transparent;
    /* color: #fff */
    color: var(--themeclr);
    border-radius: 5px;
    padding: 7px 20px;
    border: 1px solid var(--themeclr);
    font-size: 13px;
    font-weight: 500;
    transition: .2s all;
    /* box-shadow: 0 0 9px 1px #2765a7; */
    text-transform: uppercase;
  }
  .transYellowButton:hover{
    background: var(--themeclr);
    border: 1px solid #5087c0;
    color: #fff;
  }
  .pinkText{
    color: #d40065;
  }
  .navbar-nav > li > .dropdown-menu {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    display: block;
    transform: scale(0.9);
    opacity: 0;
    transition: ease-out 0.15s;
    left: auto;
    right: 0;
  }
  
  
  
  .dropdown-menu {
    pointer-events: none;
  }
  .dropdown:hover .dropdown-menu {
    transform: scale(1);
    opacity: 1;
    pointer-events: auto;
  }
  
  
  ul.prod-gram {
      letter-spacing: 0em;
      font-size: 14px;
      line-height: 2.4;
      float: left;
      list-style: none;
      padding: 5px 0px;
      margin: 0;
      height: 40px;
      position: relative;
      min-width: 125px;
  }
  
  ul.prod-gram li.init {
    cursor: pointer;
  }
  
  ul.prod-gram li {
    z-index: 2;
    cursor: pointer;
    width: 100%;
    padding: 0 20px;
  
    transition: ease-out 0.3s;
  }
  ul.prod-gram li img{
    margin-right: 5px;
  }
  ul.prod-gram li:not(.init) {
    width: 100%;
    display: none;
    background: #121a23;
    margin: 0px;
    padding: 5px 20px;
  }
  .themeLight ul.prod-gram li:not(.init) {
    background: #fafafa;
  }
  ul.prod-gram li:not(.init):hover {
    background: #81c8f6;
  }
  .customNavTrade {
    background-color: #000;
    border-bottom: none !important;
    transition: .5s;
    /* background-image: url(../images/bg-trip.png); */
    background-repeat: repeat;
    background-size: 100% 100%;
    background-position: top;
  }
  .themeLight .customNavTrade {
    background-color: #ffffff;
    border-bottom: 1px solid #c8c8c8;
    transition: .5s;
  }
  .customNavTrade .nav-item.regButton .nav-link {
      background-color: #81c8f6;
      border-radius: 5px;
      margin-left: 15px;
      padding: 10px 20px;
      font-size: 12px;
      color: #fff;
      text-transform: uppercase;
      transition: .5s;
  }
  .customNavTrade .nav-item.regButton .nav-link:hover {
    background-color: #81c8f6;
    color: #fff;
  }
  .customNavTrade .nav-item{
    margin-right: 0;
    margin-left: 0;
  }
  .customNavTrade .nav-item .nav-link {
      text-transform: uppercase;
      color: #d2d3d8;
      font-size: 13px;
      font-weight: 500;
      border-left: 0px solid #242d39;
      padding-left: 20px;
      padding-right: 20px;
      transition: .5s;
  }
  .themeLight .customNavTrade .nav-item .nav-link {
    color: #000000;
  }
  .customNavTrade .nav-item.regButton .nav-link {
      margin-right: 15px;
  }
  .themeLight .customNavTrade .nav-item.regButton .nav-link {
    color: #ffffff;
  }
  .themeLight .customNavTrade .nav-item .nav-link {
    border-left: 0px solid #c8c8c8;
  }
  .customNavTrade .nav-item .nav-link:hover {
    color: #5087c0;
  }
  
  .iconNav{
    display: block;
    background-image: url('../images/trademainnavicons.png');
  }
  .bg-bellIcon {
      width: 15px; height: 17px;
      background-position: -10px -10px;
  }
  .customNavTrade .nav-item:hover .nav-link .bg-bellIcon {
      background-position: -10px -47px;
  }
  
  .bg-mobileIcon {
      width: 12px; height: 19px;
      background-position: -10px -84px;
  }
  .customNavTrade .nav-item:hover .nav-link .bg-mobileIcon {
      background-position: -10px -123px;
  }
  
  .bg-sunIcon {
      width: 21px; height: 21px;
      background-position: -10px -162px;
  }
  .customNavTrade .nav-item:hover .nav-link .bg-sunIcon {
      background-position: -10px -203px;
  }
  
  .themeLight .bg-sunIcon {
      width: 21px; height: 16px;
      background: url('../images/moonIconS.png') -10px -10px;
  }
  .themeLight .customNavTrade .nav-item:hover .nav-link .bg-sunIcon {
     width: 21px; height: 16px;
      background: url('../images/moonIconS.png') -10px -46px;
  }
  
  
  .selectCoinType{
    position: relative;
    padding-right: 15px;
    margin-right: 15px;
  }
  .prod-gram::after{
    content: "";
    background: url(../images/downarrow.png) no-repeat scroll 0 0;
    width: 11px;
    height: 7px;
    position: absolute;
    right: 0px;
    top: 48%;
    transition: .2s;
    z-index: -1;
  }
  .prod-gram.open::after{
    transform: rotate(180deg);
  }
  .headerOverviewGroup {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
      padding: 8px 15px;
      border-left: 1px solid #1b2c52;
      border-right: 1px solid #1b2c52;
  }
  .tradeMain .headerOverviewGroup{
    border-left: 0px solid #1b2c52;
    border-right: 0px solid #1b2c52;
  }
  .themeLight .header-overview {
    border-left: 1px solid #c8c8c8;
    border-right: 1px solid #c8c8c8;
  }
  .headerOverviewGroup {
      display: flex;
  }
  .headerOverviewStatus{
    margin: 0 12px;
  }
  .headerOverviewStatus h5{
    font-size: 13px;
    color: #ffffff;
    font-weight: 600;
    margin: 0;
  }
  .themeLight .headerOverviewStatus h5{
    color: #000;
  }
  .headerOverviewStatus h5.pinkText{
    color: #d40065;
  }
  .headerOverviewStatus h5 small{
    display: block;
    color: #bcbcbc;
    font-size: 12px;
  }
  .themeLight .headerOverviewStatus h5 small{
    color: #969696;
  }
  .tradeMain {
    padding-top: 46px;
    margin-top: 20px;
  }
  .lighttheme .tradeMain {
    background: #f2f2f2;
  }
  .tableHead {
      display: flex;
      justify-content: space-between;
      align-items: center;
      /* border-bottom:1px solid #12161c; */
      margin-bottom: 0px;
      padding-bottom: 8px;
  }
  .themeLight .tableHead{
    border-bottom:1px solid #c8c8c8;
  }
  .inputGroup{
    margin-right: 15px;
  }
  .inputGroup .btn{
    padding: 0;
    border-radius: 0;
    box-shadow: none !important;
    background: transparent;
    margin-left: 5px;
  }
  .darkBox {
     background: #1e2026;
    border: 1px solid #12161c;
    border-bottom: 0;
    /* padding: 8px 0px; */
    margin-bottom: 10px;
    box-shadow: none;
  }
  .tradeMain .darkBox{
    margin-bottom: 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    border-top:0;
  
  }
  .tradePage .darkBox{
    border-bottom: 0;
    border-right: 0;
  }
  .flexColumn{
    display: flex;
  }
  .header-overview {
    background-color: #1b2c52;
  }
  .header-overview.header-overview_trade {
    background-color: #12161c;
  }
  .tradeLimitMarket{
    position: relative;
  }
  .tradeLimitMarket .css-1wa3eu0-placeholder {
      font-weight: normal;
      font-size: 14px;
      color: #969696 !important;
  }
  .themeLight .darkBox {
    background: #fafafa;
    border:1px solid #c8c8c8;
  }
  .list2ColumnJustify label{
    color: #ffffff;
  }
  .themeLight .list2ColumnJustify label{
    color: #969696;
  }
  .tableHead h4{
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 0 15px;
    color: #ffffff;
  }
  .tradeTableTitle{
    /* border-top: 1px solid #12161c; */
    border-bottom: 1px solid #12161c;
  }
  .lighttheme .tradeTableTitle{
    /* border-top: 1px solid #12161c; */
    border-bottom: 1px solid #eaeaea;
  }
  .themeLight .tradeTableTitle{
    border-top: 1px solid #c8c8c8;
    border-bottom: 1px solid #c8c8c8;
  }
  .tradeTableTitle span{
    text-align: center;
    color: #bcbcbc;
    font-size: 12px;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .themeLight .tradeTableTitle span{
    color: #969696;
  }
  .tradeTableBodyRow span{
    text-align: center;
    color: #bcbcbc;
    font-size: 12px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .themeLight .tradeTableBodyRow span{
    color: #969696;
  }
  
  .tradeTableBodyRow span i{
    margin-right: 5px;
  }
  .tradeTableBodyRow.highLight span{
    color: #8f8f8f;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
  }
  .tradeTableBodyRow.highLight span i{
    margin-right: 5px;
  }
  
  .tradeTabBoxStyle .nav-tabs {
    border-bottom: 0;
    padding-bottom: 0;
  
  }
  .tradeTabBoxStyle .nav-tabs.subLevelTabs {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #3a4a81;
    margin: 0 20px
  }
  .tradeTabBoxStyle .nav-tabs .nav-link {
    padding: 2px 10px 4px;
    border-radius: 0px;
    color: #ffffff;
    border-top:0;
    border-left:0;
    border-right:0;
    border-bottom: 2px solid transparent;
    margin-left: 15px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }
  .themeLight .tradeTabBoxStyle .nav-tabs .nav-link{
    color: #969696;
  }
  .themeLight .tradeTabBoxStyle .nav-tabs .nav-link:hover{
    color: #969696;
  }
  .tradeTabBoxStyle .nav-tabs.subLevelTabs .nav-link {
    border-left: 0;
    border-top: 0;
    border-right: 0;
    border-radius: 0;
    color: #bcbcbc
  }
  .tradeTabBoxStyle .nav-tabs .nav-item.show .nav-link,
  .tradeTabBoxStyle .nav-tabs .nav-link.active {
    background: 0 0;
    border-color: #81c8f6;
    color: #81c8f6;
  }
  .tradeTabBoxStyle .nav-tabs.subLevelTabs .nav-item.show .nav-link,
  .tradeTabBoxStyle .nav-tabs.subLevelTabs .nav-link.active {
    background: 0 0;
    border-bottom: 3px solid #0090ff;
    color: #fff
  }
  .themeLight .tradeTabBoxStyle .nav-tabs.subLevelTabs .nav-item.show .nav-link,
  .themeLight .tradeTabBoxStyle .nav-tabs.subLevelTabs .nav-link.active {
    color: #969696;
  }
  .tradeTabBoxStyle .nav-tabs .nav-link:focus,
  .tradeTabBoxStyle .nav-tabs .nav-link:hover {
    background: 0 0;
    border-color: #81c8f6;
    color: #ffffff;
  }
  .themeLight .tradeTabBoxStyle .nav-tabs .nav-link:focus,
  .themeLight .tradeTabBoxStyle .nav-tabs .nav-link:hover {
    background: 0 0;
    border-color: #81c8f6;
    color: #969696;
  }
  .tradeTabBoxStyle .nav-tabs.subLevelTabs .nav-link:focus,
  .tradeTabBoxStyle .nav-tabs.subLevelTabs .nav-link:hover {
    color: #fff;
    border-bottom-width: 3px
  }
  .themeLight .tradeTabBoxStyle .nav-tabs.subLevelTabs .nav-link:focus,
  .themeLight .tradeTabBoxStyle .nav-tabs.subLevelTabs .nav-link:hover {
    color: #969696;
    border-bottom-width: 3px
  }
  .tradeMain .tradeTabBoxStyle .tableHead{
    padding-bottom: 0;
  }
  .pinkText {
      /* color: #df0b5b !important; */
      color: #f84960 !important;
  }
  .yellowText{
    color: #6eb8ff !important;
  }
  .lighttheme .tradeFulltabbedTable  .yellowText{
    color: var(--themeclr) !important;
  }
  .greenText{
    /* color: #3dc90d !important; */
    color:#81c8f6 !important
  }
  .lighttheme .greenText{
    color: var(--themeclr) !important;
  }
  .headerOverviewStatus .greenText{
    color: #6eb8ff !important;
  }
  .greyText{
    color: #6eb8ff !important;
  }
  .border-bottom-zero{
    border-bottom: 0 !important;
  }
  .border-right-zero{
    border-right: 0 !important;
  }
  .tradeTableBodyRow.even{
    background-color: rgba(255, 255, 255, 0.04);
    transition: .5s;
  }
  .themeLight .tradeTableBodyRow.even{
    background: #e0e0e0;
  }
  .chartTradeSection img{
    width: 100%;
    height:100%;
  }
  .chartTradeSection {
    height: 500px;
    border-top: 1px solid #12161c;
    border-bottom: 1px solid #12161c;
    background-color: #1b2c52;
  }
  .dervitaviChartHeight{
    height: 427px;
  }
  .customScroll {
      max-height: 200px;
  }
  
  .tradeFulltabbedTable{
    min-height: 250px;
  }
  .tradeFulltabbedTable.tradeFulltabbedTable_h
  {
    min-height: 248px !important;
  max-height: 248px !important;
  overflow-y: auto;
  }
  .tradeFulltabbedTable .nav-tabs {
    border-bottom: 1px solid #1b2c52;
  }
  .themeLight .tradeFulltabbedTable .nav-tabs {
    border-bottom: 1px solid #c8c8c8;
  }
  .tradeFulltabbedTable .nav-tabs .nav-link{
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
  }
  .tradeFulltabbedTable .nav-tabs .nav-link{
    background: transparent !important;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-radius: 0 !important;
    transition: none;
  }
  .tradeFulltabbedTable .nav-tabs .nav-link:focus, .tradeFulltabbedTable .nav-tabs .nav-link:hover{
    background: transparent !important;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-radius: 0 !important;
    border-bottom: 2px solid #81c8f6 !important;
    color: #fff !important;
  }
  .tradeFulltabbedTable .nav-tabs .nav-item.show .nav-link, .tradeFulltabbedTable .nav-tabs .nav-link.active{
    background: transparent !important;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-radius: 0 !important;
    border-bottom: 2px solid #81c8f6 !important;
    color: #81c8f6 !important;
  }
  .themeLight .tradeFulltabbedTable .nav-tabs .nav-item.show .nav-link, .themeLight .tradeFulltabbedTable .nav-tabs .nav-link.active, .themeLight .tradeFulltabbedTable .nav-tabs .nav-link:focus, .themeLight .tradeFulltabbedTable .nav-tabs .nav-link:hover{
    color: #000 !important;
  }
  .tradeFulltabbedTable .table thead th{
    border-bottom: 0;
    vertical-align: top;
  }
  
  .tradeFulltabbedTable .table th{
    border-top: 0;
    font-size: 12px;
    font-weight:700;
    color: #dcdcdc;
    padding: 5px 15px;
  }
  .themeLight .tradeFulltabbedTable .table th{
    color: #5a5a5a;
  }
  .tradeFulltabbedTable .table td{
    border-top: 0;
    font-size: 12px;
    font-weight:400;
    color: #242424;
    padding: 4px 15px;
  }
  .darktheme .tradeFulltabbedTable .table td{
    color: #969696;
  }
  .table-striped tbody tr:nth-of-type(2n+1) {
    background-color: rgba(255, 255, 255, 0.04);
  }
  .darktheme .table-striped tbody tr:nth-of-type(2n) {
      background-color: #1e2026;
  }
  .themeLight .table-striped tbody tr:nth-of-type(2n+1) {
      background-color: #ffffff;
  }
  .lighttheme .table-striped tbody tr:nth-of-type(2n) {
      background-color: #e4e4e4;
  }
  ul.list2ColumnJustify{
    padding: 10px 0 0 0;
  }
  ul.list2ColumnJustify li{
    font-size: 12px;
    color:#969696;
    padding: 0px 15px;
    display: flex;
    justify-content: space-between;
  }
  ul.list2ColumnJustify li span{
    color:#81c8f6;
  }
  ul.list2ColumnJustify li span a{
    text-decoration: underline;
  }
  ul.list2ColumnJustify li span a:hover{
    text-decoration: none;
  }
  .tradeFulltabbedTable thead {
    background: #12161c;
    border-left: 0;
    border-right: 0;
    border-color: #12161c;
  }
  .themeLight .tradeFulltabbedTable thead {
    background: #c8c8c8;
    border-left: 0;
    border-right: 0;
    border-color: #c8c8c8;
  }
  .tradeFulltabbedTable tbody {
    background: #1e2026;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    border-color: #12161c;
  }
  .tradeMain .tableHead{
    border-bottom: 0;
  }
  .tradeFooter{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 0px;
    background: #121126;
    padding: 15px;
  }
  .themeLight .tradeFooter{
    background: #ffffff;
  }
  .tradeFooterBox .tradeFooter{
    margin-top: 0;
    border-top: 1px solid #12161c;
  }
  .themeLight .tradeFooterBox .tradeFooter{
    margin-top: 0;
    border-top: 1px solid #c8c8c8;
  }
  .tradeFooter li{
    display: inline-block;
    margin: 0 15px;
  }
  .tradeFooter .tradeFooterLinks li a{
    font-size: 14px;
  }
  .tradeFooter .tradeSocialLinks li a{
     font-size: 18px;
     color: #ffffff;
  }
  .tradeFooter li a{
    color: #ffffff;
  }
  .themeLight .tradeFooter li a{
    color: #969696;
  }
  .tradeFooter li a:hover{
    color: #81c8f6;
  }
  .tradeFooter li:first-child{
    margin-left: 0;
  }
  .tradeFooter li:last-child{
    margin-right: 0;
  }
  
  .tradeLimitMarket .nav-tabs {
      /* border-bottom: 1px solid #12161c; */
      padding-bottom: 0px;
  }
  .themeLight .tradeLimitMarket .nav-tabs {
    border-bottom: 1px solid #c8c8c8;
  }
  .tradeLimitMarket .nav-tabs .nav-link{
    padding: 2px 10px 4px;
    border-radius: 0px;
    color: #ffffff;
    border-bottom:2px solid transparent;
    margin-left: 15px;
    font-size: 14px;
    font-weight: 600;
    border-top:0;
    border-left: 0;
    border-right: 0;
  }
  
  .tradeLimitMarket .nav-tabs .nav-item.show .nav-link,
  .tradeLimitMarket .nav-tabs .nav-link.active{
    background: transparent;
    border-color: var(--themeclr);
    color: var(--themeclr);
  }
  .tradeLimitMarket .nav-tabs .nav-link:focus{
    background: transparent;
    border-color: #81c8f6;
    color: #81c8f6;
  }
  .tradeLimitMarket .nav-tabs .nav-link:hover{
    background: transparent;
    border-color: #81c8f6;
    color: #ffffff;
  }
  
  
  
  .themeLight .tradeLimitMarket .nav-tabs .nav-link{
    padding: 2px 10px 4px;
    border-radius: 0px;
    color: #969696;
    border-bottom:2px solid transparent;
    margin-left: 15px;
    font-size: 14px;
    font-weight: 600;
    border-top:0;
    border-left: 0;
    border-right: 0;
  }
  
  .themeLight .tradeLimitMarket .nav-tabs .nav-item.show .nav-link,
  .themeLight .tradeLimitMarket .nav-tabs .nav-link.active{
    background: transparent;
    border-color: #81c8f6;
    color: #81c8f6;
  }
  .themeLight .tradeLimitMarket .nav-tabs .nav-link:focus{
    background: transparent;
    border-color: #81c8f6;
    color: #81c8f6;
  }
  .themeLight .tradeLimitMarket .nav-tabs .nav-link:hover{
    background: transparent;
    border-color: #81c8f6;
    color: #969696;
  }
  
  
  
  .tradeLimitMarket.assetsTable .nav-tabs .nav-link{
    font-size: 16px;
    padding: 4px 15px 6px;
  }
  
  .mbMinus{
    margin-bottom: -35px !important;
  }
  
  .assetsTable .nav-tabs {
      border-bottom: none !important;
      padding-bottom: 10px;
  }
  .assetsTable .nav-tabs .nav-link{
    padding: 2px 10px 4px;
    border-radius: 5px;
    color: #ffffff;
    border:1px solid #959cab;
    margin-left: 15px;
    font-size: 14px;
    font-weight: 400;
  }
  .assetsTable .nav-tabs .nav-item.show .nav-link, .assetsTable .nav-tabs .nav-link.active{
    background: transparent;
    border-color: var(--themeclr);
    color:var(--themeclr);
  }
  .assetsTable .nav-tabs .nav-link:focus, .assetsTable .nav-tabs .nav-link:hover{
    background: var(--themeclr);
    border-color: var(--themeclr);
    color: #121126;
  }
  .assetsTable .nav-tabs .nav-link{
    font-size: 16px;
    padding: 4px 15px 6px;
  }
  .assetHistoryTableTop{
    margin: 15px;
  }
  .assetsTable .niceStyleSelect.nice-select{
    width: 100%;
    border:1px solid #374454;
    border-radius: 5px;
    background: #121a23;
    color: #fff;
  }
  .supportTicket .niceStyleSelect.nice-select{
    width: 140px;
    border:1px solid var(--themeclr);
    border-radius: 5px;
    background: transparent;
    margin-right: 25px;
    color: #fff;
    width: 100%;
    height: 46px;
    line-height: 44px;
  }
  .lighttheme .supportTicket .niceStyleSelect.nice-select{
    color: #000;
  }
  .assetsTable input.datepicker{
    background: #121a23;
    margin-right: 25px;
    border:1px solid #374454;
    width: 100%;
    border-radius: 5px;
    box-shadow: none !important;
    color: #fff;
    font-size: 14px;
    padding: .375rem .75rem;
  }
  .border-top-one{
    /* border-top: 1px solid #12161c !important; */
  }
  .assetsTable tbody tr{
    background: rgb(56 199 137 / 10%);
  }
  .assetsTable tbody{
    border:0;
  }
  .assetsTable tbody tr:nth-child(2n){
    background: rgb(56 199 137 / 20%);
  }
  .closedPandLChart img{
    display: block;
    margin: 0 auto;
  }
  .datePickerMain{
    position: relative;
  }
  .svg-line-chart-label{
    color: #ffffff;
  }
  .datepicker td, .datepicker th {
    color: #969696;
    font-size: 12px;
  }
  .darkBox.assetsTable{
    min-height: 600px;
  }
  .assetsTable .table{
    border-bottom: 1px solid #12161c;
  }
  .closedPandLDataStatus{
    margin: 50px 15px 15px;
  }
  .closedPandLDataStatus .assetTitle small{
    display: block;
  }
  .datePickerMain .input-group-append-icon {
      position: absolute;
      top: -2px;
      right: 35px;
      color: #374454;
  }
  .assetHistoryTableTop label {
      display: block;
      width: 100%;
      font-size: 14px;
  }
  .input-group.referralLinkBox .form-control {
      border-radius: 5px;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      height: 52px;
  }
  .input-group.referralLinkBox .input-group-append,.input-group.referralLinkBox .input-group-append .btn
  {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  }
  .input-group.referralLinkBox .input-group-append .btn:hover
  {
    background-color: transparent !important;
    border-color: #81c8f6 !important;
    color:#fff !important;
  }
  input.buttonType1{
    background: #5087c0;
    background-image: linear-gradient(180deg,#48a7e1 20%, #5087c0 50%);
    color: #fff !important;
    border-radius: 25px;
    padding: 7px 20px !important;
    border: 1px solid #5087c0;
    font-size: 13px !important;
    font-weight: 500 !important;
    transition: .2s all;
    box-shadow: 0 0 9px 1px #2765a7;
    text-transform: uppercase;
  }
  input.buttonType1:hover{
    background: transparent !important;
      border: 1px solid #5087c0 !important;
      color: #fff;
  }
  .assetTableTop {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 15px;
  }
  .assetTitle{
    margin: 0;
  }
  .assetTitle span{
    display: block;
    color: var(--themeclr);
    font-size: 20px;
    font-weight: 400;
  }
  .assetTitle{
    color: #fff !important;
    font-size: 24px !important;
    font-weight: 600;
    margin-bottom: 20px !important;
  }
  
  .assetTitle small{
    color: #ffffff;
    font-size: 18px;
  }
  .lighttheme .assetTitle small{
    color:#000
  }
  .assetTableRightLink li{
    display: inline-block;
    margin: 0 10px 0 0;
    padding-right: 10px;
    border-right: 2px solid #81c8f6;
  }
  .assetTableRightLink li:last-child{
    border-right: 0;
    padding-right: 0;
    margin-right: 0;
  }
  .assetTableRightLink li a{
    font-size: 16px;
    font-weight: 400;
    color: #121126;
  }
  .assetTableRightLink li a:hover{
    color: #81c8f6;
  }
  .niceStyleSelect.nice-select{
    height: 32px;
    line-height: 30px;
    background: transparent;
    border-radius: 0;
    border:0;
    color: #969696;
  }
  .niceStyleSelect.nice-select .list{
    border-radius: 0;
    background: #121a23;
  }
  .themeLight .niceStyleSelect.nice-select .list{
    background: #fafafa;
  }
  .niceStyleSelect.nice-select .option:hover, .niceStyleSelect.nice-select .option.focus, .niceStyleSelect.nice-select .option.selected.focus{
    background: #81c8f6 !important;
    color: #fff;
    font-weight: 400;
    font-size: 14px;
  }
  .themeLight .niceStyleSelect.nice-select .option:hover, .themeLight .niceStyleSelect.nice-select .option.focus, .themeLight .niceStyleSelect.nice-select .option.selected.focus{
    background: #81c8f6 !important;
  }
  .tradeLimitMarket .niceStyleSelect.nice-select{
    position: absolute;
    right: 15px;
    top: 9px;
    font-weight: 600;
  }
  .tradeLimitMarket .niceStyleSelect.nice-select::after {
    border-color: #81c8f6;
  }
  
  .tradeLimitMarket.assetsTable .niceStyleSelect.nice-select{
    position:static;
  }
  .tradeLimitMarket .slider.slider-horizontal{
    width: 100%;
  }
  .tradeLimitMarket #nav-tabContent-tradeLimitMarket{
    padding: 15px 15px 0;
  }
  .tradeLimitMarket .slider.slider-horizontal .slider-track{
    height: 3px;
  }
  .tradeLimitMarket .slider-track{
    background: #12161c;
  }
  .themeLight .tradeLimitMarket .slider-track{
    background: #b5b5b5;
  }
  
  .tradeLimitMarket .slider-selection{
    background: #81c8f6;
  }
  .tradeLimitMarket .slider-handle{
    width: 16px;
    height: 16px;
    background: #12161c;
    border:2px solid #81c8f6;
    top: -2px;
  }
  .themeLight .tradeLimitMarket .slider-handle{
    background: #ffffff;
  }
  .tradeLimitMarket .slider-handle::before{
      content: "";
      width: 6px;
      height: 6px;
      background: #81c8f6;
      border-radius: 50%;
      position: absolute;
      left: 3px;
      top: 3px;
  }
  #leverageCurrentSliderValLabel{
    color: #ffffff;
    font-size: 14px;
    margin: 0 0 10px 0;
  }
  #leverageSliderVal {
    color: #81c8f6;
    font-weight: 700;
  }
  #nav-tradeLimit .form-group label{
    font-size: 14px;
    color: #ffffff;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
  }
  .themeLight #nav-tradeLimit .form-group label{
    color: #969696;
  }
  #nav-tradeLimit .form-group label span{
    color: var(--themeclr);
  }
  .themeLight #nav-tradeLimit .form-group label span{
    color: #000;
  }
  #nav-tradeLimit ul.list2ColumnJustify li{
    padding-left: 0;
    padding-right: 0;
    font-size: 12px;
  }
  #nav-tradeLimit ul.list2ColumnJustify li span{
    color: var(--themeclr);
  }
  #nav-tradeLimit .form-group{
    margin-bottom: 10px;
  }
  .btn.btn-borderButton {
      color: #ffffff;
      border: 1px solid #12161c;
      text-transform: uppercase;
      transition: .5s;
      background: #005eb8;
      padding: 5px 7px;
      font-size: 11.5px;
      box-shadow: none !important;
      outline: none;
  }
  .mainFooter .inputgrp input {
    border-radius: 10px 0 0 10px;
}
  .themeLight .btn.btn-borderButton {
      color: #81c8f6;
      border: 1px solid #81c8f6;
      text-transform: uppercase;
      transition: .5s;
      background: #fff;
      padding: 5px 7px;
      font-size: 12px;
      box-shadow: none !important;
      outline: none;
  }
  .darkmodal .modal .btn.btn-borderButton {
    background: #000;
  }
  .modal .btn.btn-borderButton{
    border-left-width: 1px !important;
    border-radius: 5px;
    color:#000
  }
  /* .lightmodal .modal .btn.btn-borderButton{
    color:#fff;
    border-color: #fff !important;
  } */
  .btn.btn-borderButton:hover {
      color: #81c8f6;
      border: 1px solid #81c8f6;
  }
  .buySellBtn{
    display: inline-block;
    text-align: center;
    width: 100%
  }
  .buySellBtn{
    margin-top: 10px;
  }
  .btn-btnBuy{
    background:#02c076;
    /* background: #3dc90d; */
    border:1px solid #02c076;
    color: #fff;
    padding: 5px 25px;
    text-transform: uppercase;
    font-weight: 500;
    box-shadow: none !important;
    transition: .5s;
    font-size: 14px !important;
  }
  .btn-btnBuy:hover{
    background: transparent;
    color: #02c076;
    border:1px solid #02c076;
  }
  
  .btn-btnSell{
    background: #f84960;
    border:1px solid #f84960;
    color: #fff;
    padding: 5px 25px;
    text-transform: uppercase;
    font-weight: 500;
    box-shadow: none !important;
    font-size: 14px !important;
  
  }
  .btn-btnSell:hover{
    background: transparent;
    color: #f84960;
    border:1px solid #f84960;
  }
  
  .number-input input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }
  
  .number-input input[type=number]::-webkit-inner-spin-button,
  .number-input input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  
  .number-input {
  
  }
  
  .number-input button {
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0;
    position: relative;
  }
  
  .number-input button:before,
  .number-input button:after {
    display: inline-block;
    position: absolute;
    content: '';
    height: 2px;
    transform: translate(-50%, -50%);
  }
  
  .number-input button.plus:after {
    transform: translate(-50%, -50%) rotate(90deg);
  }
  
  .number-input input[type=number] {
    text-align: center;
  }
  
  .number-input.number-input {
    border: 1px solid #0a4c95;
    width: 100%;
    border-radius: 2px !important;
   /* background: #02071e; */
   color: #ffffff;
    position: relative;
  }
  .themeLight .number-input.number-input {
    border: 1px solid #c8c8c8;
    background: #fff;
  }
  .number-input.number-input button {
      padding: 0;
      width: 20px;
      position: absolute;
      height: 32px;
      outline: none;
  }
  .number-input.number-input button.minus {
      padding-left: 0px;
      right: 0px;
      color:#81c8f6;
  }
  .border_blue_select > div div:hover {
    background: #444444 !important;
}
.lighttheme .border_blue_select > div div:hover {
  background:#eaeaea !important;
}
/* .lighttheme .border_blue_select > div div:hover,.lighttheme .border_blue_select > div div:hover div {
  background: #eaeaea !important;
} */

  #quantity{
    width: calc(100% - 50px);
  }
  .number-input.number-input button:before,
  .number-input.number-input button:after {
    width: .6rem;
    background-color: #81c8f6;
  }
  .themeLight .number-input.number-input button:before,
  .themeLight .number-input.number-input button:after {
    background-color: #81c8f6;
  }
  .number-input.number-input input[type=number] {
    max-width: 100%;
    width: 85%;
    padding: .5rem;
    border: 0px solid #374454;
    border-width: 0 1px;
    font-size: 1rem;
    height: 2rem;
    color: #000 !important;
    background: #eaeaea;
    text-align: right;
    border-left: 0 !important;
    border-radius: 5px 0 0 5px;
  }
  .themeLight .number-input.number-input input[type=number] {
    color: #81c8f6;
    background: #ffffff;
    border-color: #c8c8c8;
  }
  .number-input.number-input button.plus{
    border-right: 1px solid #1b2c52;
    border-radius: 0;
    color: #81c8f6;
    right: 26px;
  }
  .themeLight .number-input.number-input button.plus{
    border-right: 1px solid #c8c8c8;
  }
  .contractDetailsBTCUSDT .customScroll {
      max-height: 380px;
      min-height: 380px;
  }
  .contractDetailsBTCUSDT ul.customScroll {
      max-height: 330px;
      min-height: 330px;
  }
  .contractDetailsBTCUSDT ul.customScroll li{
    color: #969696;
    margin-bottom: 7px;
    font-size: 13px;
    line-height: 18px;
  }
  .contractDetailsBTCUSDT ul.customScroll li span{
    color: #ffffff;
  }
  .themeLight .contractDetailsBTCUSDT ul.customScroll li span{
    color: #000000;
  }
  .contractDetailsBTCUSDT ul.customScroll li span.moderatorName{
    color: #FF0000;
  }
  .contractDetailsBTCUSDT ul.customScroll li span.nonmoderatorName{
    color: #81c8f6;
  }
  .trollBox{
    position: relative;
    min-height: 380px;
    margin: 10px 10px 0;
  }
  .trollBoxForm input{
    background: #121a23;
    border:1px solid #12161c;
    border-radius: 0;
    box-shadow: none !important;
  }
  .trollBoxForm input:focus{
    background: #121a23;
    border:1px solid #81c8f6;
  }
  .themeLight .trollBoxForm input{
    background: #ffffff;
    border:1px solid #c8c8c8;
    border-radius: 0;
    box-shadow: none !important;
  }
  .themeLight .trollBoxForm input:focus{
    background: #fff;
    border:1px solid #81c8f6;
  }
  .trollBoxForm input[type=submit]{
    background: transparent;
    box-shadow: none !important;
    border:1px solid #81c8f6;
    color: #81c8f6;
    margin-left: 5px;
    cursor: pointer;
    transition: .5s;
  }
  
  .trollBoxForm input[type=submit]:hover{
    border:1px solid #81c8f6;
    color: #81c8f6;
  }
  .bottomFixed {
      position: relative;
      bottom: 0px;
      left: 0;
      z-index: 1;
  }
  .dropdown-menu{
    background: #18294c;
    border:0;
  }
  .themeLight .dropdown-menu.sm-menu {
      background: #fafafa;
      border: 0;
  }
  .dropdown-item{
    color: #ffffff;
  }
  .themeLight .dropdown-item{
    color: #000;
  }
  .dropdown-item:focus, .dropdown-item:hover{
    background: #000;
    color: #fff;
  }
  
  .tooltip-inner {
      background-color: #12161c;
      text-align: left;
      font-size: 13px;
  }
  .tooltip.bs-tooltip-right .arrow:before {
      border-right-color: #12161c !important;
  }
  .tooltip.bs-tooltip-left .arrow:before {
      border-right-color: #12161c !important;
  }
  .tooltip.bs-tooltip-bottom .arrow:before {
      border-right-color: #12161c !important;
  }
  .tooltip.bs-tooltip-top .arrow:before {
      border-right-color: #12161c !important;
  }
  
  .themeLight .tooltip-inner {
      background-color: #c8c8c8;
      text-align: left;
      font-size: 13px;
      color: #3a3a3a;
  }
  .themeLight .tooltip.bs-tooltip-right .arrow:before {
      border-right-color: #c8c8c8 !important;
  }
  .themeLight .tooltip.bs-tooltip-left .arrow:before {
      border-right-color: #c8c8c8 !important;
  }
  .themeLight .tooltip.bs-tooltip-bottom .arrow:before {
      border-right-color: #c8c8c8 !important;
  }
  .themeLight .tooltip.bs-tooltip-top .arrow:before {
      border-right-color: #c8c8c8 !important;
  }
  
  .assetDownMain {
      /*width: 19px; height: 14px;*/
      display: block;
      /*margin-right: 15px;*/
      cursor: pointer;
  }
  .assetDown {
      width: 19px; height: 14px;
      background: url('../images/assetIcons.png') -10px -10px;
      cursor: pointer;
  }
  .assetDown:hover {
      background-position: -10px -44px;
  }
  
  .assetUpMain {
      /*width: 19px; height: 14px;*/
      display: block;
      cursor: pointer;
  }
  .assetUp {
      width: 19px; height: 14px;
      background: url('../images/assetIcons.png') -10px -78px;
      cursor: pointer;
  }
  .assetUp:hover {
      background-position: -10px -112px;
  }
  #assetsTable td small{
    display: block;
    font-size: 14px;
    color: #81c8f6;
  }
  #assetsTable.table thead{
    background: #000;
    border: 0;
    color:#fff;
  }
  .lighttheme #assetsTable.table thead, .lighttheme  #assetsTable.table tbody td{
    background: #fff;
    color: #000 !important;
    border-color: #e2e2e2 !important;
  }
  .lighttheme  #assetsTable.table tbody td button{
    color:#000
  }
  .lighttheme #assetsTable.table thead th{
    color: #000 !important
  }
  #assetsTable.table thead th{
    border-bottom: 1px solid #161c37 !important;
    color: #fff !important;
    background: none;
    font-size: 15px;
    font-weight: 600;
    white-space: nowrap;
  }
  #assetsTable.table tbody td
  {
    border-bottom: 1px solid #161c37 !important;
    /* color: #636fa0 !important; */
    font-weight: 400;
  }
  .assetsTable tbody tr
  {
    background: #02071d !important;
  }
  #assetsTable.table td, #assetsTable.table th{
    border: 0;
    color:#ffff;
  }
  #assetsTable.table td{
    font-size:14px;
    vertical-align: middle;
    white-space: nowrap;
  }
  .react-phone-number-input__row {
      display: flex;
      align-items: center;
      background: #fff;
      border-radius: 5px;
      padding-left: 15px;
  }
  .react-phone-number-input__input{
    margin-bottom: 0;
    padding: 9px 15px 9px 0 !important;
    box-shadow: none !important;
    background: #fff !important;
    color: #000000 !important;
    border-radius: 5px !important;
    border-color: #ffffff !important;
    height: auto !important;
  }
  .accordianComman .card-header .btn {
      padding: 0 20px 15px 20px;
      display: block;
      width: 100%;
      text-align: left;
      color: #81c8f6;
      font-weight: 600;
      font-size: 16px;
      text-decoration: none;
      border-bottom: 1px solid #12161c;
      border-radius: 0;
      box-shadow: none !important;
  }
  .accordianComman .card-header .btn.btn-link.collapsed{
    color: #81c8f6;
    text-decoration: none;
    padding-bottom: 0px;
    transition: .5s;
    font-weight: 600;
    font-size: 16px;
    border-bottom: 0;
  }
  .accordianComman .card-header .btn.btn-link.collapsed:hover{
    text-decoration: none;
  }
  .accordianComman .card-header .btn i {
      float: right;
      margin-right: 0px;
      margin-top: 0px;
      padding: 8px 0 0 0;
      color: #81c8f6;
      font-size: .85rem;
  }
  .accordianComman .card-header {
      padding: .55rem 0rem;
      margin-bottom: 0;
      background-color: transparent;
      border-bottom: 0;
  }
  .accordianComman .card{
    border: 1px solid #12161c !important;
    border-radius: 0px !important;
    background: #121126;
    margin-bottom: 5px;
    padding: 0 0px;
  }
  .accordianComman .card-body {
      padding: 15px;
      margin-bottom: 0px;
  }
  
  .accordianComman .card-body .btnDefault{
    padding: 9px 15px;
    color: #121126;
    background: #81c8f6;
    transition: .5s;
    box-shadow: none !important;
    border: 1px solid #81c8f6;
  }
  .accordianComman .card-body .btnDefault:hover{
    background: #81c8f6;
    color: #121126;
    border-color: #81c8f6;
  }
  .accordianComman .card-body .input-group-append .btnDefault:hover{
    background: #121126;
  }
  .accordianComman .card-header [data-toggle="collapse"] .fa-chevron-down:before {
    content: "\f068";
    color: #81c8f6;
  }
  
  .accordianComman .card-header [data-toggle="collapse"].collapsed .fa-chevron-down:before {
    content: "\f067";
    color: #81c8f6;
  }
  .accordianSubGroup{
    border-bottom: 1px solid #12161c;
    padding-bottom: 15px;
    margin-bottom: 20px;
  }
  .groupTitle {
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
  }
  .userMailId {
      font-size: 14px;
      color: #999999;
      margin: 0 10px;
      font-weight: 600;
  }
  .textVerifyGreen {
      color: #25b012;
      font-size: 14px;
  }
  .textUnVerifyRed {
      color: #f44336;
      font-size: 14px;
      margin-left: 10px;
  }
  .mobNumber .nice-select.niceStyleSelect {
      border-left: 1px solid #374454;
      border-top: 1px solid #374454;
      border-bottom: 1px solid #374454;
      height: 46px;
      line-height: 44px;
      border-radius: 5px 0 0 5px;
      background: #121a23;
      color: #fff;
  }
  .scanCode{
    margin-top: 5px;
    display: inline-block;
  }
  #myWalletAddress {
      font-size: 20px;
  }
  .accordianSubGroup:last-child{
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .settingsCheckList label{
    font-size: 14px;
    margin-bottom: 12px;
  }
  .settingsCheckList .listText{
    font-size: 14px;
    margin-left: 0;
    color: #c8c8c8
  }
  .settingsCheckList .checkbox .cr {
      position: relative;
      display: inline-block;
      border: 1px solid #81c8f6;
      background: #81c8f6;
      border-radius: 0em;
      width: 1.3em;
      height: 1.3em;
      float: left;
      margin-right: .5em;
      margin-top: 1px;
  }
  .form-group .inlineLabel {
      display: inline-block;
      width: auto;
  }
  .settingsCheckList  .checkbox .cr .cr-icon{
    top: 8px;
    left: 3px;
  }
  .settingsCheckList .checkbox .cr .cr-icon {
      color: #121126;
  }
  .tableHead h3 {
      color: #81c8f6;
      font-size: 16px;
      font-weight: 600;
      margin: 10px 15px;
  }
  .assetExchange {
      margin-top: 25px;
      margin-bottom: 50px;
  }
  .assetExchangeWalletBalance {
      background: transparent;
      text-align: center;
      border: 1px solid #374454;
      border-radius: 5px;
      padding: 15px;
  }
  .assetExchangeWalletBalance p {
      color: var(--themeclr);
  }
  .assetExchangeWalletBalance h3 {
    font-size: 32px;
    color: var(--themeclr);
    font-weight: 400;
  }
  .assetExchangeWalletBalance h3 small{
    color: #ffffff;
    font-size: 20px;
  }
  .assetExchangeWalletBalance h6 {
    color: #fff;
    font-weight: 600;
  }
  .lighttheme .assetExchangeWalletBalance h6 {
    color: #000;
  }
  .assetEchangeForm{
    margin: 0 0 0 15px;
    color: #fff;
  }
  .lighttheme .assetEchangeForm .form-group label {
     color: #000;
  }
  .lighttheme .assetEchangeForm{
    color: #000;
  }
  .assetEchangeForm label{
    width: 100%;
    display: block;
  }
  .assetsTable .assetEchangeForm .niceStyleSelect{
    width: 100%;
    margin-right: 0;
  }
  
  .changeSelect {
      background: var(--themeclr);
      max-width: 32px;
      max-height: 32px;
      width: 100%;
      border-radius: 50%;
      margin: 0 auto;
      cursor: pointer;
      transition: .5s;
  }
  .changeSelect:hover {
      background: #81c8f6;
  }
  .form-group.inputWithText {
      position: relative;
  }
  .input-group-append-icon {
      position: absolute;
      top: 42px;
      right: 15px;
      color: #bcbcbc;
  }
  .rightLink {
      font-size: 14px;
      text-decoration: underline;
  }
  .assetEchangeForm .assetTitle span {
    font-size:16px;
  }
  .assetEchangeForm .assetTitle span small{
    color: #969696;
    font-size:16px;
  }
  .supportTicket{
    position: relative;
    background-color: #000;
  }
  .lighttheme .supportTicket{
    background-color: #fff;
  }
  
  .security-setting-content-success .ivu-icon-alert-circle
  {
      font-size: 12px;
      color: #ffbf00;
  }
  .ivu-icon-alert-circle:before {
      content: "\E616";
  }
  .cmsTopImg {
      display: block;
      margin: 0 auto;
          margin-top: 0px;
  }
  .borderBottomRemove {
      border-bottom: 0;
  }
  .tableHead.tableHeadBlock p {
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      color: #ffffff;
  }
  .editor h4{
    color: var(--themeclr) !important;
    font-size: 18px;
  }
  .tableHead.tableHeadBlock{
    display: block;
  }
  .bonusBox{
    margin-bottom: 25px;
  }
  .tableHead h2{
    font-size: 25px;
    color: var(--themeclr);
    text-align: center;
    margin: 12px 15px 15px;
    font-weight: 600;
  }
  .darkBoxSpace{padding: 35px;}
  .formWhite .form-group label{
    display: block;
    width: 100%;
    font-size: 14px;
    color: #ffffff;
    margin-bottom: 10px;
  }
  .lighttheme .formWhite .form-group label{
    color: #000;
  }
  .formWhite .form-group label sup{
    color: var(--themeclr);
    font-size: 14px;
  }
  .formWhite .form-group .niceStyleSelect{
    width: 100%;
    background: #d7d7d7;
    border-radius: 5px;
    color: #000;
    font-size: 14px;
    height: 46px;
    line-height: 46px;
  }
  .formWhite .form-group input[type="text"], 
  .formWhite .form-group input[type="password"], 
  .formWhite .form-group input[type="email"], 
  .formWhite .form-group input[type="number"], 
  .formWhite .form-group textarea, 
  .formWhite .form-group textarea.form-control{
    background: #000;
    border-color:var(--themeclr);
    color: #000;
  }
  .lighttheme .formWhite .form-group input[type="text"], 
  .lighttheme .formWhite .form-group input[type="password"], 
  .lighttheme .formWhite .form-group input[type="email"], 
  .lighttheme .formWhite .form-group input[type="number"], 
  .lighttheme .formWhite .form-group textarea, 
  .lighttheme .formWhite .form-group textarea.form-control{
  background-color: #fff;
  }
  .formWhite .niceStyleSelect.nice-select .list {
      width: 100%;
      background: #d7d7d7;
  }
  
  /*Custom File Upload Starts*/
  #customFile .custom-file-control:lang(en)::after {
    content: "Max size 5MB in pdf/docx/jpg/png";
    /* content: "Max file size 5 MB in pdf / docx / jpg / png"; */
    color: #787878;
  }
  
  #customFile .custom-file-control:lang(en)::before {
      content: "Upload";
      position: absolute;
      right: -8px;
      font-weight: 700;
      background: var(--themeclr);
      padding: 15px 25px;
      cursor: pointer;
      color: #fff;
      top: -13px;
      border-radius: 0 5px 5px 0;
  }
  
  /*when a value is selected, this class removes the content */
  .custom-file-control.selected:lang(en)::after {
    content: "" !important;
  }
  .custom-file.form-control {
      background:  #fff;
      padding: 10px 15px;
      height: auto;
      border-color: var(--themeclr);
  }
  .darktheme .custom-file.form-control {
    background:  #000;
  }
  .custom-file {
    overflow: hidden;
  }
  .custom-file-control {
    white-space: nowrap;
  }
  
  .custom-file-control {
      white-space: nowrap;
      position: absolute;
      z-index: 9;
      text-align: left;
      top: 13px;
      box-shadow: none !important;
      width: 96%;
  }
  .custom-file-input{
      box-shadow: none !important;
      height: auto;
  }
  /*Custom File Upload Ends*/
  
  .supportTicket .buttonType1 {
      font-size: 16px;
      padding: 8px 100px;
      margin: 0 auto;
  }
  .noteText h6 {
      font-size: 16px;
      font-weight: 600;
      color: var(--themeclr);
  }
  .noteText p {
      font-size: 12px;
      color: #ffffff;
  }
  .lighttheme .noteText p{
    color: #000;
  }
  .dwCont{
    background: #121a23;
    border: 1px solid #81c8f6;
    border-radius: 0;
  }
  .dwCont button.close{
    color: #81c8f6;
    font-size: 30px;
    position: absolute;
    top: 3px;
    right: 15px;
  }
  .dwCont .modal-body{
    padding: 0;
  }
  .dwCont .popHead{
    background:#222e3d;
    position: relative;
    padding: 10px 0;
  }
  .dwCont .popHead h4{
    color: #ffffff;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }
  .dwCont .popUpSpace{
    padding: 20px;
  }
  .depositeQr img{
    display: block;
    margin: 0 auto;
  }
  .walletKey {
    margin: 35px 0 15px;
  }
  .walletKey input[type="text"] {
      background: #121a23;
      border: 1px solid #374454;
      color: #fff;
      font-size: 14px;
      text-align: left;
      padding: 10px 15px;
  }
  .walletKey .input-group-append-icon {
      top: 5px;
      right: 5px;
      background-color: transparent;
      border-radius: 0;
      border: 0;
      box-shadow: none !important;
      outline: none !important;
      color: #81c8f6;
      cursor: pointer;
      font-size: 20px;
      transition: .5s;
      height: 40px;
  }
  .walletKey .input-group-append-icon:hover
  {
    color:#fff;
  }
  .noteCont h6{
      color: #201e1e;
      font-size: 14px;
      font-weight: 600;
  }
  .noteCont p{
      font-size: 12px;
      color: #201e1e;
  }
  .withdrawPopupForm .btn.buttonType1 {
      padding: 8px 25px;
      margin: 0 auto;
  }
  .withdrawPopupForm .form-group label{
    font-size: 14px;
    display: inline-block;
    width: 100%;
  }
  .withdrawPopupForm .form-group label a{
    color: #81c8f6;
    text-decoration: underline;
    transition: .5s;
  }
  .withdrawPopupForm .form-group label a:hover{
    text-decoration: none;
    color: #81c8f6;
  }
  .withdrawPopupForm .form-group label span {
      color: #fff;
  }
  .withdrawPopupForm .form-group label span small {
      font-size: 14px;
      color:  #fff;
  }
  .referralSocial {
      display: inline-block;
      text-align: left;
      width: 100%;
      margin: 10px 0 25px;
  }
  .referralSocial h6{
    margin-bottom: 15px;
    font-size: 14px;
    color: #ffffff;
  }
  .referralSocial ul li{
    display: inline-block;
    margin: 0 7px;
  }
  .noBorder{
    border-radius: 0 !important;
    border:0 !important;
  }
  .noteText h3{
    font-size: 16px;
    font-weight: 600;
    color: #81c8f6;
    display: inline-block;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 15px;
  }
  .fixed-top{
    /* z-index: 99999; */
  }
  /* .tradeMain .row{
    max-width: 100%;
    width: 100%;
  } */
  
  @media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color:transparent) {
  
      .number-input.def-number-input.safari_only button:before,
      .number-input.def-number-input.safari_only button:after {
        margin-top: -.3rem;
      }
    }
  }
  @media screen and (max-width: 1768px) {
    .number-input.number-input input[type="number"]{
      width: calc(100% - 40px);
    }
    .number-input.number-input button.minus{
  
    }
    .contractDetailsBTCUSDT .customScroll {
      max-height: 388px;
  }
  }
  
  @media screen and (max-width: 1199px) {
    .tradeMain {
    /* padding-top: 120px; */
  }
  .customNavTrade{
      display: block;
  }
  .header-overview {
      display: inline-flex;
      justify-content:space-between;
      border-left: 0px solid #242d39;
      border-right: 0px solid #242d39;
      width: 100%;
  }
  .headerOverviewGroup{
    border-left: 0px;
    border-right: 0px;
    margin-top: 15px;
  }
  #navbarResponsiveUser {
      position: absolute;
      right: 15px;
      top: 15px;
  }
  }
  @media screen and (max-width: 991px) {
    .customNavTrade .navbar-toggler {
      color: #81c8f6;
      outline: none;
      padding: 0;
      font-size: 24px;
      transition: .5s;
  
  }
    .mobilespace {
      margin-right: auto !important;
      padding:15px 0;
  }
  .lang div a.nav-link {
    margin-left: 15px !important;
  }
  .navbar .navbar-nav{
    display: block;
  }
    .statcarousel .owl-stage-outer .owl-item {
      padding: 0 21px !important
    }
    .menu_right li.nav-item:last-child
    {
      margin-bottom: 0px !important;
    }
    .assetsTable .nav-tabs .nav-link {
      font-size: 15px;
      padding: 4px 8px 6px;
  }
  .tradeLimitMarket.assetsTable .nav-tabs .nav-link{
    font-size: 15px;
      padding: 4px 8px 6px;
  }
    .tradeMain{
      /* padding-top: 120px; */
    }
    .assetExchangeWalletBalance{
      margin-right: 15px;
    }
    .tableHead.tableHeadBlock p{
      padding: 0 15px;
    }
    .orderBook .tradeTableBodyRow .pinkText {
        padding-left: 18px !important;
    }
    .recentTrades .tradeTableBodyRow .pinkText {
        padding-left: 10px !important;
    }
    .recentTrades .tradeTableBodyRow .greenText {
        padding-left: 10px !important;
    }
    #navbarResponsive {
      position: fixed;
      top: 60px;
      right: auto;
      right: 0px;
      width: 100%;
      background: #18294c;
      padding: 20px;
      z-index: 100;
    }
    .mobileNavAction{
      position: absolute;
      top: 20px;
      right: 15px;
   }
   .mobileNavAction .navbar-nav{
     display: flex;
     flex-direction: row;
     justify-content: space-around;
     align-items: center;
   }
   .mobileNavAction .navbar-nav .nav-item .nav-link.customNavbarToggler{
       color: #81c8f6;
       outline: none;
       padding-left: 20px;
       padding-right: 0px;
       padding-top: .35rem;
       padding-bottom: .35rem;
       font-size: 24px;
       transition: .5s;
       cursor: pointer;
       outline: 0 !important;
   }
   .mobileNavAction .navbar-nav .nav-item .nav-link.customNavbarToggler:hover {
       color: #81c8f6;
   }
   button.btn.auth_btn {
     margin-left: 10px;
   }
   .navbar-nav > li > .dropdown-menu{
     display: none;
     transition: none;
     transform: none;
     border: 0;
   }
   .navbar-nav > li > .dropdown-menu.show {
     display: block;
     padding: 15px;
   }
   #navbarResponsive .dropdown-toggle::after {
     right: 30px;
   }
  }
  @media screen and (max-width: 767px) {
    .form-group .mobileFullWidth {
      width: 100%;
      margin-bottom: 15px;
  }
  
  .tradeMain {
      padding-top: 69px;
  }
  .tradeFooter{
    display: inline-block;
    text-align: center;
    width: 100%;
  }
  .tradeFooterLinks{
    margin-bottom: 15px;
  }
  .assetEchangeForm{
    margin-right: 15px;
  }
  .assetExchangeWalletBalance{
    margin-left: 15px;
    margin-top: 25px;
  }
  .assetsTable .nav-tabs .nav-link {
    margin-bottom: 15px;
  }
  .headerOverviewStatus h5{
    font-size: 14px;
  }
  ul.assetTableRightLink{
    text-align: right;
  }
  .assetTableRightLink li{
    border-right: 0;
    margin-right: 0;
    padding-right: 0;
  }
  #customFile .custom-file-control:lang(en)::before {
    right: -12px;
  }
  .trollBox{
    margin-right: 0;
    margin-left: 0;
  }
  .trollBox .messages > div{
    max-width: 100%;
  }
  #navbarResponsiveUser{
    position: static;
  }
  .tradeMobile {
      display: flex;
      justify-content: space-between;
  }
  
  .headerOverviewGroup {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding: 0;
  }
  .headerOverviewStatus {
      margin: 0 12px 20px;
      width: 20%;
  }
  .spotTradingBox .border-right-one{
    border-right:0 !important;
    border-bottom: 1px solid #12161c;
    margin-bottom: 15px;
    padding-bottom: 10px;
  }
  .customNavTrade .header-overview{
    display: block;
    padding: 26px 17px;
  }
  .customNavTrade .nav-item .nav-link{
    padding-left: 0;
    padding-right: 0;
  }
  .customNavTrade .nav-item .nav-link:hover{
    color: #81c8f6;
  }
  .customNavTrade .dropdown-menu .nav-link{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  
  .rowCustom{
    flex-direction: column;
  }
  .fixedWidth{
    max-width: 100%;
  }
  .liquidWidth{
    width: 100%;
  }
  }
  @media screen and (max-width: 575px) {
    .darkBoxSpace {
      padding: 12px;
    }
    .tradewithexchange .d-flex img {
      width: 130px;
    }
    .owl-prev {
      left: -28px !important;
    }
    .owl-next {
      right: -28px !important;
    }
    .statcarousel .owl-stage-outer .owl-item{
      border: none !important;
    }
  .statcarousel .owl-stage-outer .owl-item {
    padding: 0 19px !important;
  }
  .statcarousel .owl-nav {
    /* position: unset !important; */
  }
  .tradeLimitMarket .css-1579d96-option, .css-nbylj9-option{
    font-size: 12px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .tradeLimitMarket .css-nbylj9-option, .css-h4cczp-option{
    font-size: 12px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .rightSelect {
    width: 130px;
  }
    .scanCode{
    margin-top: 5px;
    display: block;
      margin: 0 auto;
      text-align: center;
  }
  
  .header-overview{
    display: block;
  }
  
  .headerOverviewGroup {
      display: inline-block;
      width: 100%;
      text-align: left;
  }
  .headerOverviewStatus {
      margin: 0;
      margin-bottom: 0px;
      width: 26%;
      display: inline-block;
      text-align: center;
      margin-bottom: 20px;
      vertical-align: top;
  }
  .headerOverviewStatus h5{
    font-size: 14px;
  }
  .selectCoinType{
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .tradeMain{
    padding-top: 62px;
  }
  .customNavTrade .mobileNavAction .nav-item .nav-link{
    padding-left: 10px;
    padding-right: 10px;
  }
  .mobileNavAction .navbar-nav .nav-item .nav-link.customNavbarToggler{
    padding-left: 10px;
  }
  .supportTicket .buttonType1{
    padding-left: 25px;
    padding-right: 25px;
  }
  .selectCoinType .dropdown-menu{
    width: 300px;
  }
  .tradeFooter li {
      display: inline-block;
      margin: 0 7px 7px;
  }
  .assetTitle span{
    font-size: 18px;
  }
  .assetTitle{
    font-size: 18px !important;
  }
  .btnDefaultNew.btn_sm
  {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .assetTableRightLink li a{
    font-size: 14px;
  }
  
  }
  
  
  .form-control:disabled, .form-control[readonly] {
    background-color: #03081f !important;
      border: 1px solid #81c8f6 !important;
      color: #464444 !important;
      padding: 25px 15px;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin: 0px;
      width: 100%;
      transition: all 0.5s ease-in-out;
      border-radius: 5px;
      resize: none;
      box-shadow: none !important;
  }
  .modal
  {
    z-index: 99999;
  }
  .main_bg_trade_new
  {
     background-color: #000 !important;
  }
  .main_bg_trade_new .tradeMain .darkBox
  {
  background-color: #05163c !important;
  background: #000 !important;
  
  border-radius: 0px !important;
  box-shadow: 0 0 0px 0px #dbe2e92e !important;
  }
  .main_bg_trade_new.spot_trade_main .tradeMain .darkBox
  {
    /* margin: 2px !important; */
  
  }
  
  @media only screen and (min-width:768px)
  {
    .mb-new-trade-sm,.mb-trade-new-sm
    {
      margin-bottom: 0.1rem !important;
  
    }
    .mr-right-new-trade,.mr-right-trade-new
    {
      margin-right: 0.15rem !important;
    }
    .mt-top-trade-new
    {
      margin-top: 0.1rem !important;
  
    }
    .mb-bot-new-sm
    {
      margin-bottom: 0.15rem !important;
    }
    .pr-md-new-sm
    {
      padding-right: 0.15rem !important;
  
    }
    .mt-sm-new
    {
      margin-top: 0.15rem !important;
    }
  }
  .special.btn-group .btn
  {
    border-radius: 0px !important;
    background: #0f1f44 !important;
  }
  .main_bg_trade_new .tradeMain .table th
  {
  background: #1b2c52 !important;
  }
  
  .main_bg_trade_new .spotForm .input-group-text
  {
  border-radius: 0px 2px 2px 0px !important;
  }
  
  .main_bg_trade_new .vertical-tab .nav-tabs li a
  {
    font-size: 13px !important;
  }
  
  @media only screen and (max-width:767px){
    .tradebg img {
      display: none;
    }
    .fixedWidth
    {
     width: 100% !important;
    }
    .row_margin_mob
    {
      margin-left: 10px !important;
      margin-right: 10px !important;
    }
    .row_mob_upper
    {
      margin-left: -15px !important;
      margin-right: -15px !important;
    }
    .mb-bot-new-sm
    {
      margin-right: 2px !important;
    }
    .px-mob-width,.table_width_mob
    {
      padding-left: 15px !important;
      padding-right: 0px !important;
      margin-left: -15px !important;
      margin-right: -15px !important;
      width: calc(100% + 23px) !important;
    }
  }
  /* .liquidWidth 
  {
    margin-top: 0.15rem !important;
  } */
  
  @media only screen and (min-width:768px) and (max-width:991px)
  {
    .fixedWidth,.liquidWidth
    {
     width: 100% !important;
    }
    .rowCustom {
      flex-direction: column;
  }
  }
  .number-input.number-input button.contnet_none::before,
  .number-input.number-input button.contnet_none::after
  {
  content: none !important;
  }
  .statcarousel{
    position: relative;
    color: #fff;
    padding: 40px 0;
  }
  .statcarousel .owl-nav {
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .statcarousel .owl-nav button {
    height: 25px;
    width: 25px;
  }
  .statcarousel .owl-stage-outer .owl-item {
    padding: 0 70px;
    border-right: 1px solid #070d12;
  }
  .darktheme .slick-dots li.slick-active button:before{
    color: #fff;
  }
  .darktheme .slick-dots li button:before{
    color:lightgray
  }
  .lighttheme .wallet-tab .assetsTable .nav_border_new a{
    color: #000 !important;
  }
  .darktheme .innerLogo .logoDark {
    display: none;
  }
  .lighttheme .innerLogo .logoLight {
    display: none;
  }
  button.owl-next {
    position: relative;
    right: -36px;
  }
  .owl-prev {
    position: relative;
    left: -36px;
  }
  .owl-stage-outer{
    z-index: 999;
  }
  .loginsec .input-group-text{
    border-radius: 0 10px 10px 0;
    background-color: #000;
  }
  .loginsec .input_grp_pwd{
    flex-wrap: unset;
  }
  .custom-file-label{
    display: flex;
  }
 .lighttheme  .loginsec .form-group label,
 .lighttheme .loginsec .formBox h2,
 .darktheme  .loginsec .formBox h2{
    color:#fff 
  }
  .lightmodal .primary-modal .modal-content,
  .lightmodal .modal .walletKey .address{
    background-color: #fff;
}
.lightmodal .modal .noteCont p , .lightmodal .modal .noteCont h3 ,
.lightmodal  .primary-modal .modal-title,.lightmodal .modal .modal-header .close,
.lightmodal .modal li span{
  color: #000 !important;
}
body.modal-open {
  padding-right: 0 !important;
}
.proofThumb {
  max-width: 150px;
}