body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.spot .nav-tabs .nav-link{
	margin-bottom: 0;
  }

span.Ear1n {
    /* right: 101px!important; */
    /* left: 41px!important; */
    /* margin-right: 125px!important; */
    margin: 0 0 0 130px;
}
/*.rc-slider-rail::before{
	content: "";
	position: absolute;
	width: 38.77%;
	height: 4px;
	background: #70a800;
	top:5px;
	left: 0;
	z-index: 1;
}

.rc-slider-rail::after{
	content: "";
	position: absolute;
	width: calc(100% - 38.77%);
	height: 4px;
	background: #ea0070;
	top:5px;
	right: 0;
	z-index: 1;
}*/

.Toastify__toast-container--top-center {
	margin-top: 40px;
}

.form-control-qty,.number-input.number-input input[type=number].form-control-qty
{
	max-width: 110px !important; 
    margin-left: 5px !important;
    border: 1px solid #81c8f6 !important;
    margin-left: auto;
    border-radius: 5px !important;
	border-left: 1px solid #81c8f6 !important;

}
.input_kax_al
{
	text-align: right;
}