
 .responsive-table li {
	 padding: 25px 30px;
	 display: flex;
	 justify-content: space-between;
}
 .responsive-table .table-header {
 	background: transparent;
	 border-bottom: 0;
	color: #939393;
	font-size: 16px;
	padding: 15px 30px 5px 30px;
	border-top:0;
}
 .responsive-table .table-row {
 	background: transparent;
	border-top: 0;
	font-size: 16px;
	color:#000000;
    padding: 15px 30px;
    transition: .5s;
}
 .responsive-table .col-1 {
	 flex-basis: 16%;
}
 .responsive-table .col-2 {
	 flex-basis: 18%;
}
 .responsive-table .col-3 {
	 flex-basis: 25%;
}
 .responsive-table .col-4 {
	 flex-basis: 16%;
}
 .responsive-table .col-5 {
	 flex-basis: 25%;
}
 @media all and (max-width: 767px) {
	 .responsive-table .table-header {
		 display: none;
	}
	 .responsive-table li {
		 display: block;
	}
	 .responsive-table .col {
		 flex-basis: 100%;
	}
	 .responsive-table .col {
		 display: flex;
		 padding: 10px 0;
	}
	 .responsive-table .col:before {
		 color: #6c7a89;
		 padding-right: 10px;
		 content: attr(data-label);
		 flex-basis: 50%;
		 text-align: right;
	}
}
 