/* @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
:root{
  --themeclr: #005eb8
}
.react-notification-root {
  z-index: 9999999 !important;
}

.disabled {
  background-color: #fff !important;
  opacity: 0.7 !important;
}
.f-12{
  font-size: 12px;
}


.lighttheme .markall{
  color: black;
}
.lighttheme .markall:hover {
  color: black;
}
.markall{
  color: white;
  font-size: 13px;
  cursor: pointer;
}
.markall:hover{
  color: #3a9fff !important;
  transition: 0.5s;
}
.allnotip .all_noti_link_green { 
  color: #3a9fff !important;
}
.lighttheme .allnotip .all_noti_link_green:hover {
  color: black !important;
}
.allnotip .all_noti_link_green:hover { 
  color: #ffff !important;
}
.notifi {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 15px;
  list-style: none;
  border: 0;
  height: 200px;
  overflow-y: auto;
  scrollbar-color: #21b77a #1a1b1c;
  scrollbar-width: thin;
}
.notifi li{
  border-bottom: 1px solid #4da3f5;
  padding: 8px 0px;
}
.lighttheme .notifi p{
  color:black;
}
.notifi p{
  font-size: 12px;
  color: white;
}
.lighttheme .notifi h5{
  color:black;
}
.notifi h5 {
  font-size: 14px;
  color: white;
}
.chunks{
  position: relative;
}
.notify_count{
   background: #005eb8;
    color: #fff;
    width: 17px;
    height: 17px;
    display: inline-block;
    border-radius: 17px;
    line-height: 13px;
    text-align: center;
    font-size: 10px;
    position: absolute;
    right: 15px;
    margin-top: 0px;
    border: 2px solid #fff;
    z-index: 9;
}
.chunks .dropdown-toggle::after {
 display: none;
}
.lighttheme .chunks .dropdown-menu{
  background: #fcfcfc;
  box-shadow: 0 0 5px #6cb7ff;
  border-radius: 4px;
}
.chunks .dropdown-menu{
  min-width: 20rem !important;
  padding: 10px 10px !important;
}

.lighttheme .notification-card .card {
  background: #ffff;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;
  border: 1px solid #8080809e !important;
}
.notification-card .card {
  background: #1a1b1c;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;
  
}
.notification-card h2{
  color: #005eb8;
  font-weight: 600;
}
.lighttheme .notification-card li {
  background: #eaf5ff;
  border-color: grey;
  border-left: 5px solid #005eb8ad;
  padding: 6px 5px 0;
}

.lighttheme .notification-card .chatread{
  background: #9e9e9e26;
}
.notification-card li{
  background: #212121;
  border-color: grey;
  /* border-left: 5px solid grey; */
  border-left: 5px solid #005eb8;
  padding: 6px 5px 0;
}

.notification-card .chatread{
  border-left: 5px solid grey;
}
.lighttheme .notification-card .chatread{
  border-left: 5px solid grey;
}
/* .notification-card li.read svg {
  fill: #999;
} */
.lighttheme .notification-card .chatread svg {
  fill: #999;
}
.lighttheme .notification-card li svg {
  fill: #2196F3;
}
.notification-card .chatread svg {
  fill: #999;
}
.notification-card li svg {
  width: 13px;
  margin-right: 3px;
  /* fill: #999; */
  fill: #005eb8;
}

.lighttheme .notification-card .lighttxt {
  color: black;
}
.notification-card .lighttxt{
  /* color: #848484; */
  color: #fff;
  font-size: 12px;
}
.notification-card .chatread .lighttxt{
  color: #848484;
  font-size: 12px;
}

.lighttheme .text-muted {
  color: black!important;
  font-size: 12px;
}
.text-muted {
  /* color: #6c757d!important; */
  color: #fff!important;
  font-size: 12px;
}
.chatread .text-muted{
  color: #6c757d!important;
}
.lighttheme .notification-card .btn.btn-link {
  color: #2196F3 !important;
}
.lighttheme .notification-card .btn.btn-link:hover {
  color: black !important;
  transition: 0.5s;
}
.notification-card .btn.btn-link {
  background-color: initial !important;
  /* color: #999 !important; */
  color: #3d92e3 !important;
  font-size: 12px !important;
  text-decoration: none !important;
}
.notification-card .btn.btn-link:hover {
  color: #fff !important;
  transition: 0.5s;
}
.notification-card .chatread .btn.btn-link {
  color: #999 !important;
}

.noti-icon{
  font-size: 22px;
}
.done li:nth-child(odd) {
  background: #212121 !important;
}
.done li.read {
  border-color: grey !important;
  border-left: 2px solid red;
}



.no-hover {
  background: #5087c0;
  background-image: linear-gradient(180deg,#48a7e1 20%, #5087c0 50%);
  color: #fff;
  border-radius: 25px !important;
  padding: 7px 20px !important;
  border: 1px solid #5087c0;
  font-size: 13px !important;
  font-weight: 500 !important;
  transition: .2s all;
  box-shadow: 0 0 9px 1px #2765a7;
  text-transform: uppercase;
}
.no-hover:hover {
  background: transparent;
  border: 1px solid #5087c0;
  color: #fff;
}

.normalButton {
  padding: 8px 30px;
  color: #121126;
  background-color: #81c8f6;
}

.normalButton:hover {
  background-color: #ffc949;
}

.list li {
  list-style: disc inside none;
}


.kyc-header {
  max-width: 600px;
  margin: auto;
  background: #02071d;
  border-radius: 15px;
  box-shadow: 0 0 20px 1px #dbe2e92e;
  position: relative;
  padding: 20px;
}
.kyc-header h2 {
  color: #fff;
    font-weight: 600;
    text-align: center;
    font-size: 25px !important;
}
.kyc-header p  {
    margin: 0;
    font-size: 0.9rem;
    font-weight: bold;
    color: rgb(109, 227, 146);
}

.proofContentText p {
  margin: 0;
  font-weight: 400;
}




body {
  background-color: #1b2c52;
  font-size: 16px;
  font-family: 'poppins' !important;
  overflow-x: hidden;
}
body.lightmodal {
  background-color: #fff !important;
}
#root.lighttheme{
  background-color: #fff !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.contentPage ol {
  margin: 0;
  padding: 0;
}

.contentPage li {
  list-style: none;
  margin-bottom: 10px;
}

.blogthumbimage {
  max-height: 120px;
  min-height: 120px;
}

.footer_top_left p a {
  color: #212529;
}

.footer_top_left p a:hover {
  text-decoration: underline;
}

input[type=number] {
  -moz-appearance: textfield;
}

a {
  color: #121126;
  text-decoration: none;
  transition: .5s;
}

a:hover {
  color: #81c8f6;
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
}

/* .navbar {
  background-color: #ebeff4;
} */

/* .privacy-policy-container {
  padding: 2rem;
  background-color: #fff;
} */

.privacy-policy-text {
  margin: auto;
  max-width: 900px;
  white-space: pre-wrap;
}

.react-multi-carousel-item {
  display: flex;
  padding: 0 15px;
}

.breadCrumb {
  display: inline-block;
  text-align: center;
  width: 100%;
  margin-bottom: 30px;
}

.breadCrumb ul {
  margin: 0;
  padding: 0;
}

.breadCrumb ul li {
  list-style: none;
  display: inline-block;
  margin: 0 5px;
  font-size: .85rem;
  color: #b0c0d2;
}

.breadCrumb ul li a {
  color: #000;
  text-decoration: none;
  transition: .5s;
}

.breadCrumb ul li a:hover {
  color: #81c8f6;
}

.fullBlogPost {
  margin-bottom: 40px;
}

.fullBlogPost h2 {
  text-align: center;
  font-size: 2rem;
  color: #000;
  margin-bottom: 14px;
}

.fullBlogPost span.dateText {
  text-align: center;
  color: #757575;
  font-size: .8rem;
  display: block;
  margin-bottom: 25px;
  position: relative;
  z-index: 9;
}

.img-fluid.blogFullImg {
  margin-bottom: 30px;
}

.fullBlogPost p {
  color: #505050;
}

.fullBlogPost h4 {
  margin: 25px 0 15px;
}

.relatedSlider {
  background: #f0f0f0;
  position: relative;
  padding-bottom: 30px;
}

.relatedSlider .slideItem h4 {
  padding: 15px 15px;
  text-align: center;
}

.relatedSlider .slideItem h4 a {
  font-size: 18px;
  color: #000;
  text-decoration: none;
  transition: .5s;
}

.relatedSlider .slideItem h4 a:hover {
  color: #81c8f6;
}

.relatedSlider .slideItem {
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 7px;
}

.relatedSlider .slideItem img {
  border-radius: 8px 8px 0 0;
  min-width: 100%;
}

.relatedSlider .slideItem p {
  text-align: left;
  font-size: .75rem;
  color: #757575;
  padding: 0 15px;
}

.relatedSlider::before {
  content: '';
  width: 100%;
  position: absolute;
  height: 150px;
  background-color: #e5e5e5;
  left: 0;
  top: 50%;
  margin-top: -90px;
}

.CalculaterPart .input-group-append-icon {
  position: absolute;
  top: 38px;
  right: 15px;
  color: #bcbcbc;
}

.inputcenter {
  width: auto !important;
  display: inline-block !important;
}

.calcResultBox {
  background-color: #121126;
  border: 1px solid #959cab;
  padding: 20px 20px 0;
  height: 100%;
}

.calcResultList li {
  display: flex;
  justify-content: space-between;
}

.calcResultList li p {
  font-size: 16px;
  font-weight: 500;
}

.calcResultList li p span {
  color: #81c8f6;
}

.rc-slider-rail {
  background-color: #005eb8 !important;
}

.rc-slider-dot {
  background-color: #314779 !important;
  border-color: #81c8f6 !important;
}

.rc-slider-dot-active {
  background-color: #314779 !important;
  border-color: #121126 !important;
}

.rc-slider-handle {
  background-color: #81c8f6 !important;
  border-color: #81c8f6 !important;
}

.notification-container-bottom-center, .notification-container-bottom-left, .notification-container-bottom-right, .notification-container-mobile-bottom, .notification-container-mobile-top, .notification-container-top-center, .notification-container-top-left, .notification-container-top-right {
  position: fixed;
  z-index: 9999999;
}

.checkbox label:after,
.radio label:after {
  content: '';
  display: table;
  clear: both;
}
.darktheme .crcheckbox {
  color: white;
}
.lighttheme .crcheckbox {
  color: #000;
}
.lighttheme .checkbox .cr, .lighttheme .radio .cr{
  background-color: #fff !important;
}
.checkbox .cr{
  border-radius: 3px !important;
  margin-top: 0 !important;
}
.landingForm .checkbox .cr,
.landingForm .radio .cr {
  position: relative;
  display: inline-block;
  border: 1px solid #81c8f6;
  background: #81c8f6;
  border-radius: 0em;
  width: 1.3em;
  height: 1.3em;
  float: left;
  margin-right: .5em;
  margin-top: 3px;
}

.react-phone-number-input__icon {
  width: 1.24em;
  height: auto !important;
  border: 0 !important;
  box-sizing: content-box;
}

.react-phone-number-input {
  width: 100% !important;
}

.landingForm .react-phone-number-input {
  width: 100% !important;
  border: 1px solid #d7d7d7;
  padding-left: 10px;
  background: #d7d7d7;
  border-radius: 5px;
}

.landingForm .react-phone-number-input input {
  border-radius: 0 5px 5px 0 !important;
  border: 0 !important;
  background: #d7d7d7 !important;
}

.landingForm .react-phone-number-input__country-select-arrow {
  color: #000000;
  margin-left: 15px !important;
}

.radio .cr {
  border-radius: 50%;
}

.landingForm .checkbox .cr .cr-icon,
.landingForm .radio .cr .cr-icon {
  position: absolute;
  font-size: .7em;
  line-height: 0;
  top: 50%;
  left: 20%;
  color: #444946;
}

.landingForm .radio .cr .cr-icon {
  /*margin-left: 0.04em;*/
}

.landingForm .checkbox label input[type="checkbox"],
.landingForm .radio label input[type="radio"] {
  display: none;
}

.landingForm .checkbox label input[type="checkbox"]+.cr>.cr-icon,
.landingForm .radio label input[type="radio"]+.cr>.cr-icon {
  transform: scale(3) rotateZ(-20deg);
  opacity: 0;
  transition: all .3s ease-in;
}

.landingForm .checkbox label input[type="checkbox"]:checked+.cr>.cr-icon,
.landingForm .radio label input[type="radio"]:checked+.cr>.cr-icon {
  transform: scale(1) rotateZ(0deg);
  opacity: 1;
}

.landingForm .checkbox label input[type="checkbox"]:disabled+.cr,
.landingForm .radio label input[type="radio"]:disabled+.cr {
  opacity: .5;
}

.landingForm input[type=text], .landingForm input[type=tel], .landingForm input[type=password], .landingForm input[type=email], .landingForm input[type=number], .landingForm textarea, .landingForm textarea.form-control {
  background-color: #03081f;
  border: 1px solid #81c8f6;
  color: #fff !important;
  padding: 25px 15px;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 0px;
  width: 100%;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  resize: none;
  box-shadow: none !important;
}

.landingForm input[type=text]:focus, .landingForm input[type=tel]:focus, .landingForm input[type=password]:focus, .landingForm input[type=email]:focus, .landingForm input[type=number]:focus, .landingForm input[type=search]:focus, .landingForm textarea:focus, .landingForm textarea.form-control:focus {
  background-color: #03081f;
  border: 1px solid #81c8f6;
}

.dropdown-item {
  color: #121126 !important;
}

.dropdown-item:focus, .dropdown-item:hover {
  background: #05163c !important;
  color: #ffffff !important;
}

.landingForm input:placeholder {
  color: #cccccc;
}

.landingForm input[type=button], .landingForm input[type=submit], .landingForm input[type=reset], .landingForm input[type=file] {
  background: #5087c0;
    background-image: linear-gradient(180deg,#48a7e1 20%, #5087c0 50%);
    color: #fff;
    border-radius: 25px;
    padding: 7px 20px;
    border: 1px solid #5087c0;
    font-size: 13px;
    font-weight: 500;
    transition: .2s all;
    box-shadow: 0 0 9px 1px #2765a7;
    margin-left: auto;
    margin-right: auto;
    transition: all 0.3s ease-in-out;
    width: auto;
    cursor: pointer;
    display: block;
    outline: none;
    -webkit-box-shadow: 0 0 9px 1px #2765a7 !important;
  -moz-box-shadow: 0 0 9px 1px #2765a7 !important;
  box-shadow: 0 0 9px 1px #2765a7 !important;
}

.landingForm input[type=button]:hover, .landingForm input[type=submit]:hover, .landingForm input[type=reset]:hover {
  background: transparent;
  color: #81c8f6;
  border-color: #81c8f6;
}

.landingForm input[type=button]:active, .landingForm input[type=submit]:active, .landingForm input[type=reset]:active {
  -moz-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -o-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

.formBox {
  background: var(--themeclr);
  padding: 40px;
  border-radius: 15px;
  -webkit-box-shadow: 0 0 20px 1px #dbe2e92e;
  -moz-box-shadow:0 0 20px 1px #dbe2e92e;
  box-shadow: 0 0 20px 1px #dbe2e92e;
}
.formBox.contact {
  background: #191919  !important;
  -webkit-box-shadow: 0 0 20px 1px #dbe2e92e;
  -moz-box-shadow:0 0 20px 1px #dbe2e92e;
  box-shadow: 0 0 20px 1px #dbe2e92e;
}
.formBox.contact textarea{
  height: 100% !important;
}
.lighttheme .formBox.contact h2{
  color:  #000;
}
.orderlimit .number-input.number-input {
  border: 0;
}
.orderlimit .special.btn-group .btn , .orderlimit .number-input.number-input input[type=number]{
  color:#fff !important
}
.lightmodal .orderlimit .special.btn-group .btn ,.lightmodal .orderlimit .number-input.number-input input[type=number]{
  color:#000 !important
}
.closeby .number-input.number-input button.plus {
  right: 20px;
}
.closeby .number-input input {
  background: #000 !important;
}
.closeby .input-group button{
  border: 1px solid var(--themeclr) !important;
  color: #fff !important;
}
.lighttheme .settingsec select.form-select option {
  background: #ededed;
  color: #000;
}
.lightmodal .closeby .number-input.number-input input[type="number"] {
  background-color: #fff !important;
}
.darkmodal .closeby .number-input.number-input input[type="number"] {
  background-color: #000 !important;
  color: #fff !important;
}
.lightmodal .modal .btn.btn-borderButton {
  color: #005eb8 !important;
  border-color: #005eb8 !important;
  border-radius: 0px;
}
.lighttheme .settingsec h5.text-light {
  color: #000 !important;
}
.lighttheme .formBox.contact {
  -webkit-box-shadow:0 0 20px 1px #292a2a2e;
  -moz-box-shadow:0 0 20px 1px #292a2a2e;
  box-shadow:0 0 20px 1px #292a2a2e;
  background: #fff !important; 
}
.formBox h2 {
  color: #81c8f6;
  font-weight: 600;
  text-align: center;
  font-size: 25px !important;
}
.formBox a {
  color: #81c8f6;
  font-size: 14px;
  transition: .5s;
}

.formBox a:hover {
  color: #fff !important;
}

.formBox a.regTerms {
  color: #81c8f6;
}

.formBox a.regTerms:hover {
  color: #fff;
}

.formBox h6 {
  font-size: 14px;
  color: #fff;
  font-weight: 400 !important;
}

.formBox h6 a {
  color: #81c8f6;
  transition: .5s;
}

.formBox h6 a:hover {
  color: #81c8f6;
}

.formBox .form-group label {
  font-size: 14px;
  display: block;
  width: 100%;
  color: #fff;
  margin-bottom: 10px;
}

.loginBanner .formBox h2 {
  color: #fff;
  font-weight: 600;
  text-align: center;
  font-size: 25px !important;
}

.formBox .nav-tabs {
  border-bottom: 0;
}

.formBox .nav-tabs .nav-link {
  background: #444946;
  border-radius: 0;
  color: #c8c8c8;
  text-transform: uppercase;
  font-size: 16px;
  transform: .5s;
  border: 0 !important;
}

.formBox .nav-tabs .nav-item.show .nav-link, .formBox .nav-tabs .nav-link.active {
  border: 0;
  background: #81c8f6;
  color: #ffffff;
}

.formBox .nav-tabs .nav-link:focus, .formBox .nav-tabs .nav-link:hover {
  border: 0;
  border-radius: 0;
  background: #81c8f6;
  color: #ffffff;
}

.formBox .form-group.input-group .nice-select {
  border-right: 0;
  border-radius: 5px 0 0 5px;
  height: 46px;
  line-height: 46px;
  color: #000;
  font-size: 14px;
  background-color: #d7d7d7;
}

.formBox .form-group.input-group .nice-select::after {
  border-color: #81c8f6;
}

.formBox .form-group.input-group .nice-select:active, .formBox .form-group.input-group .nice-select.open, .formBox .form-group.input-group .nice-select:focus {
  border-color: #d7d7d7;
}

.formBox .form-group.input-group .nice-select .option {
  display: block;
  margin: 0;
  color: #000;
  font-size: 14px;
}

.formBox .form-group.input-group .nice-select .list {
  background: #d7d7d7;
}

.formBox .form-group.input-group .nice-select .option:hover, .formBox .form-group.input-group .nice-select .option.focus, .formBox .form-group.input-group .nice-select .option.selected.focus {
  background: #121126;
  color: #000;
}

.btnBlue {
  background: var(--themeclr);
  /* / background-image: linear-gradient(180deg,#48a7e1 20%, #5087c0 50%); / */
  color: #fff !important;
  border-radius: 5px;
  padding: 7px 20px;
  border: 1px solid #5087c0;
  font-size: 13px;
  font-weight: 500;
  /* / box-shadow: 0 0 9px 1px #2765a7; / */
}

.btnBlue:hover {
  background: transparent;
  border: 1px solid #5087c0;
  color: #fff;
}

.customNav.up {
  background: #ebeff4;
}

.customNav .nav-link {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}

.customNav .nav-link:hover {
  color: #121126;
}

.dropdown-item.nav-link {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.gridBanner {
  display: flex;
  padding-bottom: 15px;
  margin-top: 30px;
}

.landGBCard {
  background: linear-gradient(0deg, #030060, #010175);
  width: 260px;
  height: 150px;
  margin-right: 20px;
  border-radius: 15px;
  position: relative;
  padding: 15px;
}

.btnTradeNow {
  background: linear-gradient(90deg, #81c8f6, #81c8f6);
  color: #010172;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 30px;
  position: absolute;
  left: 15px;
  bottom: 12px;
  padding: 3px 20px;
  box-shadow: none !important;
  transition: .5s;
  border: 2px solid #81c8f6;
}

.btnTradeNow:hover {
  background: transparent;
  border: 2px solid #81c8f6;
  color: #ffffff;
}

.btnLandingJoin {
  background: #5087c0;
  background-image: linear-gradient(180deg,#48a7e1 20%, #5087c0 50%);
  color: #fff;
  /* border-radius: 25px; */
  padding: 7px 20px !important;
  border:1px solid #5087c0;
  font-size: 13px !important;
  font-weight: 500 !important;
  transition: .2s all;
  -webkit-box-shadow: 0 0 9px 1px #2765a7;
  -moz-box-shadow: 0 0 9px 1px #2765a7;
  box-shadow: 0 0 9px 1px #2765a7;
  text-transform: uppercase;
}

.btnLandingJoin:hover {
  background: transparent;
  border:1px solid #5087c0;
  color: #fff;
}

.btnLandingJoin_outline {
  background: transparent; 
  color: #fff;
  border-radius: 25px;
  padding: 7px 20px;
  border:1px solid #5087c0;
  font-size: 13px;
  font-weight: 500;
  transition: .2s all;
  -webkit-box-shadow: 0 0 9px 1px #2765a7;
  -moz-box-shadow: 0 0 9px 1px #2765a7;
  box-shadow: 0 0 9px 1px #2765a7;
  text-transform: uppercase;
}

.btnLandingJoin_outline:hover {
  background: #5087c0;
  background-image: linear-gradient(180deg,#48a7e1 20%, #5087c0 50%);
  border:1px solid #5087c0;
  color: #fff;
}



.titleLGB {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.landGBCard h3 {
  font-size: 16px;
  color: #81c8f6;
  max-width: 65%;
}

.landGBCard p {
  color: #ebeff4;
  font-size: 13px;
  max-width: 75%;
  margin-bottom: 0px;
}

.lgbImg {
  position: absolute;
  bottom: -15px;
  right: -15px;
  z-index: 1;
}

.landingBannerContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 110px;
}
.landingBannerContent h2 {
  font-size: 80px;
  font-weight: 600;
  color: #fff;
  margin: 0;
}
.landingBannerContent h3 {
  font-size: 70px;
  color: #fff;
  font-weight: 400;
}
.landingBannerContent h4 {
  font-size: 18px;
  font-weight: 600;
  color: #81c8f6;
  margin: 0;
}
.landingBannerContent p {
  color: #79bdeb;
  font-size: 16px;
  text-align: center;
}
.coinTable .table td {
  border-top: 1px solid #e0e6ee !important;
  vertical-align: middle;
}

.landingBannerContent h3 span {
  color: #fff;
  font-weight: 700;
}

.homeBanner {
 
  position: relative;
  color: #81c8f6;
  background-size: contain;
  background-image: url(../images/banner-bg.png);
  background-repeat: no-repeat;
  background-position: bottom 200px left 0px;
  /* z-index: 1; */
}
.homeBanner .app_page_img
{
  padding-bottom: 100px !important;

}
/* .homeBanner::after
{
  content: "";
  background-size: contain;
  background-image: url(../images/banner-bg.png);
  background-repeat: no-repeat;
  background-position: bottom -100px left 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom:0px;
  left:0px;
  z-index: -1;
} */
/* .homeBanner::after {
  content: "";
  background-image: url("../images/banner_overlay.png");
  background-repeat-x: repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: flickerAnimation 5s infinite;
  -moz-animation: flickerAnimation 5s infinite;
  -o-animation: flickerAnimation 5s infinite;
   animation: flickerAnimation 5s infinite;
} */
/* .login_banner {
  background-image: url(../images/banner-bg.png),;

    background-repeat: no-repeat;
    background-size: 100% 100% !important;
    background-position: top 200px left 0px;
} */
.login_banner::after {
  display: none;
}
@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
.home_logo img {
  max-height: 55px;
}
.loginBanner {
  padding-top: 65px;
  padding-bottom: 35px;
  /* background: -webkit-linear-gradient(#ebeff4, #d9e6f1); */
}

/* .loginBanner .container {
  background: url(../images/homeBannerBg.png) no-repeat scroll 0 0;
} */

.AppComingSoonSection {
  background-size: cover;
}

.appComingSoonText {
  display: inline-block;
  text-align: center;
  width: 100%;
}

.appComingSoonText h3 {
  background: -webkit-linear-gradient(#eee52c, #d6b132);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 50px;
  font-weight: 600;
  margin-top: 35px;
  margin-bottom: 5px;
}

.appComingSoonText p {
  margin-bottom: 6px;
}

.loginBanner h3 {
  font-size: 36px;
  margin: 0 0 0px 0;
  color: #fff;
}

.loginBanner h2 {
  font-size: 80px;
  color: var(--themeclr);
  font-weight: 400;
  margin: 0 0 25px 0;
}

.loginBanner h2 small {
  font-size: 60px;
  font-weight: 400;
}
.lighttheme .loginBanner ul li{
  color:#000
}
.loginBanner ul li {
  color: #fff;
  text-transform: capitalize;
  font-size: 18px;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  margin-bottom: 15px;
}

.whyCard {  
  transition: 0.2s all;
  background: linear-gradient(90deg, #19a4f9, #19a4f9);
  padding: 25px 25px 45px 25px;
  border-radius: 20px;
  margin-top: 20px;
  transition: 0.2s all;
  cursor: default;
  -webkit-box-shadow: 0 0 20px 7px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0 0 20px 7px rgb(0 0 0 / 20%);
    box-shadow: 0 0 20px 7px rgb(0 0 0 / 20%);
}
.whyCard:hover {
  background-image: linear-gradient(180deg,#48a7e1 20%, #5087c0 50%);
}
.whyCard:hover::before {
  bottom: 5px;
}
.whyCard img {
  margin-right: 10px;
}
.whyCard h1 {
  font-size: 48px;
  font-weight: 900;
  color: #fff;
  transform: rotate(-90deg);
  position: relative;
}
.whyCard h1::after {
  content: "";
  background-color: #fff;
  width: 50px;
  height: 1px;
  position: absolute;
  top: 100px;
  left: 0;
}
.whyCard h3 {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  width: 150px;
  text-align: center;
  position: relative;
}
.whyCard p {
  font-size: 16px;
  transition: .5s;
  color: #fff;
}


.bitAbout {
  /* background-color: #060b28; */
  padding: 60px 0;
}

.aboutBit {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#d9e6f1+0,ebeff4+15,ebeff4+83,d9e6f1+100 */
  background: rgb(217, 230, 241);
  /* Old browsers */
  background: -moz-linear-gradient(top, rgba(217, 230, 241, 1) 0%, rgba(235, 239, 244, 1) 15%, rgba(235, 239, 244, 1) 83%, rgba(217, 230, 241, 1) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(217, 230, 241, 1) 0%, rgba(235, 239, 244, 1) 15%, rgba(235, 239, 244, 1) 83%, rgba(217, 230, 241, 1) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(217, 230, 241, 1) 0%, rgba(235, 239, 244, 1) 15%, rgba(235, 239, 244, 1) 83%, rgba(217, 230, 241, 1) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d9e6f1', endColorstr='#d9e6f1', GradientType=0);
  /* IE6-9 */
  padding: 55px 0;
}

.abtContent h3 {
  font-size: 30px;
  color: #010101;
  font-weight: 400;
  margin-bottom: 25px;
  line-height: 42px;
}
.abtContent h3 span {
  display: block;
  font-weight: 700;
  color: #81c8f6;
  font-style: italic;
}

.abtContent p {
  font-size: 18px;
  color: #212121;
}

.loginBanner ul li i {
  color: var(--themeclr);
  margin-right: 10px;
}

.homeBannerText {
  padding: 180px 0 0 0;
}

.homeBannerImg {
  margin-top: 95px;
}

.homeBannerText h3 {
  font-size: 40px;
  margin: 0 0 0px 0;
  color: #81c8f6;
  text-align: center;
}

.homeBannerText h3 span {
  font-weight: 700;
  font-style: italic;
}

.homeBannerText h2 {
  font-size: 80px;
  color: #81c8f6;
  font-weight: 400;
  margin: 0 0 25px 0;
}

.homeBannerText h2 small {
  font-size: 60px;
  font-weight: 400;
  color: #81c8f6;
}

.homeBannerText h6 {
  font-size: 14px;
  margin: 0 0 15px 0;
}

.homeBannerText h6 span {
  color: #121126;
}

.homeBannerText p a {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 12px 25px;
}

.homeBannerText ul li {
  display: flex;
  margin-bottom: 25px;
  align-items: center;
}

.homeBannerText ul li small {
  font-size: 18px;
}

.homeBannerText ul li:hover small {
  color: #121126;
}

.homeBannerText ul li span.bannerIcon {
  width: 58px;
  height: 40px;
  background: url('../images/bannerIcon.png') -11px -70px;
  display: inline-block;
  margin-right: 15px;
}

.homeBannerText ul li:hover span.bannerIcon {
  background-position: -11px -10px;
}

.coinTable {
  /* background-color: #060b28; */
  padding: 50px 0;
}
.coinTable .table th {
  font-size: 15px;
  font-weight: 600;
  color: #1f2021;
  padding: 20px 0.75rem;
}
.coinTable .table td {
  border: 0;
  color: #555b63;
  white-space: nowrap;
  padding: 20px 0.75rem;
}

.coinTable .table th:first-child, .coinTable .table td:first-child {
  padding-left: 30px;
}
.coinTable .table td:first-child {
  color: #000;
}
.coinTableMain {
  background: #02071d;
  border-radius: 15px;
  -webkit-box-shadow: 0 0 20px 1px #dbe2e92e;
  -moz-box-shadow:0 0 20px 1px #dbe2e92e;
  box-shadow: 0 0 20px 1px #dbe2e92e;
  margin: 0 auto;
  position: relative;
  z-index: 9;
}

.coinTable nav {
  background: transparent;
}

.coinTable .nav-tabs {
  border-bottom: none;
  background: #02071d;
  border-radius: 15px 15px 0px 0px;
}

.coinTable .nav-tabs .nav-item {
  margin-bottom: 0;
}

.coinTable .nav-tabs .nav-link {
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  padding: 20px 40px;
  /* border-bottom: 3px solid #ffffff; */
  /* margin-left: 25px; */
  border-radius: 0;
  cursor: pointer;
  /* background: #81c8f6; */
  position: relative;
  border-right:1px solid #161c37 !important;
  border-bottom:none !important;
  border-left: none !important;
  border-top: none !important;

}


.coinTable .nav-tabs .nav-item.show .nav-link, 
.coinTable .nav-tabs .nav-link.active {
  color: #fff;
  border: 0;
  /* border-bottom: 3px solid #81c8f6; */
  border-radius: 0;
  cursor: pointer;
  background: transparent;
}

.coinTable .nav-tabs .nav-link img {
  margin-right: 10px;
  width: 25px;
}

.btnMarketBuy {
  border: 1px solid #08b98b;
  background-color: #08b98b;
  min-width: 75px;
  margin-left: 15px;
  color: #fff;
  transition: .5s;
  font-size: 14px;
  font-weight: 500;
  padding: 3px 15px;
  border-radius: 25px;
  -webkit-box-shadow:0 0 10px 2px #bebebf70;
  -moz-box-shadow: 0 0 10px 2px #bebebf70;
  box-shadow: 0 0 10px 2px #bebebf70;
}

.btnMarketBuy:hover {
  background: transparent;
  border: 1px solid #08b98b;

  color: #ffffff;
}

.btnMarketSell {
  border: 1px solid #c5235d;
  background-color: #c5235d;
  min-width: 75px;
  margin-left: 15px;
  color: #fff;
  transition: .5s;
  font-size: 14px;
  font-weight: 500;
  padding: 3px 15px;
  border-radius: 25px;
  -webkit-box-shadow: 0 0 10px 2px #bebebf70;
  -moz-box-shadow: 0 0 10px 2px #bebebf70;
  box-shadow: 0 0 10px 2px #bebebf70;
}

.btnMarketSell:hover {
  background: transparent;
  color: #ffffff;
  border-color: #c5235d;
}

.coinTable table.dataTable {
  margin: 0 !important;
  border-bottom: 2px solid #81c8f6;
}

.coinTable table.dataTable th {
  border-bottom: 0;
  background: rgb(63, 64, 48);
  background: linear-gradient(0deg, rgba(63, 64, 48, 1) 0%, rgba(68, 69, 54, 1) 100%);
  color: #d7d7d7;
  font-size: 18px;
  font-weight: 600;
  padding: 15px 15px;
  border-top: 0;
}

.coinTable table.dataTable td {
  background-color: #2d312e;
  border-bottom: 1px solid #81c8f6;
  border-top: 0;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  padding: 15px 15px;
  transition: .5s;
}

.coinTable table.dataTable tr:hover td {
  background-color: #121126;
}

.coin-table-show-more {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* border-top: 1px solid #e0e6ee; */
  overflow: hidden;
}

.coin-table-show-more a {
  padding: 1rem;
  width: 100%;
  color: #81c8f6;
  background: transparent;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  overflow: hidden;
}

.coin-table-show-more:hover a {
  color: #697882;
}



.cryptoFeatures {
  background: url(../images/featuresBG.jpg) no-repeat scroll center bottom;
  background-size: cover;
  padding-top: 35px;
}
.landinTitle {
  font-size: 40px;
  font-weight: 400;
  color: #1f2021;
}
.landinTitle_light {
  color: #ffffff;
}
.landinTitle span {
  font-weight: 700;
}
.landinTitle_light span {
  color: #81c8f6;
}
.landing_content_light {
  color: #72738c;
}
.wbLeftContent h4 {
  font-size: 34px;
  font-weight: 400;
  color: #121126;
  text-align: left;
  margin-bottom: 25px;
}

.wbLeftContent h4 span {
  font-weight: 700;
  font-style: italic;
}

.wbLeftContent h4 img {
  margin-right: 7px;
}

.wbLeftContent p {
  color: #767676;
  margin-bottom: 30px;
}

.wbRightContent h4 {
  font-size: 34px;
  font-weight: 400;
  color: #121126;
  text-align: left;
  margin-bottom: 25px;
}

.wbRightContent h4 span {
  font-weight: 700;
  font-style: italic;
}

.wbRightContent h4 img {
  margin-right: 7px;
}

.wbRightContent p {
  color: #767676;
  margin-bottom: 30px;
}

.whyBisanse {
  padding-top: 50px;
  padding-bottom: 80px;  
  background-color: #19a4f9;
}

.whyBisanse .row.mt-5 {
  margin-top: 75px !important;
}

.footer_top_left {
  background: #d9e1ea;
  display: flex;
}

.loginSignUp {
  background: linear-gradient(90deg, #81c8f6, #81c8f6);
  border-radius: 50px;
}

.loginSignUp li {
  color: #ffffff;
  line-height: 24px;
}

.loginSignUp a {
  color: #ffffff !important;
}

.loginSignUp a:hover {
  color: #03005b !important;
}

.wbLeftImg {
  text-align: right;
  margin-right: 20px;
}

.btnType1 {
  background: #81c8f6;
  border: 1px solid #81c8f6;
  box-shadow: none !important;
  color: #121126;
  padding: 7px 30px 8px;
  transition: .5s;
}

.btnType1:hover {
  background: #121126;
  border: 1px solid #121126;
  color: #ffffff;
}

.TradingPlatform {
  background: #121126;
  min-height: 400px;
  max-height: 400px;
  margin-top: 0px;
}
.footer_bottom_left h2 {
  font-size: 30px;
  font-weight: 600;
  color: #fff;
}
.footer_bottom_left h2 span {
  color: #81c8f6;
}
.footer_bottom_left p {
  color: #ffffff;
  font-size: 13px;
  width: 500px;
  line-height: 2 !important;
}
.tpContent {
  position: relative;
}

img.bisanseScreen {
  margin-top: 0px;
  position: absolute;
  right: 0;
  top: -40px;
}

.tpLeftContent {
  float: left;
  display: flex;
  flex-direction: column;
  color: #fff;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
}

.tpLeftContent h2 {
  font-size: 30px;
  margin-bottom: 35px;
}

.tpLeftContent h2 span {
  font-weight: 700;
  font-style: italic;
  color: #81c8f6;
}

.tpLeftContent h2 span {
  display: block;
}

.tpLeftContent li {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 15px;
}

.tpLeftContent li img {
  margin-right: 10px;
}

.pageTitle {
  text-align: center;
  font-size: 36px;
}

.pageTitle small {
  display: block;
  font-size: 18px;
  color: #81c8f6;
}

.cryptoFeaturesContText h4 {
  font-size: 28px;
  color: #121126;
  transition: .5s;
}

.cryptoFeaturesCont:hover .cryptoFeaturesContText h4 {
  color: #81c8f6;
}

.homeCTA {
  background: url(../images/ctabg.jpg) no-repeat scroll center top;
  background-size: cover;
  min-height: 200px;
  padding: 55px 0 25px;
}

.homeCTACont h6 {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  color: #19222d;
  margin-bottom: 0;
  text-shadow: 0px 0px 10px #ffffbe;
}

.homeCTACont h3 {
  text-align: center;
  font-size: 50px;
  font-weight: 600;
  margin-bottom: 25px;
  text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
}

.homeCTACont p a {
  text-transform: uppercase;
  font-weight: 600;
  padding: 10px 20px;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
}

.homeCTACont p a:hover {
  background-color: #2d312e;
}

.getStarted {
  display: flex;
  padding-top: 50px;
  padding-bottom: 80px;
  background-color: #fff;
}
.primary_btn {
  border: 2px solid #81c8f6;
  color: #81c8f6;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: transparent;
  padding: 10px 30px;
  transition: 0.2s all;
}
.primary_btn:hover {
  color: #121126;
  background-color: #81c8f6;
}
.gsmCard {
  background: rgba(254, 254, 254, .8);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  height: 180px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gsmCard h3 {
  font-size: 26px;
  color: #050505;
  font-weight: 700;
  font-style: italic;
  margin-bottom: 20px;
}

.gsmCardBG {
  /*background: url(../images/bisanse/circleDot.png) no-repeat scroll center center;*/
  width: 316px;
  height: 316px;
  display: flex;
  align-items: center;
}

.bfCard img {
  margin-bottom: 25px;
}

.bfCard h3 {
  color: #020063;
  font-size: 30px;
  font-weight: 600;
}

.ourPartners {
  background-color: #ffffff;
  padding: 50px 0;
}

.ourPartners h2 {
  font-size: 34px;
  color: #121126;
  text-align: center;
}

.partnersLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.partnersLogo a {
  display: block;
  margin: 0 40px;
}

.ourPartners h2 span {
  font-weight: 700;
  font-style: italic;
}

.getStartedCont h4 {
  font-size: 20px;
  font-weight: 700;
  color: #121126;
  text-transform: uppercase;
  margin: 20px 0 0 0;
  text-align: center;
  transition: .5s;
}

.getStartedCont:hover h4 {
  color: #81c8f6;
}

.getStartedCont span {
  display: block;
  margin: 0 auto;
}

.getStartIcon {
  height: 130px;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.getStartIcon1 {
  width: 101px;
  height: 101px;
  background: url('../images/gsicon.png') -10px -10px;
  transition: .3s;
}

.getStartedCont:hover .getStartIcon1 {
  background-position: -10px -131px;
}

.getStartIcon2 {
  width: 100px;
  height: 130px;
  background: url('../images/gsicon.png') -10px -498px;
  transition: .3s;
}

.getStartedCont:hover .getStartIcon2 {
  background-position: -10px -648px;
}

.getStartIcon3 {
  width: 98px;
  height: 103px;
  background: url('../images/gsicon.png') -10px -252px;
  transition: .3s;
}

.getStartedCont:hover .getStartIcon3 {
  background-position: -10px -375px;
}

.getStartedCont::after {
  content: "";
  background-image: url(../images/arrowTriple.png);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: 0 0;
  width: 32px;
  height: 17px;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -17px;
  right: 0;
}

.getStartedCont.noarrow::after {
  display: none;
}

.howBitbazzi {
  padding: 50px 0 80px;
  background-color: #fff;
  position: relative;
}
.mainFooter {
 
  /* background: #d9e1ea url(../images/footerbg.png) repeat-y scroll right 0; */
  background-color: var(--themeclr);
  padding-bottom: 20px;
  padding-top: 60px;
  position: relative;
  background-size:100% 100%;
        /* background-image: url(../images/footer_bg.png); */
        background-repeat: no-repeat;
        background-position: top 0px left 0px;
}

ul.getstarted_list {
  position: absolute;
  right: 0;
  width: 50%;
  top: 15%;
}
.getstarted_list li {
  background: #81c8f6;
  margin: 15px 0;
  padding: 18px 50px;
  border-radius: 100px 0 0 100px;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  margin-left: auto;
}
.getstarted_list li:first-child {
  width: 90%;
}
.getstarted_list li:nth-child(2) {
  width: 100%;
}
.getstarted_list li:nth-child(3) {
  width: 80%;
}
.getstarted_list li img {
  margin-right: 20px;
}
.Footerrow {
  display: flex;
  width: 100%;
}

.footer_top_left {
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  background: unset;
}

.footer_bottom_right {
  display: flex;
  flex-direction: column;
  padding: 15px 0;
}

.footer_top_left h4 {
  position: relative;
  margin: 10px 0;
  color: #212121;
  font-size: 18px;
  font-weight: 600;
}

.footer_top_left p {
  position: relative;
  margin-bottom: 0;
  font-size: 14px;
}

.footer_bottom_right h4 {
  position: relative;
  margin: 10px 0;
  color: #212121;
  font-size: 18px;
  font-weight: 600;
}

.footer_bottom_right ul {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.footer_bottom_right .copyright li {
  margin-right: 0.5rem;
}

.social h4 {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
}
.social {
  text-align: right;
}
.socialIcons li a {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 15px 18px; */
  width: 40px;
  height: 40px;
  border-radius: 5px;
  color: #fff;
  font-size: 26px;
  /* border: 1px solid #5087c0; */
  transition: 0.2s all;
}
.socialIcons {
  gap: 5px;
}
.socialIcons li a:hover {
  color:var(--themeclr) !important;
  background-color: #fff !important;
  box-shadow: 0 !important;
}
.footer_top_left ul {
  position: relative;
  display: flex;
}

.footer_bottom_right ul li {
  margin: 5px;
}

.footer_top_left ul li a {
  margin: 0 15px;
  color: #fff;
  font-size: 14px;
}

.footer_top_left ul li a:hover {
  color: #81c8f6;
}


.footerSocialLinks {
  text-align: right;
}

.footerSocialLinks h3 {
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 15px;
}

.footerSocialLinks ul {
  display: flex;
  justify-content: flex-end;
}

.footerSocialLinks ul li {
  font-size: 24px;
  margin-left: 45px;
}

.footerSocialLinks ul li a {
  color: #778092;
  transition: .5s;
}

.footerSocialLinks ul li a:hover {
  color: #81c8f6;
}

.footerBottomBox {
  display: block;
  border-top: 1px solid #263a64;
  padding: 20px 0 25px;
  margin-top: 50px;
}

.footerBottomBox p {
  margin-bottom: 0;
  color: #e0e0e0;
  font-size: 14px;
}

.footerBottomLinks {
  display: flex;
}

.footerBottomLinks li {
  font-size: 14px;
}

.footerBottomLinks li a {
  color: #e0e0e0;
}

.middLinkFooter li {
  margin-bottom: 15px;
}

.middLinkFooter li a {
  color: #ffffff;
  transition: .5s;
}

.middLinkFooter li a:hover {
  color: #81c8f6;
}

.secFAQ {
  padding: 50px 0;
  background: #151e28;
}
.loginsec span.input-group-text{
  border-color: #81c8f6;
}
.homeAccordian #accordion .card-header .btn {
  padding: 0;
  display: block;
  width: 100%;
  text-align: left;
  color: var(--themeclr);
  font-weight: 500;
  font-size: 1.2rem;
  text-decoration: none;
  border-bottom: 0;
  white-space: normal;
  display: flex;
  justify-content: space-between;
  box-shadow: none !important;
}
.lighttheme .replytoadmin .card-header{
  background-color: #e2e2e2
}
.lighttheme .homeAccordian #accordion .card-header .btn.btn-link.collapsed,
.lighttheme .homeAccordian #accordion .card-header .btn i:before{
  color: var(--themeclr);
}  
.homeAccordian #accordion .card-header .btn.btn-link.collapsed {
  color: #ffffff;
  text-decoration: none;
  transition: .5s;
  border-bottom: 1px solid #959cab;
  padding-bottom: 20px;
  transition: .5s;
  font-weight: 500;
  white-space: normal;
  display: flex;
  justify-content: space-between;
  box-shadow: none !important;
}

.homeAccordian #accordion .card-header .btn.btn-link.collapsed:hover {
  text-decoration: none;
  color: #81c8f6;
}

.homeAccordian #accordion .card-header:hover .btn.btn-link.collapsed {
  text-decoration: none;
  color: #81c8f6;
}

.homeAccordian #accordion .card-header .btn i {
  float: right;
  margin-right: 15px;
  margin-top: 0px;
  padding: 8px;
  color: var(--themeclr);
  font-size: .85rem;
  transition: .5s;
}

.homeAccordian #accordion .card-header {
  padding: .55rem 0rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 0;
}

.homeAccordian #accordion .card {
  border-radius: 0px;
  border: 0;
  background: transparent;
}

.homeAccordian #accordion .card-body {
  padding: .5rem 0rem .3rem 0rem;
}

.homeAccordian #accordion .card-body p {
  color: #fff;
}
.lighttheme .homeAccordian #accordion .card-body p{
  color:#000
}

.homeAccordian #accordion .card-header [data-toggle="collapse"] .fa:before {
  content: "\f068";
  color: var(--themeclr);
}

.homeAccordian #accordion .card-header [data-toggle="collapse"].collapsed .fa:before {
  content: "\f067";
  color: #ffffff;
}

.homeAccordian #accordion .card-header:hover .btn i:before {
  color: #81c8f6 !important;
}

.homeAccordian #accordion .card-header .btn .numList {
  background: #ff8300;
}

.homeAccordian #accordion .card-header .btn.btn-link.collapsed .numList {
  background: #2c6ed5;
}

.pageFooter {
  background: #121a23;
  padding: 25px 0;
}

.footerCont h6 {
  color: #81c8f6;
  margin-bottom: 25px;
}

.footerCont h6.footerBottTitle {
  color: #71767b;
  font-size: 14px;
}

.footerCont ul li a {
  color: #c8c8c8;
}

.footerCont ul li a:hover {
  color: #121126;
}

.footerCont p {
  color: #c8c8c8;
  font-size: 14px;
  line-height: 18px;
}

.footerCont ul.socialIcons {
  margin-bottom: 20px;
}

.footerCont ul.socialIcons li {
  display: inline-block;
  margin: 0 15px;
}

.footerCont ul.socialIcons li:first-child {
  margin-left: 0;
}

.footerCont ul.socialIcons li:last-child {
  margin-right: 0;
}

.footerCont ul.socialIcons li a {
  font-size: 24px;
  color: #71767b;
}

.footerCont ul.socialIcons li a:hover {
  color: #121126;
}

.featuresIcon1 {
  width: 94px;
  height: 93px;
  display: block;
  background: url('../images/featuresIcon.png') -10px -10px;
  transition: .3s;
}

.cryptoFeaturesCont:hover .featuresIcon1 {
  background-position: -10px -123px;
}

.featuresIcon2 {
  width: 94px;
  height: 93px;
  display: block;
  background: url('../images/featuresIcon.png') -10px -236px;
  transition: .3s;
}

.cryptoFeaturesCont:hover .featuresIcon2 {
  background-position: -10px -349px;
}

.featuresIcon3 {
  width: 94px;
  height: 93px;
  display: block;
  background: url('../images/featuresIcon.png') -10px -462px;
  transition: .3s;
}

.cryptoFeaturesCont:hover .featuresIcon3 {
  background-position: -10px -575px;
}

.featuresIcon4 {
  width: 94px;
  height: 93px;
  display: block;
  background: url('../images/featuresIcon.png') -10px -688px;
  transition: .3s;
}

.cryptoFeaturesCont:hover .featuresIcon4 {
  background-position: -10px -801px;
}

.cryptoFeaturesImg img {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  transition: .5s;
}

.cryptoFeaturesImg img:hover {
  filter: unset;
}

.customNav {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #060b27;
  border-bottom: none !important;
  transition: .5s;
  /* background-image: url(../images/bg-trip.png); */
  background-repeat: repeat;
  background-size: 100% 100%;
  background-position: top;
  /* border-bottom: 1px solid #ccd4df; */
}

.customNav .nav-item {
  /* padding: 0px 12px; */
  border-radius: 20px;
}

.lang_select {
  background: transparent url("../images/downarrow.png") no-repeat right .75rem center/10px 6px !important;
  border: none;
  color: #fff !important;
  box-shadow: none !important;
}
.lang_select option {
  color: #000 !important;
}
.customNav .nav-item:hover {
  color: #5087c0 !important;
}
.customNav .nav-item .nav-link.login_btn,.customNavTrade .nav-item .nav-link.login_btn {
  /* background: var(--themeclr); */
  background: transparent;
  color: #fff !important;
  border-radius: 5px;
  padding: 7px 20px;
  border: 1px solid #5087c0;
  font-size: 13px;
  font-weight: 500;
}
.themebtn,.btnDefaultNewBlue,.btnDefaultNew.btnLandingJoin,.darktheme input.themebtn[type="button"],.btnBuyKSC,
.lighttheme input.themebtn[type="button"],.settingsec .btnDefaultNew {
  background: var(--themeclr) !important;
  color: #fff !important;
  border-radius: 5px !important;
  padding: 7px 20px !important;
  border: 1px solid #5087c0 !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
  box-shadow: none !important;
}
.number-input.number-input input[type="number"]#quantity{
  color: #fff !important;
}
.lighttheme .number-input.number-input input[type="number"]#quantity{
  color: #000 !important;
}
.border_blue_select > div div:hover {
  background: #444444 !important;
}
.lighttheme .border_blue_select > div div:hover {
background:#eaeaea !important;
}
.border_blue_select > div{
border-radius: 5px;
}
.darkmodal .border_blue_select div > div:first-child {
 color: #fff !important;
}
.border_blue_select div > div:last-child
.border_blue_select div > div:last-child{
border: 1px solid var(--themeclr);
border-radius: 0 5px 5px 0;
}
.darktheme .border_blue_select div > div:first-child {
  background-color: #000;

}
.border_blue_select > div:nth-child(2) {
  /* background: red !important; */
}
.lighttheme .border_blue_select div > div:first-child {
  background-color: #fff;
  color: #000;
}

.lighttheme div#nav-tabContent-tradeLimitMarket .border_blue_select > div{
  background: #eaeaea;
}
.lighttheme div#nav-tabContent-tradeLimitMarket .border_blue_select > div div:first-child,
.lighttheme .settingsec .border_blue_select > div div:first-child {
  background: #eaeaea;
  color: #000;
  border-color: #a89696;
}
.darktheme div#nav-tabContent-tradeLimitMarket .border_blue_select > div div:first-child,
.darktheme .settingsec .border_blue_select > div div:first-child {
  background: #000;
  color: #fff;
  border-color: var(--themeclr);
}
.darktheme .myasset .border_blue_select:hover div > div:first-child,
.darktheme .settingsec .border_blue_select:hover div > div:first-child,
.darkmodal  .border_blue_select:hover div > div:first-child {
  background: black !important;
}
.lighttheme .myasset .border_blue_select:hover div > div:first-child,
.lighttheme .settingsec .border_blue_select:hover div > div:first-child,
.lightmodal  .border_blue_select:hover div > div:first-child {
  background: white !important;
}
div#nav-tabContent-tradeLimitMarket .border_blue_select {
  background: #ff000000 !important;
}
.lighttheme div#nav-tabContent-tradeLimitMarket .border_blue_select > div div:first-child {
  background: #eaeaea;
  color: #000;
  border-color: #eaeaea;
}
.lighttheme .contactus textarea,.lighttheme .contactus input {
  color: #000 !important;
}
.address span{
  max-width: 225px;
text-overflow: ellipsis;
display: block;
overflow: hidden;
}
.lightmodal .withdrawdetails {
  color: #000;
}
.themebtn:hover,.btnDefaultNew:hover,.btnDefaultNewBlue:hover,.btnLandingJoin:hover,.darktheme input.themebtn[type="button"]:hover,
.lighttheme input.themebtn[type="button"]:hover, .btnBuyKSC:hover{
  
  background: transparent !important;
  color: var(--themeclr) !important;
  border-radius: 5px;
  padding: 7px 20px;
  border: 1px solid var(--themeclr);
  font-size: 13px;
  font-weight: 500;
}
.border_blue_select{
  border-radius: 10px;
  border: 1px solid red;
}
.thememodal .modal-content input {
  border-radius: 5px;
}
.lightmodal .thememodal .modal-content input{
  background-color: #fff !important;
}
.darktheme .border_blue_select > div:last-child *{
color: #fff !important
}
.launchpad_token_wrapper .btnLandingJoin span {
  color: #fff !important;
}
.border_blue_select .css-1wa3eu0-placeholder{
  padding-right: 10px;
}
.lighttheme .customNav .nav-item .nav-link.login_btn{
  color:var(--themeclr) !important
}
.lighttheme .customNavTrade .nav-item .nav-link.login_btn{
  color:var(--themeclr) !important
}
.tradeFulltabbedTable.tradeFulltabbedTable_h nav {
  white-space: nowrap;
}
.tradeFulltabbedTable.tradeFulltabbedTable_h .nav{
  flex-wrap: nowrap;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
.lighttheme a.nav-link.login_btn{
  color: var(--themeclr) !important;
}
.customNav .nav-item .nav-link.login_btn:hover,.customNavTrade .nav-item .nav-link.login_btn:hover {
  background: var(--themeclr);
}
.customNav .nav-item .nav-link.register_btn,.customNavTrade .nav-item .nav-link.register_btn {
  background: var(--themeclr);
    /* background-image: linear-gradient(180deg,#48a7e1 20%, #5087c0 50%);  */
    color: #fff !important;
    border-radius: 5px;
    padding: 7px 20px;
    border: 1px solid #5087c0;
    font-size: 13px;
    font-weight: 500;
    /* / box-shadow: 0 0 9px 1px #2765a7; / */
}
.customNav .nav-item .nav-link.register_btn:hover,.customNavTrade .nav-item .nav-link.register_btn:hover {
  background: transparent;
  border:1px solid #5087c0;
  color:#fff;
}
.lighttheme .customNavTrade .nav-item .nav-link.register_btn:hover {
  color:var(--themeclr) !important;
}
.lighttheme .themeinput input {
  background-color: #fff;
  border-color: #fff;
}
.darktheme .themeinput input {
  background-color: #000;
  border-color: #000;
}
.mainFooter .inputgrp button,.mainFooter .inputgrp input{
  background: #fff;
}
.mainFooter .inputgrp input{
  border-color: #fff;
}
.lighttheme #contact-us .themebtn {
  background-color: #fff;
  color: #000 !important;
}
.lighttheme #contact-us .themebtn:hover{
  color:var(--themeclr) !important
}
#contact-us .form-group label{
  color:#fff !important
}
.lighttheme #contact-us .form-group input{
  color:#000 !important
}
.lighttheme .themeinput input:focus{
  background-color: #fff;
  color:#000 !important
}
.darktheme .launchpad_contact_details p{
  color: #fff;
}
.darktheme .themeinput input:focus{
  background-color: #000;
  color:#fff
}
.darktheme #contact-us .themebtn {
  background-color: #000;
  color: #fff !important;
  border-color: #000;
}
.darktheme #contact-us .themebtn:hover{
  color:var(--themeclr) !important
}

.lighttheme .customNavTrade .nav-item .nav-link.login_btn:hover{
  color: #fff !important;
}
.menu_right li.nav-item {
  margin-left: 10px;
}
.customNav .nav-item .nav-link {
  padding: 20px;
  text-transform: uppercase;
  color: #d2d3d8;
  font-size: 13px;
  font-weight: 500;
}
.lighttheme .customNav .nav-item .nav-link{
  color:#000
}
ul.menu_right {
  display: flex;
  align-items: center;
}
.customNav .nav-item:hover .nav-link {
  color: #5087c0;
}
.customNav .nav-item .nav-link:after {
  /*display: none;*/
}
.widget_panel {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #373a4d;
}
.widget_panel_left h5 {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  margin: 0;
}
.widget_panel_left p {
  font-size: 14px;
}
.widget_panel_left h2 {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
}
.widget_panel_left h3 {
  font-size: 13px;
  color: #72738c;
}
.widget_panel_right {
  display: flex;
}
section.widget_setion {
  background: #060b28;
  padding: 25px;
}
.widget_panel_right img:first-child {
  margin-right: -10px;
}
.widget_panel_right img {
  width: 50px;
}
.innerCMS {
  padding-top: 0px;
  /* border-top: 0px solid #314779; */
  padding-bottom: 15px;
  /* background: #f0f0f0; */
}

.innerCMS .darkBox {
  margin-top: 25px;
}

.mt25 {
  margin-top: 25px;
}

#blogNavBG {
  background: #121126;
}

.darkBox {
  background: #111111 !important;
  /* border-radius: 15px; */
  box-shadow: 0 0 20px 1px #dbe2e92e;

}
.lighttheme .darkBox{
  background:#f2f2f2 !important;
}

.tableHead {
  display: block;
  /* border-bottom: 1px solid #314779; */
  margin-bottom: 0px;
  padding-bottom: 8px;
}

.tableHead h2 {
  font-size: 24px;
  color: var(--themeclr);
  text-align: center;
  margin: 12px 15px 15px;
  font-weight: 600;
}

.tableHead h1 {
  font-size: 24px;
  color: #81c8f6;
  text-align: left;
  margin: 12px 15px 15px;
  font-weight: 600;
}

.leverage {
  position: relative;
  margin-top: 150px;
}

.darkBox.leverage::before {
  content: "";
  position: absolute;
  left: 50%;
  top: -150px;
  background-image: url(../images/leverage.png);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center top;
  padding-top: 0;
  width: 153px;
  height: 156px;
  margin-left: -75px;
}

.darkBoxSpace {
  padding: 35px 35px 15px;
}

.contentPage h4 {
  font-size: 16px;
  font-weight: 600;
  color: var(--themeclr);
}

.contentPage p {
  font-size: 14px;
  color: #ffffff;
}
.lighttheme .contentPage p{
  color: #000;
}

.CalculaterPart label {
  color: #ffffff;
  margin-bottom: 10px;
}

.contentPage h5 {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 600;
  color: var(--themeclr);
  display: inline-block;
  width: 100%;
}

.cmsTopImg {
  display: block;
  margin: 0 auto;
}

.borderBottomRemove {
  border-bottom: 0;
}

.buttonType1 {
  background-color: #81c8f6 !important;
  /* border-radius: 5px; */
  padding: 8px 30px;
  font-size: 14px;
  color: #121126 !important;
  text-transform: uppercase;
  transition: 0.3s !important;
  border: 0;
  box-shadow: none !important;
  cursor: pointer;
	border: 1px solid #81c8f6 !important;
}

.buttonType1:hover {
  background-color: #1ea369 !important;
	border: 1px solid #1ea369 !important;
}

.bonusBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #121126;
  border: 1px solid #959cab;
  border-radius: 5px;
  padding: 15px;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  transition: .5s;
}

.bonusBox:hover {
  /* -webkit-box-shadow: 0px 0px 25px 2px rgba(117, 91, 5, 0.5);
  -moz-box-shadow: 0px 0px 25px 2px rgba(117, 91, 5, 0.5);
  box-shadow: 0px 0px 25px 2px rgba(117, 91, 5, 0.5); */
  border-color: #81c8f6;
}

.bonusfaceIcon {
  background: #3b5a99;
  width: 36px;
  height: 36px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin: 0 3px;
  border-radius: 50%;
  font-size: 18px;
}

.bonusfaceIcon:hover {
  background: #eeeeee;
  color: #3b5a99;
}

.bonustwitterIcon {
  background: #33ccff;
  width: 36px;
  height: 36px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin: 0 3px;
  border-radius: 50%;
  font-size: 18px;
}

.bonustwitterIcon:hover {
  background: #eeeeee;
  color: #33ccff;
}

.bonusteleIcon {
  background: #36AEE2;
  width: 36px;
  height: 36px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin: 0 3px;
  border-radius: 50%;
  font-size: 18px;
}

.bonusteleIcon:hover {
  background: #eeeeee;
  color: #33ccff;
}

.bonusRewards h4 {
  font-size: 16px;
  font-weight: 600;
  color: #81c8f6;
  margin: 0 0 20px 0;
}

.bonusBox h2 {
  font-size: 44px;
  color: var(--themeclr);
  font-weight: 600;
}
.lighttheme .bonusBox h2 small{
color:#000
}
.borLeftRight {
  border-left: 1px dotted #959cab;
  border-right: 1px dotted #959cab;
}

.bonusBox h2 small {
  font-size: 28px;
  color: #ffffff;
  font-weight: 600;
}

.bonusBox p {
  font-size: 12px;
  color: #fff;
  text-align: center;
}
.lighttheme .bonusBox p{
color: #000;
}
.bonusBox p span {
  color:
    #81c8f6;
}

.bonusRewards .bonusBox h4 {
  font-size: 22px;
  color: var(--themeclr);
  text-align: center;
}

.tableHead.tableHeadBlock p {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  padding-left: 15px;
  padding-right: 15px;
}

.tableHead.tableHeadBlock p {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  padding-left: 15px;
  padding-right: 15px;
}

.btnClaim {
  margin-top: auto;
}

.btnClaim .buttonType1 {
  padding: 15px 25px;
}

.bonusContent h4 {
  margin-bottom: 15px;
  margin-top: 45px;
}

.bonusContent p {
  font-size: 12px;
}

#questionnaire .modal-dialog .questionnaireContent {
  background-color: #19222d;
  border: 1px solid #242d39;
  border-radius: 0;
}

#questionnaire .modal-dialog .questionnaireContent .modal-body {
  padding: 0;
}

.questionnaireContent button.close {
  position: absolute;
  top: 0;
  right: -25px;
  color: #fff;
  font-size: 36px;
  outline: 0;
}

.popHead {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #242d39;
  align-items: center;
  padding: 0 20px;
}

.popHead h3 {
  font-size: 24px;
  color: #81c8f6;
  font-weight: 600;
  margin: 0;
}

.popHead h2 {
  font-size: 72px;
  color: #81c8f6;
  font-weight: 600;
  margin: 0;
}

.popHead h2 small {
  font-size: 28px;
  color: #ffffff;
  font-weight: 600;
  display: inline-block;
  vertical-align: top;
  line-height: 72px;
}

.popUpSpace {
  padding: 20px 0px;
}

#questionnaire .form-group label {
  display: block;
  width: 100%;
}

.answerList {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 20px 0;
  border-bottom: 1px solid #242d39;
  padding-bottom: 20px;
}

.faqCont .card {
  background: transparent;
  border: 0;
  margin-bottom: 15px;
}

.faqCont .card-header {
  padding: 0;
  background: transparent;
  border: 0;
}

.faqCont .card-header .btn {
  padding: 0;
  text-decoration: none;
  color: #fff;
  font-size: 16px;
}

.faqCont .card-header h5 {
  margin: 0;
}

.faqCont .card-body {
  padding-left: 0;
  padding-right: 0;
  padding-top: 12px;
  padding-bottom: 0;
}

.faqCont .card-body p {
  color: #c8c8c8;
}

.faqCont .card-header .btn.btn-link {
  border-bottom: 1px solid #242d39;
  border-radius: 0;
  display: inline-block;
  width: 100%;
  text-align: left;
  padding-bottom: 12px;
  font-weight: 600;
}

.faqCont .card-header .btn.btn-link.collapsed {
  border-bottom: 0px solid #fff;
  border-radius: 0;
  font-weight: 400;
}

.recentBlog ul {
  margin: 0;
  padding: 0;
}

.recentBlog ul li {
  list-style: none;
  margin: 0 0 24px 0;
}

.recentBlog ul li a {
  color: #646464;
  font-weight: 500;
  transition: .3s;
}

.recentBlog ul li a:hover {
  color: #81c8f6;
}

.recentBlog ul li span {
  display: block;
  color: #757575;
  font-size: .75rem;
}



/* .blogMainContent .container-fluid {
  padding-top: 135px;
} */

.blogMainContent header {
  background: #121126;
}

.sideBarBox {
  background: #fff;
  padding: 15px;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  margin-bottom: 25px;
}

.sideBarBox h3 {
  font-size: 1.2rem;
  padding-bottom: 14px;
  border-bottom: 1px solid #e2e2e2;
  margin-bottom: 15px;
}

ul.tagCloud {
  margin: 0;
  padding: 0;
}

ul.tagCloud li {
  list-style: none;
  display: inline;
  margin-right: 5px;
  margin-bottom: 12px;
}

ul.tagCloud li a {
  transition: .5s;
  text-decoration: none;
}

ul.tagCloud li a.smallDark {
  font-size: .8rem;
  color: #757575;
}

ul.tagCloud li a.smallLight {
  font-size: .8rem;
  color: #a3a2a2;
}

ul.tagCloud li a.bigDark {
  font-size: 1.1rem;
  color: #757575;
}

ul.tagCloud li a.bigRed {
  font-size: 1.3rem;
  color: #ff9500;
  text-decoration: underline;
}

ul.tagCloud li a:hover {
  color: #ff9500;
  text-decoration: none !important;
}

.sidebarDownloads {
  margin-top: 40px;
}

.sidebarDownloads p a {
  color: #646464;
  text-decoration: underline;
  transition: .5s;
}

.sidebarDownloads p a:hover {
  color: #ff9500;
}

.categoryBlogList {
  margin-bottom: 45px;
}

.categoryTitle {
  border-bottom: 1px solid #161c37 !important;
  margin-bottom: 20px;
}

.categoryTitle h2 {
  font-size: 1.6rem;
}

.categoryTitle h2 span.blockChainIcon {
  background: url(../images/blog-chain-icon.png) no-repeat scroll 0 3px;
  width: 23px;
  height: 28px;
  display: inline-block;
  margin-left: 5px;
}

.categoryTitle h2 span.stoIcon {
  background: url(../images/sto-icon.png) no-repeat scroll 0 4px;
  width: 27px;
  height: 31px;
  display: inline-block;
  margin-left: 5px;
}

.categoryTitle h2 span.hyperledgerIcon {
  background: url(../images/hyper-ledger-icon.png) no-repeat scroll 0 7px;
  width: 29px;
  height: 34px;
  display: inline-block;
  margin-left: 5px;
}

.categoryTitle a {
  color: #757575;
  transition: .5s;
  text-decoration: none;
}

.categoryTitle a:hover {
  color: #ff9500;
}

.categoryListPostBox {
  background: #fff;
  padding: 15px;
  border-radius: 7px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  margin-bottom: 25px;
}

.postDescriptionSection .dateText {
  font-size: .8rem;
  color: #757575;
  display: block;
  margin-bottom: 5px;
}

.postDescriptionSection h4 {
  font-size: 1.4rem;
  margin-bottom: 12px;
}

.postDescriptionSection h4 a {
  color: #000;
  text-decoration: none;
  transition: .5s;
}

.postDescriptionSection h4 a:hover {
  color: #81c8f6;
}

.postDescriptionSection p {
  color: #757575;
  margin-bottom: 20px;
}

.postAction ul {
  margin: 0;
  padding: 0;
}

.postAction ul li {
  list-style: none;
  display: inline-block;
  margin-right: 20px;
}

.postAction ul li .dropdown-toggle::after {
  display: none;
}

.postAction ul li .dropdown-menu.show {
  padding: 15px;
  min-width: 135px !important;
}

.postAction ul li .dropdown-menu.show li, .postAction ul li .dropdown-menu.show li a {
  display: block;
  text-align: left;
  margin-bottom: 10px;
  float: none !important;
  color: #fff;
  margin-right: 0;
}

.postAction ul li .dropdown-menu.show li a i {
  font-size: 1rem;
  margin-right: 5px;
}

.postAction ul li .dropdown-menu.show li a:hover {
  color: #ffffff;
}

.postAction ul li .dropdown-menu.show li:last-child, .postAction ul li .dropdown-menu.show li:last-child a {
  margin-bottom: 0;
}

.postAction ul li a {
  color: #757575;
  font-size: .8rem;
  text-decoration: none;
  transition: .5s;
  display: flex;
  align-items: center;
}

.postAction ul li a:hover {
  color: #ff9500;
}

.postAction ul li span {
  background: url(../images/postActionIcon.png) no-repeat scroll 0 0;
  display: inline-block;
  margin-left: 5px;
}

.postAction ul li a span.likeIcon {
  width: 16px;
  height: 16px;
  background-position: -53px -10px;
}

.postAction ul li a span.commentIcon {
  width: 16px;
  height: 14px;
  background-position: -45px -53px;
}

.postAction ul li a span.shareIcon {
  width: 15px;
  height: 15px;
  background-position: -10px -53px;
}

.postAction ul li span.dotsIcon {
  width: 23px;
  height: 23px;
  background-position: -10px -10px;
  cursor: pointer;
}

.btnYellowBorder {
  background: #121126;
  border: 1px solid #81c8f6;
  color: #ffffff;
  border-radius: .25rem;
  transition: .5s;
}

.btnYellowBorder:hover {
  background: #81c8f6;
  color: #121126 !important;
}

/*Stacking CSS Starts Here*/
.stackingBanner {
  background: url(../images/stakingbg.jpg);
  padding-top: 90px;
  min-height: 576px;
}

.stackingBanner .customNav {
  background: #ebeff4;
}

.stackingBannerContent h2 {
  font-size: 60px;
  color: var(--themeclr);
  /* text-align: center; */
  font-weight: 600;
}

.stackingBannerContent h4 {
  font-size: 26px;
  color: #ebeff4;
  text-align: center;
  font-weight: 600;
}

.stackingBannerContent .input-group {
  background: #ffffff;
  border-radius: 30px;
}

.stackingBannerContent .input-group .form-control {
  border-radius: 30px;
  border: 0;
  min-height: 45px;
}

.btnYellow {
  background: linear-gradient(90deg, #81c8f6, #81c8f6);
  color: #ffffff !important;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 30px !important;
  box-shadow: none !important;
  transition: .5s;
  padding-left: 30px;
  padding-right: 30px;
}

.btnYellow:hover {
  background: linear-gradient(90deg, #81c8f6, #81c8f6);
  box-shadow: 0 0 5px rgba(3, 10, 91, .9)
}

.stakeHomeBox {
  width: 265px;
  background: #fff;
  border-radius: 20px;
  text-align: center;
  padding: 0 15px 5px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
}

.abtContent .stakeHomeBox h3 {
  font-size: 20px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 15px;
}

.stakeMoreReturn .abtContent h3 span {
  color: #ffb83d;
  font-size: 30px;
}

.stakeHomeBox  img {
  margin-top: -25px;
  width: 50px;
}

.coinTable .stakeInverstmentTable th {
  background: #eef2f7 !important;
  font-size: 16px !important;
  color: #000000 !important;
  border: 0 !important;
}

.coinTable .stakeInverstmentTable td {
  color: #484848 !important;
  font-size: 16px !important;
  border-top: 0 !important;
}

.stakeInverstmentTable td img {
  margin-right: 10px;
}

.stakeInvestorTitle {
  font-size: 30px !important;
  font-weight: 600 !important;
  text-align: center !important;
  color: #03005b !important;
  padding-top: 15px;
  padding-bottom: 15px;
}

.stakeInvestorTitle span {
  display: block !important;
  font-size: 22px !important;
  color: #ffb83d !important;
  font-weight: 700;
}

.stakePlans {
  padding-top: 50px;
  padding-bottom: 50px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ebeff4+0,dce7f2+100 */
  background: rgb(235, 239, 244);
  /* Old browsers */
  background: -moz-linear-gradient(top, rgba(235, 239, 244, 1) 0%, rgba(220, 231, 242, 1) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(235, 239, 244, 1) 0%, rgba(220, 231, 242, 1) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(235, 239, 244, 1) 0%, rgba(220, 231, 242, 1) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ebeff4', endColorstr='#dce7f2', GradientType=0);
  /* IE6-9 */
}

.stakePlans .whyCard {
  width: 100%;
}

.stakePlans .whyCard .whySpace {
  width: 100%;
}

.stakePlans .whyCard:hover {
  background: transparent;
}

.stakePlans .whyCard ul {
  margin-bottom: 25px;
  margin-top: 25px;
}

.stakePlans .whyCard ul li {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.stakePlans .whyCard ul li i {
  font-size: 9px;
  color: #151ea6;
  margin-right: 10px
}

.stakePlans .whyCard button {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.faqStake {
  padding-top: 50px;
  padding-bottom: 50px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ebeff4+0,dce7f2+100 */
  background: rgb(220, 231, 242);
  /* Old browsers */
  background: -moz-linear-gradient(top, rgba(220, 231, 242, 1) 0%, rgba(235, 239, 244, 1) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(220, 231, 242, 1) 0%, rgba(235, 239, 244, 1) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(220, 231, 242, 1) 0%, rgba(235, 239, 244, 1) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ebeff4', endColorstr='#dce7f2', GradientType=0);
  /* IE6-9 */
}

.faqStake .homeAccordian #accordion .card {
  background: #ffffff;
  border-radius: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.faqStake .homeAccordian #accordion .card-header .btn.btn-link.collapsed {
  border-bottom: 0;
  color: #000000;
  font-size: 16px;
  padding-bottom: 0;
}

.faqStake .homeAccordian #accordion .card-body p {
  color: #212529;
}

.faqStake .homeAccordian #accordion .card-header .btn {
  color: #151ea6;
}

.faqStake .homeAccordian #accordion .card-header [data-toggle="collapse"].collapsed .fa::before {
  color: #151ea6;
}

.faqStake .homeAccordian #accordion .card-header [data-toggle="collapse"] .fa::before {
  color: #151ea6;
}

.countableStake {
  padding: 50px 0;
}

.countCard h2 {
  text-align: center;
  font-size: 42px;
  color: #ffffff;
  font-weight: 700;
}

.countCard h2 small {
  display: block;
  font-size: 20px;
  font-weight: 600;
  color: #81c8f6;
  margin-top: 10px;
}

.stakeList,
.launchPadMain {
  background: #eef2f7;
  padding: 115px 0 60px;
}

.stackNav {
  border-bottom: 1px solid #ccd4df;
}

.stackListMain {
  border-bottom: 1px solid #ccd4df;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.stackTitle h2 {
  font-size: 30px;
  color: #03005b;
  font-weight: 600;
  margin-top: 30px;
}

.stackTitle h2 small {
  display: block;
  font-size: 18px;
  color: #424242;
  font-weight: 600;
  margin-top: 5px;
}

.stackNavbar ul {
  display: flex;
}

.stackNavbar ul li {
  margin-right: 50px;
}

.stackNavbar ul li a {
  font-size: 20px;
  font-weight: 600;
  padding-top: 15px;
  padding-bottom: 15px;
  display: block;
  outline: none;
}

.stackNavbar ul li a:hover {
  color: #03005b;
}

.stackListGroup .stakeHomeBox {
  padding-bottom: 30px;
  margin-bottom: 40px;
  box-shadow: 1px 1px 14px 1px rgba(29, 26, 87, 0.15);
  -webkit-box-shadow: 1px 1px 14px 1px rgba(29, 26, 87, 0.15);
  -moz-box-shadow: 1px 1px 14px 1px rgba(29, 26, 87, 0.15);
}

.stackListGroup .stakeHomeBox h3 {
  font-size: 22px;
  color: #03005b;
  font-weight: 600;
  margin: 20px 0;
}

.stackListGroup .stakeHomeBox h3 span {
  font-size: 18px;
  color: #919191;
}

.stackListGroup .stakeHomeBox h6 {
  font-size: 18px;
  color: #919191;
  margin-top: 30px;
  font-weight: 600;
  margin-bottom: 10px;
}

.stackListGroup .stakeHomeBox .btnLandingJoin {
  font-size: 16px;
}

.daysRoundBtnGroup {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.daysRoundBtn {
  color: #03005b;
  width: 36px;
  height: 36px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #03005b;
  border-radius: 50%;
  box-shadow: none !important;
  outline: none !important;
  margin: 0 5px;
  transition: .5s;
}

.daysRoundBtn:hover,
.daysRoundBtn.active {
  background-color: #ffb83d;
  color: #ffffff;
  border-color: #ffb83d;
}

.borderBottomZero {
  border-bottom: 0 !important;
}

.flexTitleGroup {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 25px;
}

.flexTitleGroup h5 {
  font-size: 16px;
  color: #03005b;
  font-weight: 600;
}

.flexTitleGroup h5 span {
  display: block;
  font-size: 16px;
  color: #919191;
}

.stackNav {
  border-bottom: 1px solid #ccd4df;
  position: sticky;
  top: 90px;
  z-index: 9;
  background: #eef2f7;
}

.stakeHomeBox {
  position: relative;
}

.stakeHomeBoxHover {
  padding-bottom: 30px;
  box-shadow: 1px 1px 14px 1px rgba(29, 26, 87, 0.15);
  -webkit-box-shadow: 1px 1px 14px 1px rgba(29, 26, 87, 0.15);
  -moz-box-shadow: 1px 1px 14px 1px rgba(29, 26, 87, 0.15);
  border-radius: 20px;
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background: #ffffff;
  z-index: 9;
}

.stakeHomeBox:hover .stakeHomeBoxHover {
  display: block;
  transform: scale(1.1);
}

.stakeHomeBox .input-group {
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.stakeHomeBox .input-group label {
  margin-bottom: 0;
  color: #919191;
  font-weight: 600;
}

.stakeHomeBox .form-group label {
  color: #919191;
}

.stakeHomeBox .form-control {
  width: 50%;
  max-width: 150px;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 2px solid #ffb83d;
  font-size: 18px;
  color: #000000;
  text-align: center;
}

.stakeHomeBox .input-group.stackDays {
  margin-top: -10px;
  margin-bottom: 25px;
}

.stakeHomeBox .stackDays .form-control {
  max-width: 75px;
}

.stackListGroup .stakeHomeBox .btnLandingJoin small {
  font-weight: 600;
}

.input-group-lock-amount {
  display: flex;
  align-items: center;
  border: 1px solid #ff8f00;
  padding: 0px 15px;
  border-radius: 50px;
  margin-bottom: 20px;
  background: #ffffff;
}

.input-group-lock-amount .form-control {
  border: 0 !important;
  border-right: 1px solid #ff8f00 !important;
  border-radius: 0 !important;
  margin-right: 15px;
  padding-top: 30px;
  padding-bottom: 30px;
  background: none !important;
}

.btnStackGradiant {
  margin-right: 15px;
  box-shadow: none !important;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ff8600+0,ffa100+100 */
  background: rgb(255, 134, 0);
  /* Old browsers */
  background: -moz-linear-gradient(left, rgba(255, 134, 0, 1) 0%, rgba(255, 161, 0, 1) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(255, 134, 0, 1) 0%, rgba(255, 161, 0, 1) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(255, 134, 0, 1) 0%, rgba(255, 161, 0, 1) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff8600', endColorstr='#ffa100', GradientType=1);
  /* IE6-9 */
  color: #ffffff;
  border-radius: 50px;
  padding-left: 25px;
  padding-right: 25px;
  transition: .5s;
}

.btnStackGradiant:hover {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ff8600+0,ffa100+100 */
  background: rgb(255, 134, 0);
  /* Old browsers */
  background: -moz-linear-gradient(left, rgba(255, 161, 0, 1) 0%, rgba(255, 134, 0) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(255, 161, 0, 1) 0%, rgba(255, 134, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(255, 161, 0, 1) 0%, rgba(255, 134, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff8600', endColorstr='#ffa100', GradientType=1);
  /* IE6-9 */
  color: #ffffff;
}

.bigButton .btnStackGradiant {
  font-size: 18px;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 500;
}

.stackPopup .checkbox .cr {
  background: #f0f0f0;
  border-color: #ff8f00;
}

.stackPopup .checkbox label {
  color: #8e8e8e;
}

.stackPopup ul {
  margin: 0;
  padding: 0;
}

.stackPopup ul li {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.stackPopup ul li.greenText {
  color: #02b323 !important;
}

.stackPopup ul li span {
  color: #bcbcbc;
  font-size: 16px;
  width: 45%;
  margin-right: 15px;
}

.stackPopup ul li label {
  font-weight: 600;
  font-size: 20px;
  color: #1e2f55;
}

.stackPopup h6 {
  text-align: center;
  font-size: 22px;
}

.stackPopup h3 {
  text-align: center;
  font-size: 30px;
  color: #81c8f6;
  margin-bottom: 20px;
}

.input-group-lock-amount .inputLabel {
  color: #919191;
}

.intl-tel-input .country-list {
  color: #000 !important;
}

.react-phone-number-input__input {
  background-color: #121a23 !important;
  border: 1px solid #374454 !important;
  color: #fff !important;
  height: 45px !important;
}

.valuesTable th {
  border-bottom: 0;
  background: rgb(63, 64, 48);
  background: linear-gradient(0deg, rgba(63, 64, 48, 1) 0%, rgba(68, 69, 54, 1) 100%);
  color: #d7d7d7;
  font-size: 18px;
  font-weight: 600;
  padding: 15px 15px;
  border: 0 !important;
}

.valuesTable th, .valuesTable td {
  border: 0;
}

.valuesTable td {
  background-color: #2d312e;
  /* border-bottom: 1px solid #81c8f6; */
  border-top: 0;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  padding: 15px 15px;
  transition: .5s;
}

.whiteBgShadow {
  background: #151414;
  border-radius: 5px;
  margin-top: 30px;
  padding: 20px;
  height: 100%;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
}

ul.mainResponses li {
  border-top: 1px solid #e3e3e3;
  padding-top: 20px;
}

.responseUserImg img {
  border-radius: 50%;
  max-width: 53px;
  max-height: 53px;
}

.responseUserImg p small {
  display: block;
  color: #afafaf;
  font-size: .75rem;
  font-weight: 400;
  text-transform: none;
}

.walletKey .address {
  background-color: #03081f;
  border: 1px solid #81c8f6;
  color: #fff !important;
  color: #fff;
  font-size: 14px;
  text-align: left;
  padding: 10px 15px;
  border-radius: 10px;
  height: 43px;
}
.lightmodal .walletKey .address{
  color: #000 !important;
}
.depositeQr {
  text-align: center !important;
}

/* 6d1755a7 */
.closedPandLDataStatus {
  overflow: auto;
}


nav.wallet-side-menu.more_menus a {
  color: #fff;
}
.darktheme nav.wallet-side-menu.more_menus a.active {
  background-color: var(--themeclr) !important;
}
.lighttheme .wallet-side-menu .nav-item.active {
  background-color: #fff !important;
  color: var(--themeclr);
}
.lighttheme .wallet-side-menu{
  background-color: var(--themeclr);
}
.lighttheme .bg_strip_none{
  background-color: #f2f2f2;
}
.darktheme .bg_strip_none{
  background-color: #111111;
}
.lighttheme .assetHistoryTableTop label{
  color: #000;
}
.darktheme .assetHistoryTableTop label{
  color: #fff;
}
.lighttheme .assetsTable .assetTitle{
color: var(--themeclr) !important
}
.darktheme .wallet-tab input,.darktheme .wallet-tab select,.lighttheme .wallet-tab select {
  background: #000 !important;
  color: #fff !important;
  border-color: var(--themeclr) !important;
  height: 52px;
}
.lighttheme .wallet-tab input {
border-color: var(--themeclr) !important;
  height: 52px;
}
.lighttheme .wallet-tab input {
  background: #fff !important;
  color: #000 !important;
}
.lighttheme .react-tel-input,.lighttheme .react-tel-input .selected-flag{
  background-color: #fff !important;
  border-radius: 5px;
}

.darktheme .assetsTable tr{
  background-color: #000 !important;
}
.wallet-container {
  display: flex;
  padding-top: 57px;
  /* background-color: #1e2026; */
  min-height: 100vh;
  --sideMenuWidth: 200px;
}
.bg_strip_none
{
  background: #060b27;
}
.bg_strip_none .customNavTrade
{
  /* background: #060b27 !important; */
}

.wallet-side-menu {
  position: sticky;
  left: 0;
  top: 57px;
  width: var(--sideMenuWidth);
  min-width: var(--sideMenuWidth);
  max-width: var(--sideMenuWidth);
  max-height: calc(100vh - 265px);
  flex-grow: 0;
  overflow-y: auto;
  min-height: calc(100vh - 265px);
  /* background-color: #0c1a4b; */
  min-height: 100vh;
  background-color: #000;
}

.wallet-side-menu .nav-tabs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* height: calc(100vh - 120px); */
  border: 0;
}

.wallet-side-menu .nav-item {
  width: 100%;
  padding: 1.25rem;
  user-select: none;
}

.wallet-side-menu .nav-item:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, .2);
}

.wallet-tab {
  flex-grow: 1;
  /* margin-left: var(--sideMenuWidth); */
  margin-left: 0;
  padding-right: 1rem;
  width: calc(100% - var(--sideMenuWidth) - 1rem);
}

/* Launchpad Css */
.whiteBoxLaunchpad {
    background: #fff;
    padding: 30px;
    border-radius: 8px;
  box-shadow: 0 0 7px 3px rgb(0 0 0 / 10%);
}
.launchpadCoinName {
    display: flex;
  align-items: center;
}
.launchpadCoinName img {
    margin-right: 15px;
}
.launchpadCoinName h3 {
  font-size: 36px;
  font-weight: 400;
}
.launchpadCoinName h3 small {
  display: block;
  font-size: 16px;
  color: #7d7d7d;
}
.launchPadCoinDetails h4 {
    font-size: 18px;
    color: #81c8f6;
  border: 2px solid #81c8f6;
    padding: 10px 30px;
    border-radius: 50px;
}
.launchPadCoinDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
  margin-bottom: 25px;
}
.whiteShadowBox {
    box-shadow: 0 0 4px 10px rgb(231 237 243 / 50%);
    border-radius: 2px;
    margin-bottom: 20px;
    padding: 30px;
}
.projectIntroduction h3 {
    font-size: 18px;
    border-top: 1px solid #e0e6ee;
    border-bottom: 1px solid #e0e6ee;
    padding: 10px 0;
}
.projectIntroduction li {
  color: #000;
  font-size: 14px;
  margin: 10px 0;
}
.projectIntroduction li span {
    font-size: 14px;
    color: #7d7d7d;
  width: 40%;
    display: inline-block;
}
.projectIntroduction p {
  color: #7d7d7d;
  font-size: 14px;
}
.whiteShadowBox .form-group label {
  color: #000;
  font-size: 16px;
}
.choose_coin_list {
  padding: 0;
  margin: 0;
  display: flex;
  margin-bottom: 10px;
}
.choose_coin_list li {
    margin: 0 25px 0 0;
}
.choose_coin_list li a {
    color: #7d7d7d;
  font-size: 16px;
  font-weight: 700;
  transition: 0.4s all;
}
.choose_coin_list li a.active {
  color: #fff;
    border-bottom: 2px solid #81c8f6;
}
.whiteShadowBox input.form-control {
    border: 1px solid #e1e1e1;
}
.whiteShadowBox span.input-group-text {
    background-color: transparent;
    border: 1px solid #e1e1e1;
}
.btnGroupPercent {
    display: flex;
    justify-content: space-between;
}
.btnPercent {
  background: linear-gradient(90deg,#81c8f6,#81c8f6);
    color: #ffffff !important;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 30px;
    padding: 8px 30px;
    box-shadow: none !important;
    transition: .4s all;
}
.btnPercent:hover {
    background: linear-gradient(90deg,#030060,#000176);
    box-shadow: 0 0 5px rgb(3 10 91 / 90%);
}
.whiteShadowBox .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #fec003;
    background-color: #febf04;
}
.btnBuyKSC {
  background: linear-gradient(90deg,#81c8f6,#81c8f6);
    color: #ffffff !important;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 30px;
    padding: 8px 30px;
    box-shadow: none !important;
    transition: .4s all;
  margin-right: 15px;
}
.btnBuyKSC:hover {
    background: linear-gradient(90deg,#030060,#000176);
    box-shadow: 0 0 5px rgb(3 10 91 / 90%);
}
.whiteShadowBox h3 {
    font-size: 18px;
}
.whiteShadowBox .table thead th {
    border-bottom: 0;
}
.whiteShadowBox .table tbody {
  border-top: 0;
}
.project_docs a {
  font-size: 16px;
  color: #7d7d7d;
  margin: 0 40px 0 0;
    display: inline-block;
}
.project_docs a.active {
  color: var(--themeclr);
}
.socialMediaCoinDetails ul {
  display: flex;
}
.socialMediaCoinDetails ul li {
  margin: 0 20px 0 0;
}
.socialMediaCoinDetails ul li a {
  color: #000;
  border: 1px solid #000;
  background-color: transparent;
  width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 18px;
  transition: 0.4s all;
}
.socialMediaCoinDetails ul li a:hover {
  color: #fec003;
  border: 1px solid #fec003;
}
.socialMediaCoinDetails h3 {
  font-size: 18px;
    border-top: 1px solid #e0e6ee;
    border-bottom: 1px solid #e0e6ee;
    padding: 10px 0;
  margin-top: 30px;
}
.cdTeamDetail h3 {
  font-size: 18px;
    border-top: 1px solid #e0e6ee;
    border-bottom: 1px solid #e0e6ee;
    padding: 10px 0;
  margin: 30px 0;
}
.cdTeamDetail ul {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
}
.cdTeamProfilePic img {
  border-radius: 50%;
  margin-right: 10px;
}
.cdTeamDetail ul li {
    display: flex;
}
.cdTeamProfileDetail h6 {
    font-size: 14px;
    margin: 0;
}
.cdTeamProfileDetail p {
  color: #7d7d7d;
  font-size: 14px;
  margin: 0;
}
.cdTeamProfileDetail a {
  color: #000;
  border: 1px solid #000;
  border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3px;
}
.cdTeamProfileDetail a:hover {
  color: #fec003;
  border: 1px solid #fec003;
}
.launchpad_bg {
  background: url("../images/launch_banner_bg.png");
    padding-top: 57px;
    min-height: 576px;
}
h2.launch_title {
  color: #000;
    font-size: 30px;
  font-weight: 700;
  position: relative;
}
h2.launch_title span {
  font-size: 50px;
  display: block;
  color: var(--themeclr);
}
.launch_title_center {
  text-align: center;
}
.launchpad_abt p {
    color: #8b8b8b;
    font-size: 16px;
}
.slick_vertical_nav h3 {
    color: #03005b;
    font-size: 18px;
  padding: 8px 20px;
    border-radius: 5px;
  position: relative;
  margin-right: 30px;
}
.lighttheme .slick_vertical_nav h3::before{
  color: #000;
}
.slick_vertical_nav h3::before {
  content: "\f0d9";
  position: absolute;
    right: -32px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: #fff;
}
.slick_vertical_nav .slick-slide.slick-active.slick-current h3 {
    background-color: var(--themeclr);
    display: inline-block;
}
.slick_vertical_nav .slick-slide.slick-active.slick-current h3::before {
  color: var(--themeclr);
}
.slick_vertical_nav {
    text-align: right;
    border-right: 1px solid var(--themeclr);
    margin-top: 50px;
}
.slick_vertical_mainnav {
    margin-top: 50px;
}
.slick_vertical_nav .slick-track {
    height: auto !important;
}
ol.slick_slider_list li {
    color: #575757;
    font-size: 16px;
    margin: 0 0 15px;
}
.bitAbout h4 {
  font-size: 22px;
  color: #000;
  font-weight: 700;
  margin: 0;
}
.launch_program {
    padding: 50px 0;
    background-color: #fff;
}
.box_panel {
    box-shadow: 0 0 10px 10px rgb(0 0 0 / 10%);
    background: #fff;
    border-radius: 10px;
    width: 300px;
  margin: 50px auto;
    padding: 20px 35px;
}
.box_panel_content h2 {
    font-size: 20px;
    color: #5e5e5e;
    margin: 15px 0 0;
    font-weight: 700;
  min-height: 56px;
}
.box_panel_content p {
  font-size: 16px;
  color: #9e9e9e;
}
.launch_program .slick-prev:before, 
.launch_program .slick-next:before {
  display: none;
}
.launch_program .slick-prev {
  content: '';
  background: url(../images/launch_program_prev.png);
  width: 30px;
  height: 55px;
  filter: contrast(0) brightness(1.5);
  transition: 0.4s all;
}
.launch_program .slick-prev:hover,
.launch_program .slick-prev:focus {
  filter: invert(0.7);
  background: url(../images/launch_program_prev.png);
}
.launch_program .slick-next {
  content: '';
  background: url(../images/launch_program_next.png);
  width: 30px;
  height: 55px;
  filter: contrast(0) brightness(1.5);
  transition: 0.4s all;
}
.launch_program .slick-next:hover,
.launch_program .slick-next:focus {
  filter: invert(0.7);
  background: url(../images/launch_program_next.png);
}
.launch_card h2 {
  color: var(--themeclr);
  font-size: 36px;
    font-weight: 600;
}
.launch_card p {
  color: #000;
}
.darktheme .launchpad_contact {
    background-color: #000;
}
.lighttheme .launchpad_contact{
  background-color: #fff;
}
.lighttheme .launchpad_contact h2.head {
  color: #000;
}
.darktheme .launchpad_contact h2.head {
  color: #fff;
}
.lighttheme .countableStake, .lighttheme .bitAbout{
  background: #ededed;
}
 .darktheme .launch_program {
  background: #000000;
}
 .darktheme .countableStake  {
  background: #000;
}
.darktheme section.bitAbout{
  background: #0a0a0a;
}
.darktheme h2.launch_title{
  color: #fff;
}
.lighttheme .bitAbout h4{
  color: #000;
}
.darktheme .box_panel_content h2{
  color: #fff;
}
.darktheme .countableStake .themebtn{
  background-color: #000;
  border-color: #000;
}
.darktheme .launchpad_token_wrapper {
  background: #0a0a0a;
}
.darktheme .countableStake h2{
  color: #fff;
}
.darktheme .slick-list h3 {
  color: #fff;
}
.darktheme ol.slick_slider_list span {
  color: #fff;
}
.slick_slider_list li{
  list-style-type: none;
}
.bitAbout::after,.bitAbout::before{
  display: none;
}
.darktheme .countableStake{
  background-color: var(--themeclr);
}
.darktheme .slick-slide .box_panel {
  background: #1d1d1d;
}
.bitAbout h4{
  color: #fff;
}
.launchpad_contact h2 {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 40px;
}
.launchpad_contact_details {
    display: flex;
    align-items: center;
}
.launchpad_contact_details h2 {
  font-size: 20px;
  color: #000;
  background-color: #81c8f6;
  margin: 0;
  text-align: center;
    padding: 5px;
}
.launchpad_contact_details > div {
    margin-right: 15px;
}
.launchpad_contact_details p {
    margin: 5px 0;
}
.launchpad_contact_details a {
    margin: 0 5px;
}
.launchpad_form h2 {
  color: #fff;
  margin: 0 0 15px;
}
.launchpad_form ul {
    display: flex;
    align-items: center;
}
.launchpad_form ul li {
  margin: 0 10px ;
}
.launchpad_form ul li a {
  font-size: 20px;
  background-color: #acacac;
}
.launchpad_form ul li a {
    font-size: 20px;
    background-color: #fff;
  border: 2px solid #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--themeclr);
  transition: 0.4s all;
}
.launchpad_form ul li a:hover {
  background-color: transparent;
  border: 2px solid #81c8f6;
  color: #81c8f6;
}
.launchpad_form ul li {
    margin: 0 25px 30px 0;
}
.launchpadtoken_bg {
  background-image: url(../images/banner.png);
  background-size: 100%;
  background-color: #000;
  background-position: bottom 0px left 0px;
  /* min-height: 600px; */
  min-height: calc(100vh);
  display: flex;
  align-items: center;
  background-repeat: no-repeat;

  /* background: url(../images/launchpad_token_bg.png); */
  padding-top: 90px;
}
.launchpadtoken_bg p {
  color: #fff;
  font-size: 18px;
}
.launchpad_token_single {
    background: #fff;
    border: 1px solid #ebeff4;
  border-radius: 10px;
  box-shadow: 0 0 10px 8px rgb(241 247 253 / 80%);
  margin-bottom: 30px;
}
.darktheme .launchpad_token_single {
  background: #000;
  border: 1px solid #0c0c0c;
  box-shadow: 0 0 10px 8px rgba(31, 31, 31, 0.8);
}
.darktheme span.timer_time{
  color: #fff;
}
.darktheme .launchpad_token_single p, .darktheme .launchpad_token_single h4{
  color: #fff;
}
.launchpad_token_single img {
  width: 100%;
  border-radius: 10px 10px 0 0;
}
.launchpad_token_single p {
    padding: 0 15px;
    margin: 0 0 10px;
  font-size: 14px;
  color: #000;
}
.launchpad_token_single h4 {
  font-size: 18px;
  color: #000;
  margin: 15px 0 5px;
}
.launchpad_token_single .btnLandingJoin {
    font-size: 14px;
    padding: 7px 30px;
    margin-bottom: 20px;
}
.launchpad_token_single > div p:first-child {
  color: #7d7d7d;
}
.launchpad_token_single h6 {
  color: #7d7d7d;
  font-size: 14px;
}
.launchpad_token_wrapper {
    background: #fff;
    border-radius: 10px;
  box-shadow: 0 0 10px 5px rgb(0 0 0 / 10%);
  padding: 20px 0 60px;
  margin-top: -60px;
}
.launchpad_token_wrapper ul {
    display: flex;
    padding-left: 30px;
  margin: 0 0 30px;
  border-bottom: 1px solid #eee;
    padding-bottom: 20px;
}
.launchpad_token_wrapper ul li a {
    font-size: 14px;
    padding: 10px 30px;
    margin-right: 30px;
}
.launchpad_token_wrapper ul li a span {
    /* font-size: 18px; */
}
.launchpad_token_wrapper ul li a.active {
  background: linear-gradient(90deg,#030060,#000176);
}
.darktheme .launchpad_token_wrapper ul{
  border-bottom-color: #2f2f2f;
}
.launchpad_token_panel {
    padding: 0 30px;
}
.launchpad_token_section {
  background: #000;
    padding: 0 0 60px;
}
.timer_panel {
  text-align: center;
  display: flex;
    justify-content: center;
}
span.timer_label {
    display: block;
  font-size: 14px;
  color: #7d7d7d;
}
span.timer_time {
    font-size: 20px;
    color: #000;
}
.timer_panel > span {
    margin: 5px;
}
span.timer_dots {
    margin: 0 3px;
    line-height: 38px;
    color: #333333;
}
.launchpad_btn_center {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -20px;
}
.launchpad_btn_center .btnLandingJoin {
  padding: 10px 30px;
  font-size: 14px;
}

@media screen and (max-width: 991px) {
  .login_btn{
    margin-bottom: 10px;
  }
  #navbarResponsive .navbar-nav li:last-child button {
    padding-left: 0 !important;
  }
  .bitAbout {
    padding-top: 20px;
    padding-bottom: 20px;
}
  .menu_default_show .nav-link
  {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .customNav .nav-item .menu_default_show .nav-link:hover,.menu_default_show .nav-link:hover
  {
    background: transparent !important;
    color:#81c8f6 !important;
  }
  .customNav .nav-item .nav-link:hover
{
  color: #81c8f6  !important;
}
  .menu_default_show
  {
    display: block !important;
    position: relative !important;
    opacity: 1 !important;
    padding-top: 0px !important;
    background: transparent !important;
    color: #ffffff !important;
    font-size: 13px !important;
    font-weight: 500 !important;
  }
  .customNav .nav-item .menu_default_show .nav-link
  {
    color: #ffffff !important;
    padding: 10px 0 !important;
    font-size: 13px !important;
    font-weight: 500 !important;
  }
  .menu_default_hide
  {
    display: none !important;
  }
  .collapse_menu

  {
    position: fixed;
  max-width: 260px;
  min-width: 260px !important;
  right: 0;
  top: 60px;
  background: #1b2d50;
  padding: 20px;
  min-height: calc(100vh - 50px);
  overflow-y: auto;
  max-height: calc(100vh - 50px);
  }
  .menu_right li.nav-item.ml-2
  {
    margin-left: 0px !important;
  }
  .launch_program .slick-prev {
    left: 0;
  }
  .launch_program .slick-next {
    right: 0;
  }
  .btnBuyKSC {
    margin-bottom: 15px;
  }
  .btnPercent {
    font-size: 12px;
      padding: 6px 18px;
  }
}
@media screen and (max-width: 767px) {
  .wallet-side-menu{
    padding-left: 0 !important;
  }
  .center_div_align
  {
    justify-content: center;
  }
  .mainFooter 
  {
    background-position: top 0px left 0px !important;
    /* padding-top: 220px !important; */
  }
  .copy_right_section p
  {
    margin-top: 20px !important;
  }
  .coinTable
  {
    padding-top: 0px !important;
  }
  .formBox.launchpad_form {
    margin-top: 30px;
  }
  .stackingBannerContent h2 {
    font-size: 30px;
  }
  .stackingBannerContent h4 {
    font-size: 20px;
  }
  h2.launch_title span {
    font-size: 32px;
  }
  h2.launch_title {
    font-size: 24px;
  }
  .launch_card h2 {
    font-size: 24px;
  }
  .launchpad_contact h2 {
    font-size: 28px;
  }
  .launchPadCoinDetails {
    display: block;
  }
  .cdTeamDetail ul {
    flex-wrap: wrap;
  }
  .cdTeamDetail ul li {
    margin: 15px 0 0;
  }
  .social h4 {
    text-align: center;
    display: block;
  }
  .launchPadCoinDetails h4 {
    font-size: 14px;    
    line-height: 22px;
    text-align: center;
  }
  .whyBisanse .text-right {
    text-align: left !important;
  }
}
@media screen and (max-width: 575px) {
  .launchpad_token_panel
  {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .launchpad_token_panel .d-flex
  {
    display: block !important;
  }
  .bitAbout {
    padding-top: 39px !important;
    padding-bottom: 30px !important;
  }
  .form_contct iframe {
    transform: scale(.60);
    position: absolute;
    left: 0;
}
  .social {
    flex-direction: column;
  }
  .footer_bottom_right ul {
    flex-wrap: wrap;
  }
  .launchpadtoken_bg .stackingBannerContent img {
    margin: 30px auto 50px;
  }
  .launchpad_token_wrapper ul {
    display: block;
    padding-right: 30px;
  }
  .launchpad_token_wrapper ul li a {
    margin-right: 0;
    margin-bottom: 15px;
    width: 100%;
  }
  .projectIntroduction li span {
    width: 100%;
    display: block;
  }
  .btnGroupPercent {
    flex-wrap: wrap;
  }
  .btnPercent {
    margin-bottom: 10px;
  }
  .socialMediaCoinDetails ul {
    flex-wrap: wrap;
  }
  .socialMediaCoinDetails ul li {
    margin-top: 15px;
  }
}
@media screen and (max-width: 768px) {
  .borLeftRight{
    border: 0;
  }
  .wallet-side-menu
  {
    min-height: unset !important;
  }
  .wallet-side-menu.more_menus .nav-tabs {
    display: block;
    justify-content: center;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}
.wallet-side-menu.more_menus .nav-tabs .nav-item {
  display: inline-block;
}

  .wallet-container {
    flex-direction: column;
    padding-top: 90px;
  }

  .wallet-side-menu {
    position: sticky;
    top: unset;
    left: unset;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: unset;
    padding: 0 0.5rem;
  }

  .wallet-side-menu .nav-tabs {
    flex-direction: row;
    height: unset;
  }

  .wallet-side-menu .nav-item {
    width: unset;
  }

  .wallet-side-menu .nav-tabs {
    display: flex;
    justify-content: center;
  }

  .wallet-tab {
    width: 100%;
    margin-left: 0;
    padding: 0 0.5rem;
  }


}

.lighttheme .tableHead.tableHeadBlock p{
  color: #000;
}

/* Launchpad Css */
.whiteBoxLaunchpad {
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 7px 3px rgb(0 0 0 / 10%);
}

.launchpadCoinName {
  display: flex;
  align-items: center;
}

.launchpadCoinName img {
  margin-right: 15px;
}

.launchpadCoinName h3 {
  font-size: 36px;
  font-weight: 400;
}

.launchpadCoinName h3 small {
  display: block;
  font-size: 16px;
  color: #7d7d7d;
}

.launchPadCoinDetails h4 {
  font-size: 18px;
  color: #03005b;
  border: 2px solid #81c8f6;
  padding: 10px 30px;
  border-radius: 50px;
}

.launchPadCoinDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.whiteShadowBox {
  box-shadow: 0 0 4px 10px rgb(231 237 243 / 50%);
  border-radius: 2px;
  margin-bottom: 20px;
  padding: 30px;
}

.projectIntroduction h3 {
  font-size: 18px;
  border-top: 1px solid #e0e6ee;
  border-bottom: 1px solid #e0e6ee;
  padding: 10px 0;
}

.projectIntroduction li {
  color: #000;
  font-size: 14px;
  margin: 10px 0;
}

.projectIntroduction li span {
  font-size: 14px;
  color: #7d7d7d;
  width: 40%;
  display: inline-block;
}

.projectIntroduction p {
  color: #7d7d7d;
  font-size: 14px;
}

.whiteShadowBox .form-group label {
  color: #000;
  font-size: 16px;
}

.choose_coin_list {
  padding: 0;
  margin: 0;
  display: flex;
  margin-bottom: 10px;
}

.choose_coin_list li {
  margin: 0 25px 0 0;
}

.choose_coin_list li a {
  color: #7d7d7d;
  font-size: 16px;
  font-weight: 700;
  transition: 0.4s all;
}

.choose_coin_list li a.active {
  color: #fff;
  border-bottom: 2px solid #81c8f6;
}

.whiteShadowBox input.form-control {
  border: 1px solid #e1e1e1;
}

.whiteShadowBox span.input-group-text {
  background-color: transparent;
  border: 1px solid #e1e1e1;
}

.btnGroupPercent {
  display: flex;
  justify-content: space-between;
}

.btnPercent {
  background: linear-gradient(90deg, #81c8f6, #81c8f6);
  color: #ffffff !important;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 30px;
  padding: 8px 30px;
  box-shadow: none !important;
  transition: .4s all;
}

.btnPercent:hover {
  background: linear-gradient(90deg, #030060, #000176);
  box-shadow: 0 0 5px rgb(3 10 91 / 90%);
}

.whiteShadowBox .custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #fec003;
  background-color: #febf04;
}

.btnBuyKSC {
  background: linear-gradient(90deg, #81c8f6, #81c8f6);
  color: #ffffff !important;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 30px;
  padding: 8px 30px;
  box-shadow: none !important;
  transition: .4s all;
  margin-right: 15px;
}

.btnBuyKSC:hover {
  background: linear-gradient(90deg, #030060, #000176);
  box-shadow: 0 0 5px rgb(3 10 91 / 90%);
}

.whiteShadowBox h3 {
  font-size: 18px;
}

.whiteShadowBox .table thead th {
  border-bottom: 0;
}

.whiteShadowBox .table tbody {
  border-top: 0;
}

.project_docs a {
  font-size: 16px;
  color: #7d7d7d;
  margin: 0 40px 0 0;
  display: inline-block;
}

.project_docs a.active {
  color: var(--themeclr);
}
.darktheme .project_docs a.active {
  color:#81c8f6 !important
}

.socialMediaCoinDetails ul {
  display: flex;
}

.socialMediaCoinDetails ul li {
  margin: 0 20px 0 0;
}

.socialMediaCoinDetails ul li a {
  color: #000;
  border: 1px solid #000;
  background-color: transparent;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 18px;
  transition: 0.4s all;
}

.socialMediaCoinDetails ul li a:hover {
  color: #fec003;
  border: 1px solid #fec003;
}

.socialMediaCoinDetails h3 {
  font-size: 18px;
  border-top: 1px solid #e0e6ee;
  border-bottom: 1px solid #e0e6ee;
  padding: 10px 0;
  margin-top: 30px;
}

.cdTeamDetail h3 {
  font-size: 18px;
  border-top: 1px solid #e0e6ee;
  border-bottom: 1px solid #e0e6ee;
  padding: 10px 0;
  margin: 30px 0;
}

.cdTeamDetail ul {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

.cdTeamProfilePic img {
  border-radius: 50%;
  margin-right: 10px;
}

.cdTeamDetail ul li {
  display: flex;
}

.cdTeamProfileDetail h6 {
  font-size: 14px;
  margin: 0;
}

.cdTeamProfileDetail p {
  color: #7d7d7d;
  font-size: 14px;
  margin: 0;
}

.cdTeamProfileDetail a {
  color: #000;
  border: 1px solid #000;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
}

.cdTeamProfileDetail a:hover {
  color: #fec003;
  border: 1px solid #fec003;
}

.launchpad_bg {
  background: url("../images/launch_banner_bg.png") no-repeat scroll center center;
  padding-top: 57px;
  min-height: 576px;
  background-size: cover;
}
.banner_slider {
  background-color: #000000;
  padding: 25px 50px;
  border-radius: 10px;
  margin-left: -40px;
  width: 300px;
}
.banner_slider h2 {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
}
.banner_slider a {
  color: #81c8f6;
  font-size: 14px;
}
.banner_slider_panel {
  display: flex;
  align-items: center;
}
.homeBanner .slick-dots {
  bottom: -10px;
}
.homeBanner .slick-dots li button:before {
  font-size: 10px;
}
.homeBanner .slick-dots li button:before {
  color: #fff;
  opacity: 0.8;
}
.abtImg {
  background: url("../images/app_page.png");
  height: 454px;
  /* width: 821px; */
  margin-top: -114px;
  background-size: cover;
}

.partners_section {
  padding: 50px 0;
  background: url("../images/community_banner.png");
  text-align: center;
}
.partners_section h3 {
  font-size: 20px;
  color: #72738c;
  font-weight: 400;
}
.white_btn {
  background: transparent;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  transition: 0.2s all;
}
.white_btn i {
  margin-right: 5px;
}
.white_btn:hover {
  color: #121126;
  background: #fff;
}
.footer_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #81c8f6;
}
ul.community_social_icons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
ul.community_social_icons li {
  margin: 10px;
}
ul.community_social_icons li a {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  color: #fff;
  font-size: 24px;
  background: transparent;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s all;
}
ul.community_social_icons li a:hover {
  background: #81c8f6;
  border: 1px solid #81c8f6;
  color: #121126;
}
.input_grp {
  position: relative;
}
/* .append_btn {
  position: absolute;
} */
.footer_top_right h3 {
  font-size: 20px;
  color: #fff;
}
input.newletter_inp {
  padding: 8px 8px 8px 15px;
  outline: none !important;
  border-radius: 10px 0  0 10px;
  border: 1px solid #fff;
}
button.append_btn {
  background-color: #81c8f6;
  border: 1px solid #81c8f6;
  color: #090909;
  font-size: 16px;
  padding: 8px 20px;
  border-radius: 0 10px 10px 0;  
}
.footer_top_left h4 {
  font-size: 22px;
  color: #81c8f6;
  margin: 0;
}
.footer_bottom_left > div div a {
  display: block;
}
.footer_bottom_left > div div {
  margin-right: 50px;
}
.footer_bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #1c2a4d;
}
.footer_bottom_left h4 {
  color: #fff;
  font-size: 22px;
  margin: 0;
}
.footer_bottom_left a {
  color: #fff;
}
.copy_right_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
}
.copy_right_section p {
  margin: 0;
  color: #81c8f6;
  font-size: 13px;
}
.term_list {
  display: flex;
}
.term_list li {
  margin-right: 20px;
}
.term_list li a {
  color: #81c8f6;
  font-size: 13px;
}
.term_list li a:hover {
  color: #fff;
}
button.auth_btn {
  background-color: #81c8f6;
  border: 1px solid #000000;
  color: #fff;
  font-size: 16px;
  margin-right: 20px;
  padding: 6px 25px;
  transition: 0.2s all;
}
button.auth_btn:hover {
  color: #81c8f6;
  background-color: #000000;
}
button.auth_btn a {
  color: #fff;
}
button.auth_btn:hover a {
  color: #81c8f6;
}
section.headerCrediantials.homeBanner {
  min-height: 50px;
}
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;

}
#nav-positions .table-responsive,.lighttheme, .darktheme, html, body,
 .tradeFulltabbedTable.tradeFulltabbedTable_h .nav,.more_menus,.table-responsive{
  scrollbar-width: thin;
  scrollbar-color: #5095d7 #dcdcdc;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
*::-webkit-scrollbar-thumb {
  background-color: #5095d7;
}
*::-webkit-scrollbar-track {
  background-color: #dcdcdc;
}
.getstarted_mobile {
  display: none;
}
.menu_right_after_login {
  align-items: center;
}
.customNav .menu_right_after_login .nav-item .nav-link {
  color: #fff;
  font-weight: 500;
}

/* Copy Trading Starts */
.inner_heading_wrapper {
  /* background: #13161b url("../images/inner_bg.png") no-repeat center ; */
  min-height: 250px !important;
  display: flex;
  align-items: center;
  margin-top: 59px;
}
button.inner_primary_btn {
  background-image: linear-gradient(#28c289, #4bbb87);
  border: 2px solid #35c088;
  font-size: 14px;
  font-weight: 400;
  padding: 5px 25px;
  color: #fff;
  transition: 0.2s all;
  border-radius: 5px;
}
button.inner_primary_btn:hover {
  color: #35c088;
  border: 2px solid #35c088;
  background-image: none;
}
button.inner_primary_btn a {
  color: #fff;
}
button.inner_primary_btn:hover a {
  color: #35c088;
}
.inner_heading_wrapper h2 {
  color: #81c8f6;
  font-size: 26px;
  font-weight: 700;
  margin: 0;
}
.inner_heading_wrapper h4 {
  color: #e0e0e0;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}
.inner_heading_wrapper p {
  color: #b4b4b4;
  font-size: 14px;
  font-weight: 700;
}
.inner_content_wrapper {
  margin-bottom: 30px;
}
.copy_trading_panel {
  /* width: 260px; */
  background: linear-gradient(90deg, #19a4f9, #19a4f9);
  padding: 35px 25px 35px 25px;
  border-radius: 20px;
  margin-top: 20px;
  transition: 0.2s all;
  cursor: default;
  box-shadow: 0 0 20px 7px rgb(0 0 0 / 20%);
  /* margin: 10px 15px 10px 0; */
  transition: 0.2s all;
  cursor: pointer;
}
.copy_trading_panel:hover {
  background-image: linear-gradient(180deg,#48a7e1 20%, #5087c0 50%);
}
.copy_trading_top_panel {
  background: #02071d;
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
  border-radius: 15px;
  padding: 0px 10px;
  height: 58px;
}
.copy_trading_top_panel .input-group {
  width: 300px;
}
.copy_trading_panel_top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.copy_trading_panel_top img {
  width: 52px;
  border-radius: 50%;
  border: 1px solid #e3e3e3;
  margin-right: 8px;
}
.copy_trading_panel_userdetail h2 {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 5px;
}
.copy_trading_panel_userdetail h5 {
  color: #fff;
  font-size: 12px;
  margin: 0;
}
.copy_trading_panel_top > div {
  display: flex;
}
button.follow_btn {
  background-image: linear-gradient(#34c089, #41bd87);
  border: 2px solid #32c089;
  font-size: 14px;
  font-weight: 400;
  padding: 5px 10px;
  color: #fff;
  transition: 0.2s all;
  border-radius: 5px;
}
.input_prepend {
  position: absolute;
  right: 8px;
  top: 8px;
  color: #5a5a5a;
  font-size: 14px;
}
input {
  outline: none !important;
}
button.follow_btn a {
  color: #fff;
}
.copy_trading_panel_middle {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}
.copy_trading_panel_middle h3 {
  font-size: 16px;
  color: #02071d;
  margin: 0;
}
.copy_trading_panel_middle p {
  color: #fff;
  font-size: 12px;
  margin: 0;
}
.copy_trading_panel_middle div:last-child {
  text-align: right;
}
.copy_trading_panel_last {
  cursor: default;
}
.copy_trading_panel_last p {
  background: transparent;
    color: #fff;
    border-radius: 25px;
    padding: 5px 10px;
    border: 1px solid #5087c0;
    font-size: 12px;
    font-weight: 500;
    transition: .2s all;
    box-shadow: 0 0 9px 1px #2765a7;
    text-align: center;
    margin-bottom: 0px !important;
}
.copy_trading_wrapper {
  display: flex;
  flex-wrap: wrap;
}
.copy_trading_top_panel .nav-pills .nav-item .nav-link {
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  background-color: transparent;
  text-transform: capitalize;
}
.copy_trading_top_panel .nav-pills .nav-item .nav-link.active,.copy_trading_top_panel .nav-pills .nav-item .nav-link:hover {
  background-color: transparent;
  box-shadow: none;
  color: #81c8f6;
}
.copy_trading_top_panel .nav-pills {
  padding: 0;
}
button.full_btn {
  background: #5087c0;
  background-image: linear-gradient(180deg,#48a7e1 20%, #5087c0 50%);
  color: #fff;
  border-radius: 25px;
  padding: 7px 12px;
  border: 1px solid #5087c0;
  font-size: 10px;
  font-weight: 400;
  transition: .2s all;
  -webkit-box-shadow: 0 0 9px 1px #2765a7;
  -moz-box-shadow: 0 0 9px 1px #2765a7;
  box-shadow: 0 0 9px 1px #2765a7;
}
.inner_content_wrapper_plain {
  margin-top: 120px;
}
.whitebox {
  background: #000;
  border-radius: 15px;
  /* box-shadow: 0 0 20px 1px #dbe2e92e; */
  margin: 0 auto;
  position: relative;
  margin: 20px 0px;
  padding: 20px;
}
.lighttheme .whitebox{
  background-color: #fff;
}
.follow_trade_head {
  display: flex;
  justify-content: space-between;
}
.follow_trade_head i {
  font-size: 20px;
  color: var(--themeclr);
  margin-right: 5px;
}
.follow_trade_head span {
  font-size: 20px;
  color: #000000;
}
.darktheme .follow_trade_head span{
  color: #fff;
}
.follow_trade_head p {
  margin-bottom: 0;
  font-size: 14px;
  color: #787878;
}
.trader_contract_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
}
button.contract_pair {
  position: relative;
  padding: 5px 10px;
  border: 1px solid #f1f3f6;
  background: #f1f3f6;
  color: #787878;
  font-size: 14px;
  border-radius: 2px;
  cursor: pointer;
  transition: all .35s;
  margin: 8px 10px 0;
}
.contract_label {
  font-size: 14px;
  color: #000;
  margin-right: 15px;
}
.darktheme .contract_label{
  color: #fff;
}
.contract_pair.active, 
.contract_pair:hover {
  border-color: #81c8f6;
  background-color: #fff;
  color: #494949;
}
.contract_pair.active:after {
  content: " ";
  position: absolute;
  bottom: -1px;
  right: -1px;
  display: block;
  width: 15px;
  height: 15px;
  background: url("../images/button_tick.svg") 50% no-repeat;
  background-size: cover;
}
.custom-control {
  min-height: initial;
}
.custom-control-label::before {
  top: 4px;
  left: -25px;
}
.custom-control-label::after {
  top: 4px ;
  left: -25px;
}
.custom-switch .custom-control-label::after {
  top: 6px;
}
.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: var(--themeclr);
  background-color: var(--themeclr);
}
button:focus {
  outline: none;
}
.copy_setting_box {
  margin-top: 30px;
  padding: 10px;
  background: #fff;
  border: 1px solid #2c2c2c;
  border-radius: 2px;
}
.darktheme .copy_setting_box{
  background-color: #000;
  color: #fff;
}
.copy_unify_label {
  color: #000;
  font-size: 14px;
  display: inline-block;
  width: 120px;
  margin-right: 15px;
}
.darktheme .copy_unify_label{
  color: #fff;
}
.copy_unify_box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px 0;
}
.copy_input_lever {
  display: flex;
  align-items: flex-end;
  margin: 0 6px;
}
span.input-lever-text {
  color: #fff;
  font-size: 14px;
  white-space: nowrap;
  margin-right: 10px;
}
.lighttheme span.input-lever-text{
  color: #000;
}
.lever_input {
  width: 50px !important;
  text-align: center;
  height: initial;
  border: 0 !important;
  border-bottom: 1px solid var(--themeclr) !important;
  border-radius: 0 !important;
  padding: 2px !important;
}
.copy_input_lever_box {
  display: flex;
  margin: 10px 0;
}
button.copy_inp_cal {
  color: var(--themeclr);
  background: #f5f5f5;
  border: 1px solid #f5f5f5;
  font-size: 20px;
  font-weight: 500;
  line-height: 0;
  padding: 10px 0;
  margin: 0 5px;
  height: 0;
  width: 25px;
  min-width: initial;
}
.primary_modal .modal-header h5 {
  font-size: 20px;
  color: #000;
  font-weight: 700;
}
.primary_modal .modal-header .close {
  color: #81c8f6;
}
.setting_overview {
  margin-bottom: 10px;
  padding: 16px 13px 16px 11px;
  background: #fff;
  border: 1px solid #dee1e4;
  border-radius: 6px;
}
.dialog_pair {
  color: #81c8f6;
  font-size: 16px;
  font-weight: 700;
}
.setting_overview div span {
  font-size: 14px;
  color: #5d5e65;
  display: inline-block;
  width: 49%;
  text-align: left;
}
button.banner_btn {
  width: 100%;
  background-image: linear-gradient(#64d3a3, #64d3a3);
  border: 2px solid #64d3a3;
  font-size: 14px;
  font-weight: 700;
  padding: 8px;
  color: #fff;
  transition: 0.2s all;
  border-radius: 8px;
}
button.banner_btn:hover {
  color: #64d3a3;
  border: 2px solid #64d3a3;
  background-image: none;
}
.setting_overview div span:nth-child(2) {
  font-size: 14px;
  color: #000;
  text-align: right;
}
.setting_overview div {
  margin: 10px 0;
}
.primary_form select.custom-select {
  border: 1px solid #d9d9d9;
  background: #f5f5f5 url("../images/dropdown_arrow.png") no-repeat right 0.75rem center;
}
.primary_form .primary_inp_inner {
  border: 1px solid #d9d9d9;
  background: #f5f5f5;
  width: 100%;
  height: 41px;
  border-radius: 0.25rem;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
}
.pointer {
  cursor: pointer;
}
.holder {
  height: 50px;
  width: 50px;
  border: 2px solid #252525;
  border-radius: 100%;
  margin: 0 auto;
}
.holder img {
  border-radius: 100%;
  max-width: 50px;
  max-height: 50px;
  min-width: 50px;
  min-height: 50px;
}
input[type="file"]#photo {
  margin-top: -5px;
  opacity: 0;
  z-index: 999;
}
.profile_edit_panel {
  position: relative;
  margin-bottom: -15px;
}
.profile_edit_icon {
  position: absolute;
  top: -10px;
  z-index: 0;
  left: 40px;
  right: 0;
  text-align: center;
}
.profile_edit_icon i {
  background: #81c8f6;
  color: #000;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  font-size: 12px;
}
.custom-switch .custom-control-label::after {
  top: 6px;
}
.custom-control-label{
  color: #000;
}
.darktheme .custom-control-label{
  color: #fff;
}
.copy_trade_user_data {
  display: flex;
  align-items: center;
}
.copy_trade_user_data div {
  text-align: center;
  margin: 0 30px;
}
.copy_trade_user_data h3 {
  font-size: 16px;
  margin: 0;
}
.copy_trade_user_data p {
  color: #787878;
  font-size: 12px;
  margin: 2px 0 0;
}
.badge.badge-danger {
  background: #feecec;
  color: #ff0000;
  font-size: 13px;
  text-transform: capitalize;
  padding: 8px 7px;
}
.badge_light {
  border-radius: 30px;
  font-size: 12px;
  text-transform: capitalize;
  color: #5a5a5a;
  margin: 15px 0;
}
.trade_order_pair {
  background: #f5f5f5;
  font-size: 14px;
  color: #787878;
  padding: 5px 12px;
  border-radius: 3px;
  margin: 0 10px !important;
  cursor: default;
}
ul.primary_tab.nav-tabs {
  padding: 0;
  border-bottom: none;
}
ul.primary_tab.nav-tabs li a.nav-link {
  color: #969696 !important;
  font-size: 16px !important;
  text-transform: capitalize;
  border: 0;
}
ul.primary_tab.nav-tabs li
{
  border-right: 1px solid #161c37 !important;
    padding: 7px 0px;
}
ul.primary_tab.nav-tabs li a.nav-link
{
  font-size: 14px !important;
    color: #fff !important;
    font-weight: 500;
    background-color: transparent;
    text-transform: capitalize;
}
.tab_bottom_border
{
    border-bottom: 1px solid #161c37 !important;
}
ul.primary_tab.nav-tabs li a.nav-link.active {
  background-color: transparent;
  box-shadow: none;
  color: #81c8f6;
}
ul.primary_tab.nav-tabs li a.nav-link:hover,ul.primary_tab.nav-tabs li a.nav-link.active {
  color: #81c8f6 !important; 
}
.rdt_Table,.rdt_Table div
{
  background-color: transparent !important;
  color:#fff !important;
}
/* Copy Trade Ends */

.replytoadmin .card-header{
  margin-bottom: 10px;
  padding: 15px;
  display: grid;
  grid-template-columns: auto auto auto auto;
  align-items: center;
}
.darktheme .collapse.show {
  color: #fff;
}
.accodin_btn_link.btn.btn-link {
  justify-content: space-between;
  display: flex !important;
}
.lighttheme .accodin_btn_link.btn.btn-link {
  color: #000;
}
.faq .innerCMS{
  min-height: 90vh;
}
.darktheme .faq .tableHead h2{
  color: #fff;
}
@media screen and (max-width: 1199px) {
  .bitAbout {
    height: 100%;
  }
  .abtImg {
    height: 100%;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 0;
  }
  .footer_top_left {
    background: transparent;
  }

  .homeBannerText {
    padding: 170px 0 0 0px;
  }

  .TradingPlatform {
    min-height: 300px;
    max-height: 300px;
  }
  .tpLeftContent {
    padding-top: 40px;
  }

  img.bisanseScreen {
    margin-top: 0px;
    position: absolute;
    right: 0;
    top: -10px;
    max-width: 60%;
  }

  .gsmCardBG {
    max-width: 100%;
  }
}

@media screen and (max-width: 991px) {
  div#navbarResponsive ul .dropdown-menu.sm-menu .dropdown-item:hover {
    background: transparent !important;
    color: var(--themeclr) !important;
  }
  div#navbarResponsive ul .dropdown-menu {
    padding-bottom: 0;
  }
  div#navbarResponsive ul .nav-item {
    margin-bottom: 0 !important;
  }
  div#navbarResponsive ul .nav-item a{
    padding: 15px 0;
  }
  .customNav .container {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    max-width: 100%;
  }
  .gridBanner {
    display: flex;
    padding-bottom: 15px;
    margin-top: 30px;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .landingBannerContent h3 {
    font-size: 42px;
  }
  .btnLandingJoin {
    padding: 8px 40px;
    font-size: 18px;
    transition: .5s;
  }
  .landGBCard {
    margin-bottom: 50px;
    width: 47%;
  }
  .getStarted h2 {
    font-size: 30px;
  }
  .bfCard h3 {
    font-size: 24px;
  }
  .whySpace h3 {
    font-size: 24px;
  }
  .whySpace h3 br {
    display: none;
  }
  .whyBisanse h2 {
    font-size: 30px;
  }
  .howBitbazzi h3 {
    font-size: 30px;
  }
  .Footerrow {
    flex-direction: column;
  }
  .footer_bottom_right {
    width: 100%;
  }
  .abtContent p {
    font-size: 16px;
  }
  .howBitbazzi p {
    font-size: 16px;
  }
  .whyCard {
    width: 100%;
  }
  .container {
    max-width: 100%;
  }
  .homeBannerText h3 {
    font-size: 30px;
  }
  .homeBannerText h2 {
    font-size: 60px;
  }
  .homeBannerText h2 small {
    font-size: 40px;
  }
  .homeBannerText h6 {
    font-size: 13px;
  }
  .homeCTACont h6 {
    font-size: 25px;
  }
  .homeCTACont h3 {
    font-size: 40px;
  }
  .cryptoFeaturesContIcon {
    margin-top: 10px;
  }
  .footerCont ul.socialIcons li {
    margin: 0 10px;
  }
  .homeBannerText ul li small {
    font-size: 13px;
  }
  .AppComingSoonSection {
    min-height: auto;
  }
  .appComingSoonText h3 {
    margin-top: 25px;
    font-size: 42px;
  }
  .coinTable .nav-tabs .nav-link {
    font-size: 14px;
  }
  .responsive-table .table-header {
    font-size: 14px;
  }
  .responsive-table .table-row {
    font-size: 14px;
  }
  .footerSocialLinks ul li {
    margin-left: 25px;
  }
  .recaptchaMain {
    transform: scale(75%);
    margin-left: -35px;
  }
  .footer_bottom_left .d-flex {
    display: block !important;
  }
  .customNav .nav-item .nav-link {
    color: #ffffff;
    padding: 10px 0;
  }
  .getstarted_list li {
    font-size: 16px;
    padding: 18px 30px;
  }
  .getstarted_list li img {
    margin-right: 15px;
  }
  ul.menu_right {
    align-items: flex-start;
  }
  .menu_right li.nav-item {
    margin-left: 0;
    margin-bottom: 20px;
  }
  .customNav .navbar-toggler {
    color: #81c8f6;
    outline: none;
    padding: 0;
    font-size: 24px;
    transition: .5s;
  }
  .customNav .navbar-toggler:hover {
    color: #81c8f6;
  }
}
@media screen and (max-width: 767px) {
  .replytoadmin .card-header {
    grid-template-columns: auto auto;
    gap:15px
  }
  .launchpadCoinName h3 {
    font-size: 18px;
  }
  .launchpadCoinName img {
    height: 50px;
    aspect-ratio: 1/1;
  }
  .homeBanner::after,.bitAbout::after
  {
    content: none !important;
  }
  .coinTable::after
  {
    width: 250px !important;
    height: 500px !important;
  }
  .app_page_img
  {
    max-width: 100% !important;
    padding-bottom: 0px !important;
  }
  .homeBanner .app_page_img
  {
    padding-bottom: 30px !important;

  }
  .homeBanner 
  {
    background-position: top 570px left 0px !important;
  }

  .landingBannerContent {
    align-items: center;
    /* background: url(/static/media/homeBannerBg.61885f6f.png) no-repeat scroll 0 0; */
    text-align: center;
    width: 100%;
    margin-bottom: 40px;
  }
  .landingBannerContent p {
    color: #fff;
  }
  .landBannerImg {
    text-align: center;
    margin-bottom: 30px;
  }

  .homeBannerText {
    padding: 32% 0 0 0;
    display: inline-block;
    text-align: center;
    width: 100%
  }

  .homeBannerImg {
    margin-top: 0px;
  }

  .homeBannerImg img {
    margin: 0 auto;
    display: block;
  }

  .coinTableMain {
    max-width: 100%;
  }
  .abtContent {
    text-align: center;
    margin-bottom: 40px;
  }

  .bfCard {
    text-align: center;
  }

  .footer_top_left {
    text-align: center;
  }

  .footer_top_left ul {
    align-items: center;
    justify-content: center;
  }

  .footer_bottom_right {
    text-align: center;
  }

  .footer_bottom_right ul {
    justify-content: center;
    align-items: center;
  }

  .coinTable .table td, .coinTable .table th {
    font-size: 14px;
  }

  .coinTable .nav-tabs .nav-link img {
    max-width: 18px;
    margin-right: 4px;
  }

  .homeCTACont h3 {
    font-size: 35px;
  }
  .getStartedCont::after {
    right: -15px;
  }

  table.dataTable>tbody>tr.child ul.dtr-details {
    width: 100%;
  }

  table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child::before, table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child::before {}

  table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child::before, table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child::before {
    top: 50%;
    margin-top: -7px;
    background-color: #121126;
  }

  .loginBanner .homeBannerText ul {
    margin-left: 15px;
  }

  .loginBanner ul li {
    text-align: left;
    font-size: 16px !important;
  }

  .homeBannerText ul li {
    display: block;
  }

  .homeBannerText ul li span.bannerIcon {
    display: none;
  }

  .homeBannerText ul li small {
    font-size: 18px;
  }

  .AppComingSoonSection {
    min-height: auto;
  }

  .appComingSoonText h3 {
    font-size: 36px;
    margin-top: 20px;
  }

  .whyBisanse {
    padding-bottom: 80px;
  }

  .whyBisanse h2 {
    font-size: 30px;
  }

  .wbLeftContent h4 {
    font-size: 24px;
  }

  .wbRightContent h4 {
    font-size: 24px;
  }

  .wbRightImg {
    text-align: center;
  }

  .wbLeftImg {
    text-align: center;
  }

  .mobileReverse {
    flex-direction: column-reverse;
  }

  .TradingPlatform {
    min-height: 300px;
    max-height: initial;
    padding-bottom: 40px;
  }

  .tpLeftContent {
    float: none;
    margin-bottom: 25px;
  }

  img.bisanseScreen {
    margin-top: 0px;
    position: static;
    max-width: 100%;
  }

  .getStarted h2 {
    font-size: 30px;
  }

  .gsmCardBG {
    margin: 0 auto;
  }

  .getStarted {
    padding-top: 60px;
  }

  .ourPartners h2 {
    font-size: 30px;
  }

  .partnersLogo {
    flex-direction: column;
    flex-wrap: wrap;
  }

  .partnersLogo a {
    margin-bottom: 35px;
  }

  .mainFooter {
    padding-top: 35px;
  }

  .footerLogo {
    margin-bottom: 30px;
  }

  .footerBottomLinks li {
    margin-left: 15px;
  }
  #navbarResponsive {
    padding-bottom: 20px;
  }

  .customNav .nav-item .dropdown-menu .nav-link {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .aboutBit .row {
    flex-direction: column-reverse;
  }

  .loginSignUp {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: transparent;
  }

  .loginSignUp li:nth-child(even) {
    display: none;
  }

  .loginSignUp li a:hover {
    color: #81c8f6 !important;
  }

  .recaptchaMain {
    transform: none;
    margin-left: 0px;
  }

  .lgoinBannerText {
    text-align: center;
  }

  .lgoinBannerText ul {
    justify-content: center;
    display: flex;
    width: 100%;
    align-content: center;
    text-align: center;
    margin-bottom: 20px;
    flex-direction: column;
  }

  .loginBanner ul li {
    text-align: center;
  }

  .loginBanner .col-md-6.pt-5 {
    padding-top: 0 !important;
  }
  .footer_top_left ul li a {
    margin-top: 5px;
    margin-bottom: 5px;
    display: block;
  }

  .loginSignUp li {
    color: #ffffff;
    display: block;
    width: 100%;
    text-align: left;
  }

  .loginSignUp a {
    color: #ffffff !important;
    display: block;
  }
  .abtImg {
    height: 350px;
    background-position: center;
  }
  .getStarted {
    display: block;
    text-align: center;
  }
  .footer_top {
    display: block;
  }
  .footer_top_right {
    text-align: center;
    margin-bottom: 30px;
  }
  .footer_bottom {
    display: block;
    text-align: center;
  }
  .footer_bottom_left .d-flex {
    text-align: center;
  }
  .footer_bottom_left > div div {
    margin-right: 0;
  }
  .footer_bottom_left {
    margin-top: 30px;
  }
  .copy_right_section {
    display: block;
    text-align: center;
  }
  .term_list {
    display: block;
  }
  .getstarted_list {
    display: none;
  }
  .getstarted_mobile {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .getstarted_mobile div {
    background-color: #81c8f6;
    text-align: center;
    padding: 15px;
    width: 150px;
    border-radius: 15px;
    margin-top: 30px;
  }
  .getstarted_mobile div p {
    margin: 10px 0 0;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .howBitbazzi {
    text-align: center;
  }
  .widget_panel {
    border-right: none;
  }
  .footer_bottom_left p {
    width: auto;
  }
  .bitAbout {
    /* text-align: center; */
    padding-bottom: 0;
  }
}
@media screen and (max-width: 575px) {
  .btnPercent {
    font-size: 10px;
    padding: 6px 11px;
  }
  .launchpadCoinName h3 {
    font-size: 14px;
  }
  .launchpadCoinName h3 small {
    font-size: 12px;
  }
  .launchpadCoinName img {
      height: 42px;
      margin-right: 5px;
  }
  .follow_trade_head span {
    font-size: 15px;
  }
  .copy_input_lever{
    display: block;
  }
  .tradeTabBoxStyle .nav-tabs .nav-link {
    padding: 2px 0px 4px !important;
  }
  .tradeFulltabbedTable .nav-tabs .nav-link{
    padding: 2px 7px 4px !important;
  }
  .wallet-tab .assetTitle.px-4,.card_body_space_mob
  {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .px-mob-his
  {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .copy_trade_user_data
  {
    display: block !important;
  }
  .copy_trade_user_data h3
  {
    text-align: center !important;
  }
  .copy_trade_user_data div
  {
    margin-top: 10px;
  }
  .copy_trading_top_panel
  {
    display: block !important;
    height: unset !important;
  }
  .copy_trading_top_panel .input-group
  {
    margin-top: 20px !important;
  }
  .recaptchaMain {
    transform: scale(1.3);
    /* margin-left: -55px !important; */
}
  .landGBCard {
    width: 100%;
  }

  /* .coinTable .nav-tabs .nav-item.show .nav-link,
  .coinTable .nav-tabs .nav-link.active {
    background: transparent;
  } */

  .coinTable .table th:first-child, .coinTable .table td:first-child {
    padding-left: 15px;
  }

  .homeBannerText {
    padding-top: 50%;
  }

  .homeBannerText h3 {
    font-size: 24px;
  }

  .homeBannerText h6 {
    font-size: 16px;
  }

  .cryptoFeaturesContText h4 {
    font-size: 22px;
  }

  .homeAccordian #accordion .card-header .btn span.question {
    max-width: 85%;
    display: inline-block;
  }

  .footerCont h6 {
    margin-bottom: 5px;
  }

  .pageTitle {
    font-size: 30px;
    font-weight: 600;
  }

  .getStartedCont::after {
    right: 50%;
    transform: rotate(90deg);
    margin-right: -17px;
    top: 87%;
  }

  .getStartedCont {
    margin-bottom: 80px;
  }

  .featuresIcon1, .featuresIcon2, .featuresIcon3, .featuresIcon4 {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
  }

  .cryptoFeaturesContText h4 {
    text-align: center;
  }

  .cryptoFeaturesContText p {
    text-align: center;
  }

  .appComingSoonText h3 {
    margin-top: 30px;
    font-size: 36px;
  }

  .appComingSoonText p {
    font-size: 14px;
  }

  .appComingSoonText {
    padding-bottom: 20px;
  }

  .answerList {
    display: block;
  }

  .landingForm iframe {
    transform: scale(.60);
    position: absolute;
    left: 0;
  }



  .recaptchaMain {
    overflow-x: hidden;
  }

  .footerSocialLinks {
    text-align: left;
    margin-top: 20px;
  }

  .footerSocialLinks ul {
    justify-content: flex-start;
  }

  .footerSocialLinks ul li {
    margin-left: 0;
    margin-right: 25px;
  }

  .footerBottomBox {
    flex-direction: column;
  }

  .footerBottomLinks {
    margin-top: 12px;
  }

  .lgoinBannerText {
    margin-bottom: 25px;
  }

  .loginBanner h3 {
    font-size: 24px;
  }

  .loginBanner h2 {
    font-size: 44px;
  }

  .loginBanner h2 small {
    font-size: 36px;
  }

  .lgoinBannerText h2 img {
    max-height: 28px;
  }

  .formBox {
    padding: 20px;
  }

  .formBox h6 a {
    display: block;
  }

  .loginBanner .formBox h2 {
    font-size: 30px;
  }
  .landingBannerContent h2 {
    font-size: 60px;
  }
  .landingBannerContent h3 {
    font-size: 36px;
  }
}
@media(max-width:425px){
  .recaptchaMain {
    margin-left: -15% !important;
    transform: scale(1.1);
  }
  
  .replytoadmin .card-header {
    grid-template-columns: auto ;
    gap:15px
  }
  .launchPadCoinDetails{
    display: block;
  }
  .whiteShadowBox {
    padding: 16px;
  }
}
.btn-outline-secondary:hover{
  color: var(--themeclr);
}




.copy_unify_box input {
    height: auto !important;
}
.primary-modal .modal-content {
    background-color: #02071d;
    border-radius: 10px !important;
    box-shadow: 0 0 20px 1px #dbe2e92e;
}
.primary-modal .modal-title {
    color: #fff;
    font-size: 16px !important;
}
.primary-modal .modal-header {
    border-bottom: 1px solid #6aa4cf;
}
.primary-modal  .modal-body .form-group label {
    color: #fff;
}
.primary-modal .css-2b097c-container {
    border: 1px solid #81c8f6;
        background: #03081f;
        border-radius: 10px;
}
.primary-modal .form-control{
  background-color: #03081f !important;
  border: 1px solid #81c8f6 !important;
  color: #fff !important;
  font-size: 14px;
    text-align: left;
    padding: 10px 15px;
    border-radius: 10px;
    height: 52px;
}
.primary-modal .noteCont h6 {
    color: #fff;
}
.primary-modal .noteCont p {
    color: #fff;
}
.primary-modal .tradeBuy td {
    color: #fff;
}
.securityphone {
    position: absolute;
    bottom: 0;
    /* top: 21px; */
    right: 0;
    margin-bottom: -3px !important;
    padding: 2px !important;
}
.nav-link sup{
    margin-left: 3px;
    color: #81c8f6;
    font-weight: 700;
}

@media only screen and (min-width:1300px)
{
  .container
  {
    max-width: 1200px !important;
  }
}
.navbardrop_pos::after
{
  top:0px !important;
}
.banner-heading-sm
{
  color:#fcfcfc !important;
  font-size: 30px !important;
  font-weight: 600;
  text-align: center;
}
.sec_sec_head
{
  color: #fcfcfc !important;
  font-size: 30px !important;
  font-weight: 600;
}
.sec_sec_head span
{
  color:#81c8f6 !important;
}
.sec_sec_p
{
  color:#c1c2ca;
  font-size: 14px !important;
}
.text_blue
{
  color:#6facd9;
}
.img_text_div .text-white
{
  font-weight: 600;
  font-size: 14px;
}
/* .rounded_shadow_blue
{
  box-shadow: 0 0 21px 5px #2765a7;
} */
.rounded_shadow_blue img
{
 
  width:50px;height:50px;
  /* background-color: #1b396366; */
  
filter: drop-shadow(1px -4px 12px #2765a7);
  /* box-shadow: 0 0 21px 5px #2765a7; */
  /* border-radius: 50%; */
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.rounded_shadow_blue img:hover
{
filter: drop-shadow(1px -4px 12px #1b396366);

}
.coinTable .table thead th
{
  border-bottom: 1px solid #161c37 !important;
  color:#fff !important;
  border-top: 1px solid #161c37 !important;

}
.coinTable .table tbody td,
.coinTable .table tbody td:first-child
{
  color:#636fa0 !important;
  font-weight: 600;
}
.coin-table-show-more a.btn_more
{
  width: auto !important;
  width: 50px !important;
  height: 50px !important;
  background: #5087c0;
  background-image: linear-gradient(180deg,#48a7e1 20%, #5087c0 50%);
  color: #fff;
  border-radius: 25px;
  padding: 7px 20px;
  border: 1px solid #5087c0;
  font-size: 13px;
  font-weight: 500;
  transition: .2s all;
  -webkit-box-shadow: 0 0 20px 1px #2765a7;
  -moz-box-shadow:0 0 20px 1px #2765a7;
  box-shadow: 0 0 20px 1px #2765a7;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute !important;
  z-index: 1 !important;
  margin-top: 30px !important;
  bottom: -20px !important;
  }
  .coinTableMain .table
  {
    margin-bottom: 40px !important;
  }
  .img_why_card
  {
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #18a1f2;
    border-radius: 15px;
    margin: 20px auto;
    -webkit-box-shadow: 0 0 10px 5px rgb(0 0 0 / 8%);
    -moz-box-shadow: 0 0 10px 5px rgb(0 0 0 / 8%);
    box-shadow: 0 0 10px 5px rgb(0 0 0 / 8%);
  }
  .whyCard .img_div_border
  {
    position: relative;
  }
  .whyCard .img_div_border::before
  {
    content: "";
    position: absolute;
    border-top: 1px solid #6bc4fa;
    width: 150px;
    height: 2px;
    top:0px;
  }
  .whyCard .img_div_border h3
  {
    margin-top: 15px;
  }
  .center_text_column
  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .bg_strip
  {
    /* background-image: url(../images/bg-trip.png); */
    background-repeat: repeat;
    background-size: 100% 100%;
    background-position: top;
  }
  .app_page_img
  {
    max-width: 70%;
  margin-top: 30px;
  }
  .icon_why
  {
    margin-right: 0px !important;
    max-width: 50px;
  }
  .homeBanner::after
  {
    /* content: "";
    background-image: url(../images/ring.png); */
    /* background-repeat: no-repeat;
    background-position: bottom 200px right 0px;
    width: 566px;
    background-size: contain;
    height: 562px;
    position: absolute;
    right: 3px;
    bottom: -240px;
    transform: rotate(90deg); */
     }
.bitAbout
{
  position: relative;
  overflow: hidden;
  padding-top: 120px;
  padding-bottom: 100px;
}
     .bitAbout::after
     {
       content: "";
       background-image: url(../images/ring.png);
       background-repeat: no-repeat;
       background-position: bottom 200px right 0px;
       width: 425px;
       background-size: contain;
       height: 425px;
       position: absolute;
       left: 35%;
       top: 0px;
       transform: rotate(0deg);
       overflow: hidden;
       z-index: 0;

        }

        .bitAbout::before
        {
          content: "";
          background-image: url(../images/ring.png);
          background-repeat: no-repeat;
          background-position: bottom 200px right 0px;
          width: 400px;
          background-size: contain;
          height: 455px;
          position: absolute;
          right: 28px;
          bottom: 26px;
          transform: rotate(90deg);
          overflow: hidden;
          z-index: 0;
           }

      .img_bit_about
      {
        max-width: 80%;
        margin-left: auto;
        border-radius: 20px;
        /* filter: drop-shadow(1px -4px 12px #1b396366); */
        -webkit-box-shadow: 0 0 15px 7px #125191;
        -moz-box-shadow: 0 0 15px 7px #125191;
        box-shadow: 0 0 15px 7px #125191;
        position: relative;
    z-index: 1;
      }
      /* .footer_parent
      {
        position: relative;
        padding-bottom: 200px;
      }
      .footer_parent::before
      {
        content: "";
        background-size:100% 200px;
        background-image: url(../images/footer_bg.png);
        background-repeat: no-repeat;
        background-position: bottom -45px left 0px;
        position: absolute;
        bottom: 0px;
        width: 100%;
        height: 200px;
      } */

    .coinTable
    {
      position: relative;
    }
    .coinTable::after
    {
      content: "";
      background-image: url(../images/circle.png);
      background-repeat: no-repeat;
      background-position: bottom 0px left 0px;
      width: 355px;
      background-size: contain;
      height: 600px;
      position: absolute;
      left: 0px;
      bottom:50px;
    }

    @media only screen and (min-width:768px) and (max-width:991px)
    {
      .row_mob_upper
      {
        margin-left: -5px !important;
    margin-right: -5px !important;
      }
      .px-mob-width
      {
        margin-right: 4px !important;
      }
      .table_width_mob
      {
        margin-right: 10px !important;
        padding-right:5px !important;

      }
      .main_bg_trade_new .tradeMain .table_width_mob .darkBox
      {
        margin-right: 10px !important;
        padding-right: 5px !important;

      }
      .wallet-side-menu .nav-tabs {
        /* background: var(--themeclr); */
        border-radius: 15px 15px 0px 0px;
    }
    .wallet-side-menu .nav-item.active
    {
      background: transparent !important;
      border-right: 1px solid #161c37 !important;
    }
    .wallet-side-menu .nav-tabs .nav-item
    {
      display: inline-block;
      /* border-right: 1px solid #161c37 !important; */
    }
      .wallet-tab .assetTitle.px-4,.card_body_space_mob
      {
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
      .px-mob-his
      {
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
      .customNavTrade .nav-item .nav-link
      {
        padding-left: 0px !important;
        padding-right: 0px !important;
      }
      .copy_right_section
      {
        display: block !important;
      }
      .copy_right_section .term_list
      {
        align-items: center;
        justify-content: center;
      }
      .copy_right_section p
      {
        text-align: center;
        margin-top: 20px;
      }
      .socialIcons li a
      {
        width: 35px;
        height: 35px;
      }
      .loginBanner h2
      {
        font-size: 60px !important;
      }
      .recaptchaMain
      {
        transform: scale(100%) !important;
      }
      .homeBanner::after
      {
        right: -6px !important;
        width: 389px !important;
        height: 369px !important;
        transform: rotate(90deg) !important;
      }
    }
    @media only screen and (min-width:992px) and (max-width:1199px)
    {
      .launchpad_token_single p
      {
        font-size: 12px !important;
      }
      .fixedWidth
      {
        width: 320px !important;
      }
      .liquidWidth {
        width: calc(100% - 607px) !important;
    }
      .vertical-tab .nav-tabs li a
      {
        padding-left: 5px !important;
        padding-right: 5px !important;
      }
      
      .inputGroup.buy_sell_icon_grp
      {
        min-width: 65px;
    margin-right: 0px !important;
    margin-left: 10px !important;
      }
      .tradeLeft
      {
        background: #05163c;
    height: 100%;
      }
      .recaptchaMain
      {
        transform: scale(92%);
        margin-left: -10px !important;
      }


      .homeBanner::after
      {
        width: 464px !important;
        height: 449px !important;
        right: -6px !important;
        bottom: -240px;
        transform: rotate(90deg) !important;
      }
    }


    @media only screen and (min-width:1200px) and (max-width:1399px)
    {
      .homeBanner::after
      {
        right: -4px !important;
        transform: rotate(90deg) !important;
      }
    }

    .login_banner
    {
      position: relative;
    }
    .login_banner::before
    {
      content: "";
      /* background-image: url(../images/ring.png); */
      background-repeat: no-repeat;
      background-position: bottom 200px right 0px;
      width: 491px;
    background-size: contain;
    height: 470px;
    position: absolute;
    right: -10px;
    bottom: 8px;
    transform: rotate(90deg);
       }
  
       .formBox
       {
        /* max-width: 450px !important; */
        margin-left: auto;
        margin-right: auto;
       }
       .center_div_align
       {
        display: flex;
        align-items: center;
       }
       .privacy-policy-container p,.privacy-policy-container li
       {
        color: #c1c2ca;
        font-size: 14px !important;
        line-height: 2 !important;
       }
       .privacy-policy-container a:hover
       {
        color:#fff;
       }
       .privacy-policy-container h4,  .privacy-policy-container h5
       {
        color: var(--themeclr) !important;
       }
       .privacy-policy-container h5, .privacy-policy-container h4
       {
        line-height: 1.7 !important;
       }
       .innerCMS
       {
        min-height: calc(100vh - 483px);
       }
       .blogMainContent
       {
        min-height: calc(100vh - 409px);
       }
       .categoryBlogList h2
       {
        color:#fff !important;
        font-size: 20px !important;
       }
       .categoryBlogList a
       {
        color: #81c8f6 !important;
       }
       .categoryBlogList a:hover
       {
        color: #fff !important;
       }
       .copy_trading_top_panel .nav
       {
        align-items: center;
       }
       .copy_trading_top_panel li
      {
        border-right: 1px solid #161c37 !important;
        padding: 10px 0px;
      }
      .copy_trading_top_panel input
      {
        height: 45px !important;
        padding: 17px 15px !important;
      }
      .copy_trading_top_panel .input-group-text
      {
        background-color: transparent !important;
        border: 1px solid #81c8f6 !important;
        border-radius: 0px 10px 10px 0px !important;
        height: 45px !important;
        border-left: none !important;
        color:#fff !important;
      }
      .copy_trading_top_panel .input-group
      {
        margin-top: 6.5px !important;
        margin-bottom: 6.5px !important;
      }
      .btnLandingJoin_outline:hover a
      {
        color:#fff !important;
      }

.copy_trading_top_panel .nav
      {
        display: block;
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 100%;
    white-space: nowrap;
    margin-right: 20px;
      }
      .copy_trading_top_panel .nav li
      {
        display: inline-block;
      }

      @media screen and (max-width: 575px) {
        .copy_trading_top_panel
        {
          display: block !important;
          height: unset !important;
          padding-bottom: 2px !important;
        }
        .copy_trading_top_panel .input-group
        {
          margin-top: 20px !important;
          width: 275px !important;
          margin-bottom: 20px !important;
        }
        .copy_trading_top_panel .nav
        {
          margin-right: 0px !important;
        }
      }
      .fixed-top
      {
        /* position: fixed; */
    top: 0;
    right: 0;
    left: 0;
      }
      .navbar-collapse.collapse
      {
        display: none !important;
      }
      .navbar-collapse.collapse.show
      {
        display: block !important;
      }


    @media only screen and (min-width:992px) and (max-width:1199px)
    {
      .navbar-collapse.collapse.collapse_menu
      {
        display: flex !important;
        position: unset !important;
        background: transparent !important;
        min-height: unset !important;
        max-height: unset !important;
        max-width: unset !important;
        padding: unset !important;
      }
     
    }
 
    @media only screen and (min-width:768px) and (max-width:991px)
    {
      .tradeMobile_frlx
    
      {
        display: flex;
        justify-content: space-between;
      }
  
      .tradeMobile_frlx .navbar-toggler {
        color: #81c8f6;
        outline: none;
        padding: 0;
        font-size: 24px;
        transition: .5s;
    }
    }

    @media only screen and (min-width:992px)
    {
      .customNavTrade
      {
        display: flex !important;
      }
      .navbar-collapse.collapse.collapse_menu {
        display: flex !important;
        /* position: unset !important;
        background: transparent !important;
        min-height: unset !important;
        max-height: unset !important;
        max-width: unset !important;
        padding: unset !important;
        overflow-y: hidden !important; */
    }
    }
    .login_banner
    {
      padding-top: 60px;
    }
    .headerCrediantials.homeBanner::after
    {
      top:100px !important;
      bottom: unset !important;
    }
    .blogMainContent
    {
      padding-top: 80px !important;
    }
    .innerCMS
    {
      padding-top: 50px !important;
    }
    .privacy-policy-container.py-5
    {
      padding-top: 120px !important;
    }
    .privacy-policy-container
    {
      min-height: calc(100vh - 525px);
    }

.dropdown-menu a
{
color:#fff !important;
}
.dropdown-menu.menu_default_show
{
  border-radius: 5px !important;
  /* top:25px !important; */
}
ul.primary_tab.inner_tab_style_blue.nav-tabs li
{
border-right: none !important;
}
ul.primary_tab.inner_tab_style_blue.nav-tabs li .nav-link
{
  border-bottom: 2px solid transparent !important;
  cursor: pointer;
}
ul.primary_tab.inner_tab_style_blue.nav-tabs li .nav-link.active,ul.primary_tab.inner_tab_style_blue.nav-tabs li .nav-link:hover
{
  border-bottom: 2px solid #81c8f6 !important;
}

.primary_tab.tab_bottom_border
{
  display: block;
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 100%;
  white-space: nowrap;
}
.primary_tab.tab_bottom_border li
{
  display: inline-block;
}
.wallet-tab select{
    padding-top :0 !important;
    padding-bottom :0 !important;
}
.lighttheme .wallet-tab select{
  /* background-color: #fff !important;
  color: #000 !important; */
  filter: invert(1);
    border-color: #ffa147 !important;
}
.landingForm input[type=text],.darktheme .wallet-tab select,.lighttheme .wallet-tab select, .landingForm input[type=tel], .landingForm input[type=password], .landingForm input[type=email], .landingForm input[type=number], .landingForm textarea, .landingForm textarea.form-control
 {
  background-color: #03081f;
  border: 1px solid #81c8f6;
  color: #fff !important;
  padding: 25px 15px;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 0px;
  width: 100%;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  resize: none;
  box-shadow: none !important;
}
.darktheme .wallet-tab select:focus-visible {
  outline: 0 !important;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #03081f !important;
    border: 1px solid #81c8f6 !important;
    color: #464444 !important;
    padding: 13px;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 0px;
    width: 100%;
    transition: all 0.5s ease-in-out;
    border-radius: 5px;
    resize: none;
    box-shadow: none !important;
}
.darkmodal .form-control:disabled,
.darkmodal .form-control[readonly] {
    color: #fff !important;
}
/* .wallet-side-menu .nav-tabs a.nav-item
{
  color:#fff !important;
} */

@media only screen and (max-width:580px)
{
  .wallet-side-menu .nav-tabs {
    display: block;
    justify-content: center;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    /* background: #fff; */
    /* border-radius: 15px 15px 0px 0px; */
}

.lighttheme nav.wallet-side-menu.more_menus a{
  color: #000;
}
.darktheme nav.wallet-side-menu.more_menus a{
  color: #fff;
}
.wallet-side-menu .nav-item.active
{
  background: transparent !important;
  border-right: 1px solid #161c37 !important;
}
.wallet-side-menu .nav-tabs .nav-item
{
  display: inline-block;
  /* border-right: 1px solid #161c37 !important; */
}
}
.react-tel-input.disabled .react-tel-input .form-control{
  width: 100% !important;
}
.react-tel-input .form-control {
    background-color: #03081f !important;
    background: #03081f !important;
    height: 52px !important;
    border: none !important;
    border-radius: 0px !important;
    /* border: 1px solid #81c8f6 !important; */
    color: #fff !important;
    max-width: 100% !important;
    border-top-right-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
    /* border-radius: 10px !important; */
}
.lighttheme  .wallet-tab .mobNumber input,
.darktheme .wallet-tab .mobNumber  input {
  background: #fefefe00 !important;
  color: #000 !important;
  width: 100%;
  border-radius: 0 !important;
  border: 0 !important;
}
.react-tel-input
{
  border-radius: 5px !important;
  border: 1px solid #81c8f6 !important;
  background-color: #03081f !important;
  background: #03081f !important;
  border-left: none !important;
}
.darktheme .react-tel-input{
  background: #000 !important;
}
.react-tel-input{
  border-color: var(--themeclr) !important;
}
.darktheme .react-tel-input .selected-flag{
  background: #000 !important;
  border-radius:  5px !important;
}

.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus
{
  background-color: transparent !important;
}
.react-tel-input .flag-dropdown
{
  background-color: #03081f !important;
  background: #03081f !important;
  border-radius: 5px !important;
    border: 1px solid var(--themeclr) !important;
    border-top: none !important;
    border-bottom: none !important;
    border-right: none !important;

}
.react-tel-input .selected-flag
{
  border-radius: 0px !important;
  margin-left: 0px !important;

}
.bot_10_px
{
  bottom: 10px !important;
  right:5px !important;
}
.crcheckbox.d-flex
{
  display: flex;
}
.table_radius
{
  border-radius: 15px !important;
  box-shadow: 0 0 20px 1px #dbe2e92e;
}
.table_radius table
{
  margin-bottom: 0px !important;
}
@media only screen and (min-width:576px) and (max-width:767px)
{
  .launchpad_token_panel
  {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .launchpad_token_panel .d-flex
  {
    display: block !important;
  }
  .wallet-tab .assetTitle.px-4,.card_body_space_mob
  {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .px-mob-his
  {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .form-group label
  {
    font-size: 13px !important;
  }
}
.border_blue_select
{
  border: none !important;
    background: transparent !important;
  /* border:none !important; */
  /* background: transparent !important; */
  /* color: #fff !important; */
 
}
/* 
.border_blue_select>div
{
  background-color: #03081f;
    border: 1px solid #81c8f6;
    color: #fff !important;
    border-radius: 10px !important;
    height: 52px !important;
}
.border_blue_select>div>div>div
{
  color:#fff !important;
} */
/* .border_blue_select>div>div>div:hover
{
  background: #03081f !important;
} */
/* .border_blue_select>div,.border_blue_select>div:hover
{
  background: #03081f !important;
  border-radius: 15px !important;

} */
/* .border_blue_select>div div
{
  background: #03081f !important;
  
} */
.custom-file,.custom-file input,.custom-file-label::after
{
  height: 52px !important;

}
.custom-file-label
{
  background: #03081f !important;
  border: 1px solid transparent;
  color: #fff !important;
  border-radius: 10px !important;
  height: 52px !important;
  display: flex;
    align-items: center;
    font-size: 14px !important;
}
select.form-select {
  font-size: 14px !important;
  font-weight: 300;
}
.custom-file-label::after

{
  display: flex;
    align-items: center;
    border-radius: 0px 10px 10px 0px;
    cursor: pointer;
}
.custom-file
{
  overflow: hidden;
  border: 1px solid #005eb8;
  border-radius: 5px;
}
.max_width_100
{
  max-width: 100%;
}
.btn_z_index_more
{
  position: relative;
  z-index: 3 !important;
}
.react-datepicker__input-container input
{
  /* height: 52px !important; */
}
.assetTitle.mb-0
{
  margin-bottom: 0px !important;
}
.nav_border_new a
{ 
  font-size: 14px !important;
  color: #fff !important;
  font-weight: 500 !important;
  background-color: transparent !important;
  text-transform: capitalize;
  border-bottom: 2px solid transparent !important;
  border-top:none !important;
  border-left:none !important;
  border-right: none !important;
    cursor: pointer !important;
    border-radius: 0px !important;
}
.nav_border_new a.active,.nav_border_new a:hover
{
  color: #81c8f6 !important;
  border-bottom: 2px solid var(--themeclr) !important;
}
.popup_ul li
{
  font-size: 14px !important;
}
.input_height_52
{
  height: 52px;
}
.tradeLimitMarket.tradeLimitMarket_h,.min_height_screen
{
  min-height: calc(100vh);
}

.modal {
  z-index: 999999 !important;
}
.rigt_paddng_icon
{
  padding-right: 40px !important;
}
.min_height_asset_history
{
  min-height: calc(100vh - 0px);
  padding-top: 69px;
  background-color: #F2F2F0;
}
.darktheme .min_height_asset_history{
  background-color:#191919;
}
.darktheme .react-datepicker__input-container input{
  background-color: #000;
  color:#fff !important
}
.lighttheme .react-datepicker__input-container input{
  background-color: #fff;
  color:#000 !important
}
.lighttheme .react-datepicker__input-container input:focus{
  background-color: #fff;
  color:#000 !important
}
.lighttheme .min_height_asset_history .assetsTable .assetTitle {
  color: var(--themeclr);
}
.tradeMain_margin_sm
{
  margin-top: 13px !important;
}
.main_bg_trade_new .tradeMain .darkBox
{
  box-shadow: 0 0 0px 0px #dbe2e92e !important;
}
.referralprgm .tableHead h2,.referralprgm .kyc-header label{
  color:#fff !important
}

/* .........v......... */
.flexoverflow{
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
.darktheme button.transbtn.theme {
  background: transparent !important;
}
.lighttheme .bg_strip{
  background-color: #f2f2f2 !important;
}
.darktheme .bg_strip{
  background-color: #191919 !important;
}
.darktheme button.transbtn.theme span{
    color: #fff;
}
.lighttheme .customNavTrade .nav-item .nav-link{
 color:#000
}
.lighttheme .pairTableMain tr {
  border-bottom: 1px solid #d8d8d8;
}
.darktheme .pairTableMain tr {
  border-bottom: 1px solid #12161c;
}
.lighttheme .searchbg{
  height: 100%;
  background: #eaeaea;
}
.lighttheme .pairSearch{
background-color: #fff;
}
.lighttheme .customNavTrade .nav-item .nav-link:after{
  color: #000;
}
.lighttheme .orderBookMiddle {
  background: #eaeaea;
  height: 100%;
}
.lighttheme .vertical-tab .tab-pane {
  background-color: #eaeaea;
}
.lighttheme .main_bg_trade_new .tradeMain .darkBox,.lighttheme .main_bg_trade_new .tradeMain .table th{
  background-color: #fff !important;
  background: #fff !important;
  color:#000
}
.lighttheme .vertical-tab .nav-tabs li a,.lighttheme .btnFavorite{
  color: #000;
}
.tradeFulltabbedTable nav {
  background: var(--themeclr);
}
.lighttheme .vertical-tab .nav-tabs,
.lighttheme .tradeMain .table td, .lighttheme .tradeMain .table th{
  border-right: 1px solid #d8d8d8;
  border-color:  #d8d8d8 !important;
}
.lighttheme .pTobBott{
  border-bottom: 1px solid #d8d8d8 !important;
}
.lighttheme button.transbtn.theme {
  background: transparent !important;
  color: #000;
}
button.transbtn.theme{
  border-color: transparent;
}
.lighttheme button.transbtn.theme span{
  color: #000;
}
.lighttheme .customNavTrade , .lighttheme .customNav {
  background-color: #e6eff8 !important;
  background-image: none;
}
 .customNavTrade , .customNav{
  padding-top: 5px;
padding-bottom: 5px;}
.tradeMain .header-overview{
  background-color: var(--themeclr);
}
.lighttheme iframe .group-2JyOhh7Z-,.spotLimitHead  {
  background-color: var(--themeclr) !important;
}
.lighttheme .spotTradingBox, .lighttheme .tradeRight,
.lighttheme .tradeLeft  {
  background: #ffffff;
}
.lighttheme .spotForm .form-group label{
  color: #000;
}
.lighttheme .darkBox,.lighttheme .tradeFulltabbedTable .nav-tabs,
.lighttheme .spotForm .input-group-append{
  border-color: #eaeaea !important;
}
.lighttheme .spotForm .input-group-text,.lighttheme .border-right-one,
.lighttheme .special.btn-group .btn{
  border-color: #eaeaea !important;
}
.lighttheme .spotForm .input-group .input-group-append span ,.lighttheme .special.btn-group .btn{
  background: #eaeaea !important;
  color: var(--themeclr);
}
.lighttheme .spotForm input[type=text],.lighttheme .pairSearch .form-control,
.lighttheme .pairSearch  span{
  background-color: #eaeaea !important;
  color: #000 !important;
}
.tradeTableLeftSide{
  padding-top: 0;
}
.tradeTableLeftSide  .tableHead{
  background-color: var(--themeclr);
}
.lighttheme .tradeTableBodyRow span {
  color: #3c3c3c;
}
.lighttheme .tradeTableTitle span{
  color:#000
}
.inputgrp button {
  background: white;
  border-color: transparent;
  color: var(--themeclr);
  border-radius: 0 10px 10px 0;
  box-shadow: none;
  outline: 0;
}
button.appnd_btn {
  background: #fff;
  border-radius: 7px;
  border-color: #fff0;
  outline: none;
  box-shadow: none;
}
.gap-2 {
  gap: 0.5rem;
}
footer.mainFooter ul li {
  padding-bottom: 16px;
}
footer.mainFooter ul li h4, footer.mainFooter ul li a{
  color:#ebfdff !important
}
.whitehr{
  border-top-color: #fff;
}
.footerlogo {
  width: 200px;
  margin-bottom: 18px;
}
.container.container-theme{
  max-width: 90% !important;
}
footer.mainFooter ul li:last-child {
  padding-bottom: 0;
}
.f-14{
  font-size: 14px;
}
/* derivatives */
#navbarResponsive .dropdown-menu.sm-menu {
  box-shadow: 0 0 5px #6cb7ff;
  border-radius: 3px;
}
.lighttheme #navbarResponsive .dropdown-menu.sm-menu,
.lighttheme #navbarResponsive1 .dropdown-menu.sm-menu {
  background: #fcfcfc;
}
.lighttheme #navbarResponsive .dropdown-menu.sm-menu a,
.lighttheme #navbarResponsive1 .dropdown-menu.sm-menu a{
  color: #000 !important;
}
.darktheme #navbarResponsive .dropdown-menu.sm-menu,
.darktheme #navbarResponsive1 .dropdown-menu.sm-menu {
  background: #1b1b1b;
}
.lighttheme .orderBookMiddle .tradeTableBodyRow:nth-child(odd) {
  background: white;
}
#navbarResponsive ul .dropdown-menu.sm-menu .dropdown-item:hover,
#navbarResponsive ul .dropdown-menu.sm-menu .dropdown-item:focus,
#navbarResponsive1 ul .dropdown-menu.sm-menu .dropdown-item:hover, 
#navbarResponsive1 ul .dropdown-menu.sm-menu .dropdown-item:focus {
  background: #1f84e6 !important;
}
.lighttheme .buySellTabButton .btn-group .btnSellYellow,
.lighttheme .buySellTabButton,.lighttheme  .buySellTabButton .btn-group .btnBuyYellow{
  background-color: #eaeaea;
  color: #000 !important;
}
.darktheme .buySellTabButton .btn-group .btnSellYellow,
.darktheme .buySellTabButton,.darktheme  .buySellTabButton .btn-group .btnBuyYellow{
background: #1b1b1b;
/* color: var(--themeclr) !important; */
color: #fff !important;
}

.lighttheme .chartTradeSection{
  border-color: #dee2e6;
}
.lighttheme div#nav-tabContent-tradeLimitMarket .border_blue_select > div{
  background: #eaeaea;
}
.lighttheme div#nav-tabContent-tradeLimitMarket .border_blue_select > div div:first-child,
.lighttheme .settingsec .border_blue_select > div div:first-child {
  background: #eaeaea;
  color: #000;
  border-color: #a89696;
}

div#nav-tabContent-tradeLimitMarket .border_blue_select {
  background: #ff000000 !important;
}
.lighttheme div#nav-tabContent-tradeLimitMarket .border_blue_select > div div:first-child {
  background: #eaeaea;
  color: #000;
  border-color: #eaeaea;
}
.lighttheme #price.quantity,.lighttheme #price.quantity::placeholder{
  background: #eaeaea;
  color: #000 !important;
}
.lighttheme .tableHead.border-bottom-one{
  border-color: #eaeaea !important;
}
.buySellTabButton .btn-group .btnSellBlue{
  border: 0 !important;
}

.lighttheme .rc-slider-mark-text,.lighttheme #leverageCurrentSliderValLabel,
.lighttheme .leverageHeight label, .lighttheme .tradeLimitMarket .nav-tabs .nav-link{
  color: #000 !important;
}
.lighttheme .tradeLimitMarket .nav-tabs .nav-link.active{
  color: var(--themeclr) !important;
}
.lighttheme .tradeLimitMarket .nav-tabs .nav-link:hover,
.lighttheme .tradeLimitMarket .nav-tabs .nav-link.active,
.darktheme .tradeLimitMarket .nav-tabs .nav-link:hover,
.darktheme .tradeLimitMarket .nav-tabs .nav-link.active{
  border-color: var(--themeclr);
}
.lighttheme #nav-tradeLimit ul.list2ColumnJustify li label,
.lighttheme #nav-tradeLimit .form-group label{
  color: #000 !important;
}
.lighttheme section.tradeMain.tradePage{
  background-color: #fff;
}
.lighttheme #nav-tradeLimit ul.list2ColumnJustify li span{
  color: var(--themeclr);
}
.lighttheme .number-input.number-input input[type=number],.lighttheme .number-input.number-input input[type=number]::placeholder,
.lighttheme .number-input.number-input, .lighttheme .number-input.number-input button.plus,.lighttheme .number-input.number-input button.minus{
  background: #eaeaea !important;
  border-color: #dedede !important;
  color: #000 !important;
}

.darktheme .spotForm input[type=text], 
.darktheme .pairSearch .form-control, 
.darktheme .pairSearch span,
.darktheme .spotForm .input-group .input-group-append span,  
.darktheme .special.btn-group .btn {
  background: #1b1b1b;
  color: #fff;
}

.darktheme .number-input.number-input input[type=number],.darktheme .number-input.number-input button.plus,
.darktheme .number-input.number-input button.minus,.darktheme .number-input.number-input,.darktheme #price.quantity,
.darktheme div#nav-tabContent-tradeLimitMarket .border_blue_select > div div:first-child{
  /* background-color: #1b1b1b !important;
  border-color: #1b1b1b !important; */
   background-color: #000 !important;
  border-color: var(--themeclr) !important;
}

.lighttheme div#nav-tabContent-tradeLimitMarket .border_blue_select > div div:first-child{
  background-color: #eaeaea !important;
  border-color: #eaeaea !important;
}
.lighttheme .settingsec .border_blue_select > div div:first-child{
  background-color: #fff !important;
  border-color: var(--themeclr) !important;
}
.border_blue_select > div {
  z-index: 9;
}
.darktheme .launchpad_token_section {
  padding: 0 0 60px;
}
 .darktheme .launchPadMain {
  background: #171717;
}
.darktheme .launchpad_token_section
{
  background: #000;
}
.darktheme .whiteBoxLaunchpad {
  background: #000;
}
.darktheme .whiteShadowBox,.darktheme .whiteBoxLaunchpad{
  box-shadow: 0 0 10px 8px rgba(31, 31, 31, 0.8);
}
.darktheme .launchpadCoinName h3, .darktheme .projectIntroduction h3,
.darktheme .projectIntroduction li, .darktheme .projectIntroduction p .text-dark,
.darktheme .whiteShadowBox .form-group label,.darktheme .whiteShadowBox h3,
.darktheme .socialMediaCoinDetails h3, .darktheme .cdTeamProfileDetail h6,
.darktheme .cdTeamDetail h3{
  color:#fff !important
}
.darktheme .socialMediaCoinDetails ul li a,.darktheme .cdTeamProfileDetail a {
  color: #fff;
  border: 1px solid #fff;
}
.darktheme .whiteShadowBox input.form-control, .darktheme .projectIntroduction h3,
.darktheme .socialMediaCoinDetails h3, .darktheme .cdTeamDetail h3 {
  border-color:#484848;
}
 .socialMediaCoinDetails ul li a:hover,.cdTeamProfileDetail a:hover{
  color: var(--themeclr);
  border: 1px solid var(--themeclr);
}
.lighttheme .whiteShadowBox input.form-control{
  color: #000 !important;
}
.lastpricepair > div {
  height: 850px !important;
}
.launchpad_token_wrapper li a.active{
    background: var(--themeclr) !important;
    border-color: var(--themeclr);
    box-shadow: unset !important;
    color:#fff !important;
}
.launchpad_token_wrapper li a.active:hover{
  background: #000 !important;
}
.launchpad_token_wrapper li a{
  background: #000;
}
.launchpad_token_wrapper li a:hover{
  background: var(--themeclr) !important;
  color:#fff !important;
}
.darktheme .launchpad_token_wrapper {
  background: #0a0a0a;
  box-shadow: 0 0 31px 12px #1a1a1a;
}
.lighttheme .login_banner input,.lighttheme .themebtn.dark{
  background:#fff !important ;
  color: #000 !important;
}
.lighttheme .themebtn.dark:hover{
  background:#000 !important ;
  color: #fff !important;
}
.darktheme .login_banner input,.darktheme .themebtn.dark, .modal-content  .themebtn.dark,
.modal-content .themebtn.light:hover{
  background:#000 !important ;
  color: #fff !important;
}
.modal-content .themebtn.light,.modal-content .themebtn.dark:hover{
  background:#fff !important ;
  color: #000 !important;
}
.login_banner input[type="submit"]{
  border-radius: 5px;
}
.lighttheme .login_banner input[type="submit"]{
  background: #fff !important;
  border-radius: 5px;
  color:#000 !important
}
.darktheme .login_banner input[type="submit"]{
  color:#fff !important
}
.kyc-header input {
  font-size: 14px !important;
}
.lighttheme .kyc-header input {
  background: #fff !important;
  color: #000 !important;
  font-size: 14px;
}
.lighttheme .kyc-header label,.lighttheme .kyc-header h3{ 
  color: #000;
}
.darktheme .kyc-header label,.darktheme .kyc-header h3,.lighttheme .kyc-header h3{ 
  color: #fff;
  margin-left: 0;
}
.banner {
  padding-top: 100px;
  min-height: 100vh;
}
 .bannerbg {
  background-image: url(../images/banner.png);
  background-size: 100%;
  background-repeat: no-repeat;
}
.darktheme .banner{
  color: #fff;
}
.banner .row {
  height: 100%;
  align-items: center;
}
.banner img {
  width: 100%;
}
.spot{
  padding-left: 20px;
  border: 0;
}
.spot a {
  background: #fff !important;
  color: var(--themeclr) !important;
  height: 64px;
  display: inline-flex;
  align-items: center;
  margin-right: 1px;
  padding: 15px;
  border-radius: 0 !important;
  text-transform: uppercase;
  font-weight: 700;
  border-color: #fff !important;
}
.spot a.active{
  background-color: #000 !important;
  border-color: #000 !important;
  color: #fff !important;
}
.lighttheme .spot a.active{
  background-color: #c7d6e5 !important;
  border-color: #c7d6e5 !important;
  color: #000 !important;
}
.darktheme .table-dark{
  background-color: #0b0b0b;
}
.darktheme .heading{
  color: #fff;
}
td.spotprice {
  color: var(--themeclr) !important;
}
.darktheme .table-dark td, .darktheme .table-dark th, .darktheme .table-dark thead th {
  border-color: #0e0e0e;
}
.lighttheme .table-dark td, .lighttheme .table-dark th, .lighttheme .table-dark thead th {
  border-color: #eeeeee;
}
.statcarousel {
  background-image: linear-gradient(90deg, black, #5595d2, black);
}
.lighttheme .statcarousel {
  background-image: linear-gradient(90deg, #fff, #5595d2, #fff);
}
.lighttheme .statcarousel .owl-stage-outer .owl-item{
  border-color: #f3f3f3 !important;
}
.lighttheme .statcarousel .owl-stage-outer .owl-item{
  color: #000;
}
 .statcarousel .owl-stage-outer .owl-item .green{
  color:#06b406
}
.statcarousel .owl-stage-outer .owl-item .red{
  color:#d70505
}
.lighttheme .statcarousel .owl-nav button {
  background-image: linear-gradient(88deg, #69849e, #abbbca, #eaeef2) !important;
}
td.lastprice {
  color: #88b3dc !important;
}
.owl-carousel .owl-nav button.owl-prev,.owl-theme .owl-nav .disabled, .owl-carousel .owl-nav button.owl-next, .owl-carousel button.owl-dot{
  color: #fff !important;
}
.statcarousel .owl-nav button.disabled{
  background-color: transparent !important;
}
.statcarousel .owl-nav button{
  border-radius: 50% !important;
    background-image: linear-gradient(88deg, #02284d, #02284d, #096ccd) !important;
}
.spot {
  background: var(--themeclr);
  border-radius: 13px;
}
.startearning {
  width: 250px;
  height: 70px;
  text-align: center;
  margin: auto;
  border: 2px solid var(--themeclr);
  position: relative;
  margin-top: 50px;
}
.startearning p {
  position: absolute;
  top: -12px;
  left: 0;
  right: 0;
  width: 173px;
  margin: auto;
}
.lighttheme .startearning p {
  background-color: #fff;
  color: #000;
}
.darktheme .startearning p {
  background-color: #000;
  color: #fff;
}
.darktheme .home{
  background-color: #000;
}
.startearning button {
  position: absolute;
  left: 0;
  right: 0;
  width: 182px;
  margin: auto;
  padding: 10px;
  bottom: -24px;
  background: var(--themeclr);
  border-color: var(--themeclr);
  border-radius: 5px;
  color: #fff;
  font-weight: 500;
}
.lighttheme .custom-file-label{
  background-color: #fff !important;
  color: #000 !important;
}
.darktheme .custom-file-label{
  background-color: #000 !important;
  color: #fff !important;
}

.login_banner input[type="submit"]:hover,#nav-emailLogin input[type="submit"]:hover{
  color: var(--themeclr) !important;
}

.thememodal .modal-content {
  background-color: var(--themeclr) !important;
}
.darktheme .thememodal .modal-content input{
  background-color: #000 !important;
}
.thememodal .modal-content input{
  background-color: #000 !important;
  border: 0 !important;
}
.thememodal label{
  color:#fff 
}
 .modalbtn {
  background: #fff;
  color: #000;
}
 .modalbtn:hover {
  background: #fff;
  color: var(--themeclr);
}
 .modalgraybtn{
  background: lightgrey !important;
  color: var(--themeclr);
}
 .modalgraybtn:hover{
  color: #000;
}
.thememodal input {
  padding: 15px 15px !important;
}
.thememodal .border_blue_select{
  background-color: #000 !important
}
.lighttheme .thememodal .modal-content input[type=file],
.darktheme .thememodal .modal-content input[type=file] {
    background: #fff0 !important;
    border: 0;
    box-shadow: none !important;
    padding-left: 0 !important;
    margin-left: 0;
  }
.lighttheme .thememodal .modal-content input[type=file]:hover,
.darktheme .thememodal .modal-content input[type=file]:hover{
  color: #fff !important;
}
.darktheme .tox.tox-tinymce{
  filter: invert(1)
}
.kyc-header {
  background-color: var(--themeclr);
}
 .themecards .img {
  width: 80px;
  border:1px solid var(--themeclr);
  padding: 8px;
  border-radius: 50%;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cards {
  border: 1px solid var(--themeclr);
  border-radius: 15px;
  position: relative;
  padding-top: 45px;
  text-align: center;
  color: #fff;
  padding-left: 5px;
  padding-right: 5px;
}
.cards .img {
  position: absolute;
  top: -38px;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #000;
}
.lighttheme .cards .img{
 background-color: #fff;
}
.cards:hover, .cards:hover .img {
  background-color: var(--themeclr);
  border-color: #fff;
}
.darktheme .themecards .img img {
  object-fit: contain;
  /* filter: invert(1); */
  height: 40px;
  width: 40px;
}
.tradeplatform {
  color: #fff;
}
.tradeplatform .d-flex img {
  background: #5a9edd;
  padding: 5px;
  border-radius: 5px;
}
.circleimg img {
  height: 424px;
  width: 360px;
}
.circleimg p {
  position: absolute;
  padding: 9px;
  border-radius: 5px;
  width: 140px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  background-color: #fff;
  color: var(--themeclr);
}
.circleimg p.c1 {
  top: -40px;
  right: 186px;
}
.circleimg p.c2 {
  left: 66px;
  top: 104px;
}
.circleimg p.c3 {
  top: 52%;
  right: 139px;
}
.circleimg p.c4 {
  bottom: 9px;
  left: 89px;
}
.tradeplatform {
  background: var(--themeclr);
  padding-top: 80px;
  padding-bottom: 80px;
  background-image: url(../images/map.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.tradebg img {
  width: 100%;
}
.tradewithexchange{
  color: #fff;
  background-image: url(../images/bluebg.png);
}
.bighead{
  font-size: 50px;
  color:#005eb8 !important
}
.bannerbg .bighead{
  color:#005eb8 !important
}
.themecards .cards:hover p{
  color: #fff !important;
}
.themecards .cards p {
  font-size: 14px;
  color: lightgray;
}
.themecards .cards:hover h6{
  color: #fff !important;
}
.lighttheme .themecards .cards h6{
  color: #000;
}
/* .cards:hover .img img {
  filter: invert(1);
} */

.lighttheme .themecards .cards p {
  color: #000;
}
.themecards .cards {
  padding: 43px 30px;
  height: 100%;
}

.tradewithexchange .d-flex img {
  width: 150px;
  margin-right: 10px;
}
.tradewithexchange p {
  font-size: 14px;
}
.w-full{
  width: 100%;
}
.editbtn {
  background: transparent;
  border: 0;
  color: var(--themeclr);
}
.header-overview.header-overview_trade.bgtrans {
  background: transparent;
}
.headerdrop .header-overview.bgtrans{
  display: block;
}
 .header-overview.bgtrans{
  display: none;
}
.lighttheme .table-dark{
  background-color: #fff;
}
.lighttheme .table-dark td,.lighttheme .table-dark th{
   color: #000;
}
.lighttheme .bannerimg .dark{
   display: none;
}
.darktheme .bannerimg .light{
  display: none;
}
.inputgrp.d-flex .newletter_inp {
  width: 100%;
}
.lighttheme .tradeRight {
  background: #eaeaea !important;
}
.tradeFooterBox a:hover {
  color: #000;
}
.lighttheme .privacy-policy-container p,.lighttheme .privacy-policy-container ul li{
  color: #000;
}
.lighttheme .faq .darkbox{
  border: 0;
}
.privacy-policy-container p,.privacy-policy-container ul li{
  margin-bottom: 0;
}
.lighttheme section.innerCMS{
  background-color:#f2f2f2
}
.darktheme section.innerCMS{
  background-color:#191919
}
.darkBox.contentPage,.darkBox.bonusRewards,.bonusBox{
  background-color: transparent !important;
}
.lighttheme .loginBanner h3{
  color: #000;
}
.navbar-nav{
  align-items: center;
}
.customNavTrade .nav-item .nav-link{
  line-height: 0;
}
.lighttheme .assetsTable .nav-tabs .nav-link{
  color:#000 !important
}
.pagetop {
  padding-top: 80px;
}
.tradeMain .row{
  width: 100%;
}
.minheight90vh{
  min-height: 90vh;
}
.lighttheme .spotForm input[type=text] {
  color: #000;
}
.tradeMain .derive.header-overview {
  /* background: transparent !important; */
}
.derivebtn{
  border-radius: 0 !important;
  border-color: #005eb8 !important;
  height: 100%;
}
.derivebtn:hover{
  background-color: var(--themeclr) !important;
  color: lightgray !important;
}
.derivebtn:after{
  color: #fff !important;
}
.derivehead .selectcointype_new {
  display: none;
}
button#dropdown-basic + .dd_menu_der_dd a:hover{
  background-color: var(--themeclr) !important;
}
button#dropdown-basic + .dd_menu_der_dd{
  pointer-events: painted !important;
}
@media(max-width:1199px){
  .circleimg p.c1 {
    right: 91px;
}
.circleimg p.c3 {
  right: 20px;
}
}
@media(max-width:991px){

  .notify_count {
    background: #005eb8;
    color: #fff;
    width: 17px;
    height: 17px;
    display: inline-block;
    border-radius: 17px;
    line-height: 13px;
    text-align: center;
    font-size: 10px;
    position: absolute;
    left: 12px;
    margin-top: 4px;
    border: 2px solid #fff;
    z-index: 9;
}
  .customNavTrade .nav-item .nav-link.login_btn,
  .customNavTrade .nav-item .nav-link.register_btn{
    padding: 7px 15px !important;
  }
  .collapse_menu li.nav-item.dropdown.dmenu > a.mobile{
    display: block;
  }
  .collapse_menu li.nav-item.dropdown.dmenu > a {
    display: none;
}
  footer.mainFooter ul li {
    padding-bottom: 4px;
  }
  .bighead {
    font-size: 40px;
  }
  #navbarResponsive .dropdown-menu.sm-menu{
    box-shadow: none;
  }
  .customNavTrade .nav-item a:after{
    display: none;
  }
  .lighttheme #navbarResponsive{
    background-color: #f2f2f2;
  }
  .darktheme #navbarResponsive{
    background-color: #121212;
  }
}
@media(max-width:767px){
  .lastpricepair > div{
    max-height: 300px !important;
  }
  .bannerimg img {
    max-width: 500px;
  }
  .bannerbg{
    margin-bottom: 10px;
  }
  .bannerimg {
    text-align: center;
  }
  .bighead {
    font-size: 30px;
  }
  .tradeplatform {
    background: var(--themeclr);
    padding-top: 35px;
    padding-bottom: 35px;
  }
}

.spot_trade_main .header-overview.header-overview_trade {
  display: none;
}
.bluebtn {
  background: #005eb8;
  color: #fff !important;
  font-size: 13px !important;
  padding: 10px;
}
.bluebtn svg {
  fill: #fff;
}
.border_blue_select.border_blue_select_bg_blue * {
  color: #fff !important;
}
.lighttheme .border_blue_select.border_blue_select_bg_blue  * {
  color: #000 !important;
}

@media(max-width:425px){
  .spot a {
    height: 34px;
    margin-right: 1px;
    padding: 5px;
    font-weight: 500;
    font-size: 13px;
  }
}

.primary-modal .modal-content{
  background-color: #000;
}
.primary-modal .modal-content .btnDefaultNewBlue,.primary-modal .modal-content .btnDefaultNew:hover{
  background: #000 !important;
  color: #fff !important;
  border-radius: 5px !important;
  padding: 7px 20px !important;
  border: 1px solid #5087c0 !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
  box-shadow: none !important;
}
.primary-modal .modal-content .btnDefaultNew,.primary-modal .modal-content .btnDefaultNewBlue:hover{
    padding: 7px 20px!important;
    color: #fff!important;
    font-size: 13px!important;
    font-weight: 500!important;
    transition: all .2s;
    text-transform: uppercase!important;
    box-shadow: none!important;
    background: var(--themeclr)!important;
    border-radius: 5px!important;
    border: 1px solid #5087c0!important;
}

.spot a{
  margin-bottom: 0 !important;
}
.lighttheme .all_pair_tr td {
  color: #646464;
}

.lighttheme .assetTitle,.lighttheme .groupTitle,.lighttheme .accordianSubGroup a {
  color: #000 !important;
}
.lighttheme .tradeFulltabbedTable tbody *{

}

span.input-group-text {
  background: #005eb8;
  border-color: #005eb8;
  color: #fff;
}
table{
  text-align: center;
}
.lighttheme .tradeFulltabbedTable tbody{
  background-color: #fff;
}
.darktheme .custom-file-label,
.lighttheme .custom-file-label{
  display: flex;
}
 .modal .buttonType1, .btnDefaultNew{
  background: var(--themeclr) !important;
  border-radius: 5px !important;
  border: 1px solid #5087c0 !important;
  text-transform: uppercase !important;
  box-shadow: none !important;
}
.modal .buttonType1:hover , .btnDefaultNew:hover{
  background: transparent !important;
  color: var(--themeclr) !important;
}
.lightmodal  .border_blue_select div,
.lightmodal .checkbox .cr,.lightmodal  .radio .cr{
  background-color: #fff;
}
.lightmodal  .border_blue_select div > div:first-child {
  border-color: var(--themeclr);
  color: #000;
}





.border_blue_select div > div:first-child{
  border-color: var(--themeclr);
  color:#fff;
  border-radius: 5px;
}
.lightmodal  .css-9wtuf0-IndicatorsContainer{
border: 1px solid #005eb8;
border-left: 0;
border-radius:0 5px 5px 0;
}
.lightmodal input:focus, .lightmodal .primary-modal .form-control{
  background-color: #fff !important;
  color: #000 !important;
}
.lightmodal .primary-modal .form-control,
.darkmodal .primary-modal .form-control{
  border-color: #005eb8 !important;
  border-radius: 5px
}
.border_blue_select > div:last-child *{
  color: #000 !important;
}
.lightmodal .modal-body .form-group label{
 color: #000 !important;
}
.lightmodal td{
  /* color: #000 !important; */
 }
.text-danger{
 color:#ff6f7c !important;
}
.lightmodal .special.btn-group .btn {
  background: #b5dffa !important;
  border-color: #b5dffa !important;
  color: #000;
}
.lightmodal .checkConfirmationText {
  color: #000;
}
body.modal-open {
  padding-right: 0 !important;
}
.navbar-nav a.nav-link.active {
    color: #8abeef !important;
}
.lighttheme .navbar-nav a.nav-link.active {
  color: var(--themeclr) !important;
}
.input-group .form-control[readonly] {
  width: auto;
}
.input-group .form-control{
  border-right: 0 !important;
  border-radius: 5px 0 0 5px;
}
.input-group button {
  border-color: #005eb8;
  border-radius:0 5px  5px 0;
  border-left: 0 !important;
  background-color: transparent !important;
}
.replytoadmin .card button {
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: left;
}
.lighttheme .replytoadmin textarea{
  color: #000 !important;
}
.replytoadmin  h5{
  margin-bottom: 0;
}
.replytoadmin .prof {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin-right: 7px;
}
.card-header h5 {
  color: gray;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
}
.card-header button:hover h5{
  text-decoration: none;
}
.card-header h5 span{
  color: #000;
}
.replytoadmin .card {
  margin-bottom: 10px;
  border: 0;
}
.darktheme .replytoadmin .card-header{
  background-color:#1d1d1d;
}
.darktheme  .card-header h5 span{
  color: #fff;
}
.input-group {
  flex-wrap: nowrap;
}
.darktheme .replytoadmin .card-body {
  background-color: #1d1d1d;
  color: #fff;
}
#accordion textarea {
  background: transparent;
}
.card-body > p {
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 5px;
}
.darktheme .card-body > p {
  border-bottom: 1px solid #262626;
}
.card-header button:focus {
  box-shadow: none;
}
.replytoadmin .card {
  margin-bottom: 10px;
}
.showin767{
  display: none;
}
@media(max-width:767px){
  .showin767{
    display: block;
    font-size: 14px;
  }
  .hidein767{
    display: none;
  }
}
#accordion .card button.btnDefaultNew {
  display: block;
  text-align: center;
}
.launchpad_contact_details > img {
  border-radius: 50%;
  aspect-ratio: 1/1;
  height: 100px;
}
#navbarResponsive .dropdown-item.active {
  background-color: #f000;
}
.spotForm .col-md-5 {
  display: flex;
  align-items: center;
}
.faq .darkBox.contentPage{
  border: 0 !important;
}
.faq .darkBox.contentPage .darkBoxSpace .card .card-body{
  padding: 15px;
}
.faq .darkBox.contentPage .darkBoxSpace .card button{
  padding: 14px;
  border-bottom-color: #525252;
}
.lighttheme .faq .darkBox.contentPage .darkBoxSpace .card button{
  border-bottom-color: #dcdcdc;
}
.faq .darkBox.contentPage .darkBoxSpace .card{
  background: #000 !important;
}
.lighttheme .faq .darkBox.contentPage .darkBoxSpace .card{
  background: #fff !important;
}
.custom-checkbox .custom-control-input:checked~.custom-control-label:after{
  background-color: var(--themeclr);
  border-radius: 3px;
}
.myasset {
  padding-top: 70px;
}

.myaset_parent_div .myasset
{
  padding-top: 10px !important;

}


/* CMS CSS */

@media only screen and (min-width:1200px)
{
  .img_trade
  {
    max-width: 600px !important;
  }
  .img_trade_align
  {
    text-align: right;
  }
  .img_banner_home
  {
    max-width: 700px !important;

  }
  .img_banner_home_align
  {
    text-align: right;
  }
}
.banner_felx
{
  display: flex;
  align-items: center;
}
.img_banner_home,.img_trade
{
object-fit: contain !important;
}

.myasset_height
{
  min-height: calc(100vh - 404px) !important;
}
.myaset_parent_div .myasset_height
{ 
min-height: unset !important;
}
.bighead.text_white_head
{
  color:#fff !important;
}


.lightmodal .primary-modal .tradeBuy td {
  color: #000 !important;
}

.tradeplatform .d-flex img
{
  width: 40px;
  height: 40px;
}

@media only screen and (min-width:576px)
{
  .img_launchpad
  {
    max-width: 400px !important;
  }
}
.box_panel img
{
width: 40px;
height: 40px;
}

.lighttheme .wallet-tab select.country_dd_cus option

{
background-color: #fff !important;
color:#000 !important;
}
.lighttheme .wallet-tab select.country_dd_cus:focus-visible
{
  outline: -webkit-focus-ring-color auto 0px !important;
}

.lighttheme .launchpadtoken_bg,.lighttheme .launchpad_token_section
{
background-color: #fff;
}
.lighttheme .text_them_ech
{
  color:#000 !important;
}
.close
{
  opacity: 1 !important;
}
.sha_without_box
{
  box-shadow: 0 0 0px 0px rgb(31 31 31 / 80%) !important;
  padding-top: 0px !important;
}
.lighttheme .choose_coin_list li a.active 
{
  color:#000 !important;
}
.lightmodal .modal-dialog .text-white{
  color:#000 !important;
}
.border_blue_select_bg_blue>div{
  background-color: transparent !important;
}
.img_card_row_resize img
{
  width: 40px;
  height: 40px;
}
.img_launchpad
{
  padding: 0px 20px;
}

.lightmodal .thememodal#myModal1 .modal-content input {
  background-color: #fff !important;
  color: #000 !important;
}


.lightmodal .thememodal#myModal1 .modal-content input.theme_upload_btn

{
  background: transparent !important;
    color: #fff !important;
}